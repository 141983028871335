import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserDetails } from '../_models/User';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userData = new BehaviorSubject<UserDetails>(new UserDetails());
  data = this.userData.asObservable();

  updateData(data: UserDetails) {
    this.userData.next(data);
  }
}
