/* eslint-disable max-classes-per-file */
import { Utils } from 'src/app/shared/_models/common/Date-Formatter';

export class Application {
  MailedDate: string;
  DueDate: string;
  AcknowledgmentDate: string;
  FeeFullyPaidDate: string;
  IntentAsSubmitted: string;

  constructor(data?: any) {
    if (data) {
      this.MailedDate = Utils.formatLongDate(data.AIMS_Intent_Mailed_Date);
      this.DueDate = Utils.formatLongDate(data.AIMS_Application_Due_Dt);
      this.AcknowledgmentDate = Utils.formatLongDate(data.AIMS_Intent_Acknlgmnt_Dt);
      this.FeeFullyPaidDate = Utils.formatLongDate(data.AIMS_Intent_Fee_Fully_Paid_Dt);
      this.IntentAsSubmitted = data.AIMS_Intent_As_Submitted;
    }
  }
}

export class Survey {
  InfoLetterSentDate: string;
  FeeFullyPaidDate: string;
  ScheduledLetterSentDate: string;
  SurveyStartDate: string;
  SurveyEndDate: string;
  CompNotifiedOutcomeDate: string;
  AccredDecision: string;
  AccredExpirationDate: string;

  constructor(data?: any) {
    if (data) {
      this.InfoLetterSentDate = Utils.formatLongDate(data.AIMS_Survey_Info_Lettr_Sent_Dt);
      this.FeeFullyPaidDate = Utils.formatLongDate(data.AIMS_Dt_Survey_Fee_Fully_Pd);
      this.ScheduledLetterSentDate = Utils.formatLongDate(data.AIMS_Sched_Letter_Sent_Date);
      this.SurveyStartDate = Utils.formatLongDate(data.AIMS_Survey_Start_Dt);
      this.SurveyEndDate = Utils.formatLongDate(data.AIMS_Survey_End_Dt);
      this.CompNotifiedOutcomeDate = Utils.formatLongDate(data.AIMS_Comp_Notifd_Of_Outcome_Dt);
      this.AccredDecision = data.AIMS_Accred_Decision;
      this.AccredExpirationDate = Utils.formatLongDate(data.AIMS_Accred_Expiration_Dt);
    }
  }
}

export class QIP {
  QIPNotReq: boolean;
  DueDate: string;
  ReceivedDate: string;
  NeedsMoreDueDate: string;
  NeedsMoreReceivedDate: string;
  CompletedDate: string;

  constructor(data?: any) {
    if (data) {
      this.QIPNotReq = data.AIMS_QIP_Not_Required;
      this.DueDate = Utils.formatLongDate(data.AIMS_Dt_QIP_Due);
      this.ReceivedDate = Utils.formatLongDate(data.AIMS_QIP_Recvd_Dt);
      this.NeedsMoreDueDate = Utils.formatLongDate(data.AIMS_QIP_Needs_More_Due_Dt);
      this.NeedsMoreReceivedDate = Utils.formatLongDate(data.AIMS_QIP_Needs_More_Recvd_Dt);
      this.CompletedDate = Utils.formatLongDate(data.AIMS_Dt_QIP_Completed);
    }
  }
}

export class SurveyProcessCheckpoints {
  SurveyId: string;
  Application: Application;
  Survey: Survey;
  QIP: QIP;

  constructor(data?: any) {
    if (data) {
      this.SurveyId = data.Survey_Id;
      this.Application = new Application(data.Application);
      this.Survey = new Survey(data.Survey);
      this.QIP = new QIP(data.QIP);
    }
  }
}
