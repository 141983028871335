<div class="container">
    <div class="modal-header">
      <h1 aria-label="payment successful" class="heading-font">
        Your payment has been successfully processed!
      </h1>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="fa fa-times" aria-hidden="true" (click)="onCancel()"></i>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-9">
          <ul class="list-unstyled">
            <li><strong>Order:</strong> {{ transactResponse?.orderDescription }}</li>
            <li *ngIf="transactResponse?.orderInvoiceNumber">
              <strong>Invoice Number:</strong> {{ transactResponse?.orderInvoiceNumber }}
            </li>
            <li><strong>Invoice Date:</strong> {{ transactResponse?.dateTime }}</li>
            <li><strong>Account Type:</strong> {{ transactResponse?.accountType }}</li>
            <li><strong>Account Number:</strong> {{ transactResponse?.accountNumber }}</li>
            <li><strong>Total:</strong> {{ transactResponse?.totalAmount | currency }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="form-footer">
        <div class="row">
          <div class="col-md-9">
            <button (click)="continue()" class="btn btn-info">Continue</button>
          </div>
        </div>
      </div>
    </div>
</div>
