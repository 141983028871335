import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { AttachmentUploadComponent } from 'src/app/shared/_components/attachment-upload/attachment-upload.component';
import { ApplFeePaymentModalComponent } from 'src/app/shared/_components/payment/appl-fee-payment-modal/appl-fee-payment-modal.component';
import { AnnualMaintenanceFeeComponent } from 'src/app/survey/_components/annual-report/ongoing-stepper/annual-maintenance-fee/annual-maintenance-fee.component';
import { AdditionalCertificateFormComponent } from 'src/app/survey/_components/survey-details/additional-certificate-form/additional-certificate-form.component';
import { PaySurveyFeeComponent } from 'src/app/survey/_components/survey-details/pay-survey-fee/pay-survey-fee.component';
import {
  ActionItemObject,
  AnnualMaintenanceFeeDue,
  AnnualReportDue,
  ApplicationFeeDue,
  ApplicationPendingSubmission,
  MissingApplicationItems,
  OrderAdditionalCertificates,
  SurveyFeeDue,
} from '../../models/ActionItems';

@Component({
  selector: 'app-action-items',
  templateUrl: './action-items.component.html',
  styleUrls: ['./action-items.component.scss'],
})
export class ActionItemsComponent implements OnInit {
  @Input() data: any;
  @Output() tileAction = new EventEmitter<any>();
  finalDueDate: string;
  routePath: string;
  modalReference: NgbModalRef;
  sharedPaymentData: any = null;
  response: any;
  printFlag: boolean;
  constructor(private router: Router, private modalService: NgbModal) {}

  ngOnInit(): void {
    this.finalDueDate = this.data?.dueDate ? moment(this.data?.dueDate).format('MM/DD/YYYY') : null;
  }

  tileNavigation(data: ActionItemObject) {
    if (data.buttonResponse === 'ApplicationDetails') {
      const rowData: ApplicationPendingSubmission = data?.actionitemData;
      const id =
        rowData.applicationAsSubmittedId.length > 0
          ? rowData.applicationAsSubmittedId
          : rowData.applicationId;
      const appNumber =
        rowData.applicationAsSubmittedId.length > 0
          ? 'A' + rowData.applicationNumber
          : rowData.applicationNumber;
      const navigationExtras: NavigationExtras = {
        queryParams: { id, appNumber },
      };
      this.router.navigate(['/application/details'], navigationExtras);
    } else if (data.buttonResponse === 'openAppFeeModal') {
      const applicationFeeDue: ApplicationFeeDue = data?.actionitemData;
      this.sharedPaymentData = {
        applicationId: applicationFeeDue?.intentId,
        compSubmittingIntentId: applicationFeeDue?.compSubmittingIntentId,
      };
      this.modalReference = this.modalService.open(ApplFeePaymentModalComponent, {
        backdrop: 'static',
        size: 'lg',
        keyboard: true,
        centered: true,
        ariaLabelledBy: 'AppFeeHeader',
      });
      this.modalReference.componentInstance.dataToTakeAsInput = this.sharedPaymentData;
      this.modalReference.componentInstance.printAction.subscribe((res: any) => {
        if (res) {
          this.response = res;
          this.printFlag = true;
          setTimeout(() => {
            window.document.title =
              'Customer Connect - ' + this.response.paymentModelData?.OrderDescription;
            window.print();
            window.document.title = 'Customer Connect - Home';
            this.printFlag = false;
          }, 300);
        }
      });
    } else if (data.buttonResponse === 'openSurveyFeeModal') {
      const surveyFeeDue: SurveyFeeDue = data?.actionitemData;
      this.modalReference = this.modalService.open(PaySurveyFeeComponent, {
        backdrop: 'static',
        size: 'lg',
        keyboard: true,
        centered: true,
        ariaLabelledBy: 'PayFeeHeader',
      });
      this.modalReference.componentInstance.surveyIdInput = surveyFeeDue?.surveyId;
    } else if (data.buttonResponse === 'openAppItemUploadModal') {
      const missingAppItem: MissingApplicationItems = data?.actionitemData;
      this.modalReference = this.modalService.open(AttachmentUploadComponent, {
        backdrop: 'static',
        size: 'lg',
        keyboard: true,
        centered: true,
        ariaLabelledBy: 'AttachmentModal',
      });
      this.modalReference.componentInstance.checkListID = missingAppItem?.checkListId;
      this.modalReference.componentInstance.isApplicationItems = true;
      this.modalReference.componentInstance.showOrHideLoader = true;
    } else if (data.buttonResponse === 'AnnualReportPage') {
      const rowData: AnnualReportDue = data?.actionitemData;
      const id = rowData.acqrId;
      const surveyNum = rowData.surveyNumber;
      const navigationExtras: NavigationExtras = {
        queryParams: { id, surveyNum },
      };
      this.router.navigate(['/survey/annual-report'], navigationExtras);
    } else if (data?.buttonResponse === 'openMaintainceFeeModal') {
      const annualDue: AnnualMaintenanceFeeDue = data?.actionitemData;
      this.modalReference = this.modalService.open(AnnualMaintenanceFeeComponent, {
        backdrop: 'static',
        size: 'lg',
        keyboard: true,
        centered: true,
        ariaLabelledBy: 'AnnualHeader',
      });
      this.modalReference.componentInstance.acqrIdInput = annualDue?.acqrId;
      this.modalReference.componentInstance.surveyId = annualDue?.surveyId;
    } else if (data.buttonResponse === 'SurveyDetails') {
      const rowData: any = data?.actionitemData;
      const id = rowData?.surveyId;
      const surveyNum = rowData?.surveyNumber;
      const navigationExtras: NavigationExtras = {
        queryParams: { id, surveyNum },
      };
      this.router.navigate(['/survey/details'], navigationExtras);
    } else if (data.buttonResponse === 'openAdditionalCertiForm') {
      const certiForm: OrderAdditionalCertificates = data?.actionitemData;
      this.modalReference = this.modalService.open(AdditionalCertificateFormComponent, {
        backdrop: 'static',
        size: 'lg',
        keyboard: true,
        centered: true,
        ariaLabelledBy: 'AdditionalCertiHeader',
      });
      this.modalReference.componentInstance.surveyIdInput = certiForm?.surveyId;
    } else {
      this.router.navigate([data?.buttonResponse], {
        state: { routeName: data?.routerPath, data: data?.actionitemData },
      });
    }
  }
}
