import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IDynamicDialogData } from '../../_contracts/IDynamicDialogData';

@Component({
  selector: 'dynamic-dialog',
  templateUrl: './dynamic-dialog.html',
  styleUrls: ['./dynamic-dialog.scss'],
})
export class DynamicDialogComponent {
  @Input() dialogInfo: IDynamicDialogData;
  constructor(private activeModal: NgbActiveModal) {}

  public yes() {
    this.activeModal.close(true);
  }

  public no() {
    this.activeModal.close(false);
  }

  public cancel() {
    this.activeModal.dismiss();
  }
}
