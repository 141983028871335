import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserDetails } from 'src/app/core/_models/User';
import { SortPipe } from 'src/app/shared/_pipes/sort.pipe';
import { ApplicationList } from '../../models/application-list';
import { SurveyList } from '../../models/survey-list';
import { DashboardService } from '../../_services/dashboard.service';
import { ApplicationTableConfig, SurveyTableConfig, SubHeader } from '../../constants/constants';

@Component({
  selector: 'app-application-survey',
  templateUrl: './application-survey.component.html',
  styleUrls: ['./application-survey.component.scss'],
})
export class ApplicationSurveyComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  filterApplicationData: ApplicationList[] = [];
  filteredSurveyData: SurveyList[] = [];
  applicationTableConfig: string[] = ApplicationTableConfig;
  surveyTableConfig: string[] = SurveyTableConfig;
  subHeader: string[] = SubHeader;
  applicationTableContent = [];
  surveyTableContent = [];
  userInfo: UserDetails;
  showOrHideLoader = true;
  showOrHideSecLoader = true;
  constructor(
    private router: Router,
    private applicationData: DashboardService,
    private route: ActivatedRoute,
    private sortPipe: SortPipe,
  ) {}

  ngOnInit(): void {
    this.userInfo = this.route.snapshot.data.userDetail;
    this.getApplicationData();
    this.getSurveyData();
  }
  getApplicationData() {
    this.subscriptions.push(
      this.applicationData
        .getApplicationData(this.userInfo.ContactId)
        .subscribe((res: ApplicationList[]) => {
          this.showOrHideLoader = false;
          if (res) {
            this.applicationTableContent = this.sortPipe.transform(
              res,
              'applicationNumber',
              'desc',
            );
          }
        }),
    );
  }
  getSurveyData() {
    this.subscriptions.push(
      this.applicationData.getSurveyData(this.userInfo.ContactId).subscribe((res: SurveyList[]) => {
        this.showOrHideSecLoader = false;
        if (res) {
          this.filteredSurveyData = res?.filter(
            (ele) =>
              ele?.AIMS_Survey_Status.toLowerCase() !== 'not pursued' &&
              ((ele?.AIMS_Survey_Type.toLowerCase() === 'monitoring visit' &&
                ele?.AIMS_Comp_Notifd_Of_Outcome_Dt) ||
                ele?.AIMS_Survey_Type.toLowerCase() !== 'monitoring visit'),
          );
          this.surveyTableContent = this.sortPipe.transform(
            this.filteredSurveyData,
            'surveyNumber',
            'desc',
          );
        }
      }),
    );
  }

  navigatetoApplication(rowData) {
    if (rowData.Survey_Id) {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          id: rowData.Survey_Id,
          surveyNumber: rowData.AIMS_Survey_Number,
        },
      };
      this.router.navigate(['/survey/details'], navigationExtras);
    } else {
      const appId =
        rowData.applicationAsSubmittedId.length > 0
          ? rowData.applicationAsSubmittedId
          : rowData.AIMS_Intent_Id;
      const appNum =
        rowData.applicationAsSubmittedId.length > 0
          ? 'A' + rowData.applicationNumber
          : rowData.applicationNumber;
      const navigationExtras: NavigationExtras = {
        queryParams: {
          id: appId,
          appNumber: appNum,
        },
      };
      this.router.navigate(['/application/details'], navigationExtras);
    }
  }

  navigateToList(tableClicked: string) {
    if (tableClicked === 'primary') {
      this.router.navigate(['/application']);
    } else {
      this.router.navigate(['/survey']);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
