/* eslint-disable max-classes-per-file */
import { Utils } from 'src/app/shared/_models/common/Date-Formatter';

export class ItemChecklist {
  ChecklistId: string;
  ChecklistItem: string;
  ChecklistRequired: string;
  ChecklistMedium: string;
  ChecklistReceivedDate: string;

  constructor(data?: any) {
    if (data) {
      this.ChecklistId = data.AIMS_Survey_Items_Checklist_Id;
      this.ChecklistItem = data.AIMS_Items_Chklst_Item;
      this.ChecklistRequired = data.AIMS_Items_Chklst_Required;
      this.ChecklistMedium = data.AIMS_Items_Chklst_Medium;
      this.ChecklistReceivedDate = Utils.formatLongDate(data.AIMS_Items_Chklst_Dt_Received);
    }
  }
}

export class ItemsChecklist {
  ItemsChecklist: Array<ItemChecklist>;

  constructor(data?: any) {
    this.ItemsChecklist = data?.map((item) => new ItemChecklist(item));
  }
}
