import * as moment from 'moment';
import { Utils } from 'src/app/shared/_models/common/Date-Formatter';
import { ISurveyList } from '../contracts/application-list-interface';

export class SurveyList implements ISurveyList {
  Survey_Id: any;
  AIMS_Survey_Number: any;
  surveyNumber: number;
  Company_Name: any;
  AIMS_Survey_Type: any;
  AIMS_Survey_Status: any;
  AIMS_Comp_Notifd_Of_Outcome_Dt: any;
  AIMS_Accred_Decision: any;
  AIMS_Accred_Expiration_Dt: any;

  constructor(surveyData: any) {
    this.Survey_Id = surveyData.Survey_Id;
    this.AIMS_Survey_Number = surveyData.AIMS_Survey_Number;
    this.surveyNumber = parseInt(this.AIMS_Survey_Number, 10);
    this.Company_Name = surveyData.Company_Name;
    this.AIMS_Survey_Type = surveyData.AIMS_Survey_Type;
    this.AIMS_Survey_Status = surveyData.AIMS_Survey_Status;
    this.AIMS_Comp_Notifd_Of_Outcome_Dt = surveyData.AIMS_Comp_Notifd_Of_Outcome_Dt;
    this.AIMS_Accred_Decision =
      this.AIMS_Comp_Notifd_Of_Outcome_Dt && moment(this.AIMS_Comp_Notifd_Of_Outcome_Dt) <= moment()
        ? surveyData.AIMS_Accred_Decision
        : '';
    this.AIMS_Accred_Expiration_Dt =
      this.AIMS_Comp_Notifd_Of_Outcome_Dt && moment(this.AIMS_Comp_Notifd_Of_Outcome_Dt) <= moment()
        ? Utils.formatLongDate(surveyData.AIMS_Accred_Expiration_Dt)
        : null;
  }
}
