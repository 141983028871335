<div class="container" aria-label="application payment">
  <div [hidden]="openPaymentForm">
    <div class="modal-header">
      <h1 aria-label="payment due" class="modal-title" id="AppFeeHeader">Payment Due</h1>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="submittalConfirmation()"
      >
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>
    <div class="modal-body">
      <div *ngIf="showOrHideLoader">
        <app-loader></app-loader>
      </div>
      <div *ngIf="!showOrHideLoader">
        <div>
          <p class="static-text">
            Your Application Fee for Application #<span>{{ paymentModelData?.ApplicationNumber }}</span> is <span
              >{{ paymentModelData?.BaseAmount | currency}}
              {{ paymentModelData?.Company_Country === 'CANADA' ? 'CAD' : 'USD' }}</span
            >, which is non-refundable.
          </p>
        </div>
        <div *ngIf="paymentModelData?.TaxAmount > 0">
          <p class="static-text">
            Applicable Tax: <span>{{ paymentModelData?.TaxAmount | currency }}</span>
          </p>
        </div>
        <div>
          <p class="static-text">
            Our records indicate the Application Fee balance due is <span
              >{{ paymentModelData?.TotalAmount | currency }}
              {{ paymentModelData?.Company_Country === 'CANADA' ? 'CAD' : 'USD' }}.</span
            ><span *ngIf="paymentModelData?.Office_Name === 'CARF Europe'"
              >CARF will contact you if taxes apply.</span
            >
          </p>
        </div>
        <div>
          <p class="static-text">Please select a method of payment and click Continue.</p>
        </div>
        <div>
          <div class="dropdown-styles">
            <label for="paymentMethod">Payment Method</label>
            <select
              id="paymentMethod"
              class="form-control"
              (change)="onChange($event.target.value)"
            >
              <option class="option" value="" disabled selected>- Select -</option>
              <option *ngFor="let opt of dropdown" class="option" value="{{ opt }}">
                {{ opt }}
              </option>
            </select>
          </div>
        </div>
        <p class="text-margin static-text">
          Your survey application will be placed on hold until full payment has been received.
        </p>
      </div>
    </div>
    <div class="modal-footer">
      <div class="form-footer">
        <button
          class="btn btn-info mr-3"
          type="button"
          (click)="navigatetoPayment()"
          [disabled]="!enableContinueButton"
        >
          <i class="fa fa-arrow-alt-circle-right mr-2" aria-hidden="true"></i>
          Continue
        </button>
        <button
          class="btn btn-info"
          data-dismiss="modal"
          aria-label="Close"
          (click)="submittalConfirmation()"
        >
          <i class="fa fa-times-circle plus mr-2" aria-hidden="true"></i>
          Cancel
        </button>
      </div>
    </div>
  </div>

  <div class="col-md overflow-auto payment-modal-padding">
    <div [hidden]="!openPaymentForm" id="iframe_holder" class="center-block" style="width: 100%">
      <button
        type="button"
        class="close-payment"
        data-dismiss="modal"
        aria-label="Close"
        (click)="closePaymentModal()"
      >
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
      <div *ngIf="showOrHideLoader" class="loader-padding">
        <app-loader></app-loader>
      </div>
      <iframe
        id="load_payment"
        class="embed-responsive-item frame-style frame-scroll"
        name="load_payment"
        width="100%"
        height="935px"
        title="Pay Appl Fee"
      >
      </iframe>
    </div>
    <form
      id="send_hptoken"
      name="send_token"
      [action]="IFRAME_URL"
      method="post"
      target="load_payment"
      #theForm="ngForm"
    >
      <input type="hidden" name="token" [value]="tokenReceived" />
    </form>
  </div>
</div>
