import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { UserDetails } from 'src/app/core/_models/User';
import { UserService } from 'src/app/core/_services/user.service';
import { PaymentsService } from 'src/app/shared/_components/payment/services/payments.service';
import { SharedService } from 'src/app/shared/_services/shared.service';
import { certificateFormTableConfig } from 'src/app/survey/_constants/constants';
import {
  AdditionalCertificatePayment,
  PostAdditionalCertificatePayment,
} from 'src/app/survey/_models/AdditionalCertificatePayment';
import { SurveyDetails } from 'src/app/survey/_models/SurveyDetails';
import { SurveyService } from 'src/app/survey/_services/survey.service';
import { environment } from 'src/environments/environment';
import { CheckPaymentInsComponent } from './_components/check-payment-ins/check-payment-ins.component';

@Component({
  selector: 'app-additional-certificate-payment',
  templateUrl: './additional-certificate-payment.component.html',
  styleUrls: ['./additional-certificate-payment.component.scss'],
})
export class AdditionalCertificatePaymentComponent implements OnInit, OnDestroy {
  @ViewChild('theForm', { static: true, read: ElementRef }) form: ElementRef;
  @Input() quantitySmall: number;
  @Input() quantityLarge: number;
  @Input() additionalCertificatesFormValue: any;
  paymentMethod: any = [];
  orderCertificateColumns = certificateFormTableConfig;
  openPaymentForm: boolean = false;
  paymentModelData: AdditionalCertificatePayment;
  surveyDetail: SurveyDetails;
  subscriptions: Subscription[] = [];
  showOrHideLoader: boolean = false;
  orderDetails: any = {};
  currencycode: string;
  dropdown: any = [];
  disableContinue: boolean = true;
  selectedPaymentOption: any;
  acceptPaymentPageResponse = null;
  tokenReceived: any;
  transactResponse = null;
  IFRAME_URL: string;
  modalReference: NgbModalRef;
  surveyId: string;
  userInfo: UserDetails;
  selectedComponent: string;
  getSelectedComponentInfo: any;
  surveyCertId: any;

  constructor(
    private surveyService: SurveyService,
    private paymentsService: PaymentsService,
    private sharedService: SharedService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
  ) {}

  @HostListener('unloaded')
  ngOnDestroy() {
    window.removeEventListener('message', this.receiveAdditionalCertificateMessage, false);
  }

  ngOnInit(): void {
    if (window.addEventListener) {
      window.addEventListener('message', this.receiveAdditionalCertificateMessage, false);
    } else {
      (<any>window).attachEvent('onmessage', this.receiveAdditionalCertificateMessage, false);
    }
    this.selectedComponent = 'Additional Certificate Payment';
    this.paymentsService.setComponent(this.selectedComponent);
    this.IFRAME_URL = environment.paymentUrl;
    this.surveyDetail = this.surveyService.surveyDetails;
    this.surveyId = this.surveyDetail?.SurveyId;
    this.userService.data.subscribe((res) => {
      this.userInfo = res;
    });
    this.getPaymentsData();
  }

  onContinue() {
    this.paymentsService.setSummaryData('', '', '');
    this.paymentModelData.LineItems = this.additionalCertificatesFormValue;
    const updatedObj = new PostAdditionalCertificatePayment(this.paymentModelData);
    updatedObj.AIMS_Number_Certs_Ordered = +(this.quantitySmall + this.quantityLarge);
    updatedObj.AIMS_OrderedBy = this.userInfo?.ContactId;
    this.additionalCertificatesFormValue.forEach((ele, i) => {
      if (ele.Name === this.surveyDetail.CompanyName && ele?.AccordianData.order === 1)
        updatedObj.certDetails[i].AIMS_Free = true;
      else updatedObj.certDetails[i].AIMS_Free = false;
    });
    this.showOrHideLoader = true;
    this.surveyService.createAdditionalCertificateOrder(updatedObj).subscribe((element) => {
      this.surveyCertId = element?.AIMS_Survey_Certifications_Id;
      this.showOrHideLoader = false;
      if (this.selectedPaymentOption === 'Check') {
        this.modalService.dismissAll();
        this.modalReference = this.modalService.open(CheckPaymentInsComponent, {
          backdrop: 'static',
          size: 'lg',
          keyboard: true,
          centered: true,
        });
        if (this.surveyCertId) {
          this.modalReference.componentInstance.surveyCertId = this.surveyCertId;
          this.modalReference.componentInstance.additionalCertificatesFormValue =
            this.additionalCertificatesFormValue;
        }
      } else {
        this.openPaymentForm = true;
        this.showOrHideLoader = true;
        this.sharedService.getHostedPaymentPage(this.orderDetails).subscribe(
          (success) => {
            if (success) {
              this.acceptPaymentPageResponse = this.sharedService.acceptPaymentPageResponse;
              this.tokenReceived = this.acceptPaymentPageResponse.Token;
              setTimeout(() =>
                // eslint-disable-next-line @typescript-eslint/dot-notation
                (this.form.nativeElement as any)['submit'].apply(this.form.nativeElement),
              );
              this.showOrHideLoader = false;
            }
          },
          () => {
            this.paymentsService.onModelClose();
          },
        );
      }
    });
  }
  getPaymentsData() {
    this.showOrHideLoader = true;
    this.subscriptions.push(
      this.surveyService
        .getAdditionalCertificatePaymentsData(this.surveyId, this.quantitySmall, this.quantityLarge)
        .subscribe(
          (res) => {
            this.paymentModelData = res;
            if (this.paymentModelData?.Company_Country.includes('US'))
              this.paymentMethod = ['Online (Credit Card, Bank Account)', 'Check'];
            else this.paymentMethod = ['Credit Card', 'Check'];
            this.showOrHideLoader = false;
            // eslint-disable-next-line no-unused-labels
            this.orderDetails = this.paymentsService.getorderDetails(this.paymentModelData);
          },
          () => {
            this.paymentsService.onModelClose();
          },
        ),
    );
  }

  receiveAdditionalCertificateMessage = (event: any): void => {
    if (event != null && event.data != null) {
      // eslint-disable-next-line no-cond-assign
      if (event.data?.qstr?.includes('cancel')) {
        this.paymentsService.onModelClose();
        this.router.navigateByUrl('/');
      } else if (event.data?.qstr?.includes('resizeWindow')) {
        console.log('resize window' + event.data.qstr);
      } else {
        this.transactResponse = JSON.parse(this.paymentsService.parseQueryString(event?.data));
        // transaction approved
        if (
          this.transactResponse.responseCode === '1' ||
          this.transactResponse.responseCode === '4'
        ) {
          console.log(
            'Transaction approved - account:' +
              this.transactResponse.accountNumber +
              ', total amount:' +
              this.transactResponse.totalAmount,
          );
          this.getSelectedComponentInfo = this.paymentsService.getComponent();
          if (this.getSelectedComponentInfo === this.selectedComponent && this.surveyCertId) {
            const updateCertPayment = {
              'AIMS_Survey_Certificates_Id': this.surveyCertId,
            };
            this.sharedService.updateCertificatesPayment(updateCertPayment).subscribe();
            this.paymentsService.goToPaymentSummary(this.transactResponse);
          }
        }
        // TO DO: handle various responses
        else {
          console.log('Transaction declined, response code:' + this.transactResponse.responseCode);
        }
      }
    } else {
      console.log('Payment received bad message');
    }
  };

  changeData(event) {
    if (!event) {
      this.disableContinue = true;
    } else {
      this.disableContinue = false;
      this.selectedPaymentOption = event;
    }
  }

  OnCancel() {
    this.paymentsService.onModelClose();
  }
}
