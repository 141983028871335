/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-labels */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prettier/prettier */
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { PaymentData } from 'src/app/shared/_models/PaymentData';
import { SharedService } from 'src/app/shared/_services/shared.service';
import { environment } from 'src/environments/environment';
import { ApplFeePaymentInstructionsComponent } from '../appl-fee-payment-instructions/appl-fee-payment-instructions.component';
import { PaymentsService } from '../services/payments.service';

@Component({
  selector: 'app-appl-fee-payment-modal',
  templateUrl: './appl-fee-payment-modal.component.html',
  styleUrls: ['./appl-fee-payment-modal.component.scss'],
})
export class ApplFeePaymentModalComponent implements OnInit, OnDestroy {
  @ViewChild('vcClose', { static: true }) saveBtn: any;
  @ViewChild('theForm', { static: true, read: ElementRef }) form: ElementRef;
  @Input() dataToTakeAsInput: any;
  modalRes: any;
  tableData: any[];
  paymentMethodForm: any;
  dropdown: any = [];
  public acceptPaymentPageResponse = null;
  transactResponse = null;
  openPaymentForm: boolean = false;
  tokenReceived: any;
  paymentModelData: PaymentData;
  selectedPaymentOption: any;
  enableContinueButton: boolean = false;
  openAppSubmittalConfirmation: boolean = false;
  orderDetails: any = {};
  currencycode: string;
  description: any;
  showOrHideLoader = true;
  paymentData: any;
  subscriptions: Subscription[] = [];
  public IFRAME_URL: string;
  HOSTEDPAYMENT_URL: string;
  selectedComponent: string;
  getSelectedComponentInfo: any;
  isSubmitted: any = false;
  modalReference: NgbModalRef;
  printDataModal: any;
  @Output() printAction: EventEmitter<any> = new EventEmitter<any>();
  updatePaymentData: any;

  constructor(
    private sharedService: SharedService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private paymentsService: PaymentsService,
  ) {}

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (window.addEventListener) {
      window.removeEventListener('message', this.receiveMessage, false);
    }
  }

  ngOnInit(): void {
    if (window.addEventListener) {
      window.addEventListener('message', this.receiveMessage, false);
    } else {
      (<any>window).attachEvent('onmessage', this.receiveMessage, false);
    }
    this.selectedComponent = 'Application Payment';
    this.paymentsService.setComponent(this.selectedComponent);
    this.IFRAME_URL = environment.paymentUrl;
    this.HOSTEDPAYMENT_URL = environment.hostedUrl;
    this.paymentData = this.dataToTakeAsInput;
    this.isSubmitted = this.paymentData.isSubmitted;
    this.getPaymentsData();
  }

  getPaymentsData() {
    this.showOrHideLoader = true;
    this.subscriptions.push(
      this.sharedService
        .getPaymentsData(this.paymentData?.applicationId, this.paymentData?.compSubmittingIntentId)
        .subscribe(
          (res) => {
            this.paymentModelData = res;
            this.showOrHideLoader = false;
            if (this.paymentModelData?.Company_Country === 'CANADA') {
              this.currencycode = 'CAD';
            } else {
              this.currencycode = 'USD';
            }
            // eslint-disable-next-line no-unused-labels
            this.orderDetails = this.paymentsService.getorderDetails(this.paymentModelData);
            if (this.paymentModelData?.Office_Name === 'CARF Europe') {
              this.dropdown = ['Electronic Payment', 'Credit Card'];
            } else if (
              this.paymentModelData?.Office_Name !== 'CARF Europe' &&
              this.paymentModelData?.Company_Country === 'US'
            ) {
              this.dropdown = ['Online (Credit Card, Bank Account)', 'Check', 'Wire Transfer'];
            } else {
              this.dropdown = ['Credit Card', 'Check', 'Wire Transfer'];
            }
          },
          () => {
            this.modalService.dismissAll();
          },
        ),
    );
  }

  navigatetoPayment() {
    const id = this.paymentData?.applicationId;
    const officeName = this.paymentModelData?.Office_Name;
    this.paymentsService.setSummaryData(
      id,
      officeName,
      this.selectedPaymentOption,
      this.isSubmitted,
    );
    this.openPaymentForm = true;
    this.openAppSubmittalConfirmation = false;
    this.updatePaymentData = {
      'AIMS_Intent_Id': this.paymentData?.applicationId,
      'AIMS_Intent_Fee_Payment_Method': this.selectedPaymentOption,
    };

    if (
      this.selectedPaymentOption === 'Credit Card' ||
      this.selectedPaymentOption === 'Online (Credit Card, Bank Account)'
    ) {
      this.showOrHideLoader = true;
      this.sharedService.getHostedPaymentPage(this.orderDetails).subscribe(
        (success) => {
          if (success) {
            this.acceptPaymentPageResponse = this.sharedService.acceptPaymentPageResponse;
            this.tokenReceived = this.acceptPaymentPageResponse.Token;
            setTimeout(() =>
              // eslint-disable-next-line @typescript-eslint/dot-notation
              (this.form.nativeElement as any)['submit'].apply(this.form.nativeElement),
            );
            this.showOrHideLoader = false;
          }
        },
        () => {
          this.paymentsService.onModelClose();
        },
      );
    } else if (
      this.selectedPaymentOption !== 'Credit Card' ||
      this.selectedPaymentOption !== 'Online (Credit Card, Bank Account)'
    ) {
      this.sharedService.updateOtherPayment(this.updatePaymentData).subscribe();
      if (this.isSubmitted) {
        this.submittalConfirmation();
      } else {
        this.paymentsService.onModelClose();
        this.modalReference = this.modalService.open(ApplFeePaymentInstructionsComponent, {
          backdrop: 'static',
          size: 'lg',
          keyboard: true,
          centered: true,
        });
        this.modalReference.componentInstance.paymentModelData = this.paymentModelData;
        this.modalReference.componentInstance.selectedPaymentOption = this.selectedPaymentOption;
        this.modalReference.componentInstance.printAction.subscribe((res: any) => {
          if (res) {
            this.printAction.emit(res);
          }
        });
      }
    }
  }
  submittalConfirmation() {
    this.modalService.dismissAll();
    if (this.isSubmitted) {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          id: this.paymentData?.applicationId,
          officeName: this.paymentModelData?.Office_Name,
          selectedPaymentOption: this.selectedPaymentOption,
          totalAmount: this.paymentModelData?.TotalAmount,
        },
      };
      this.router.navigate(['application/application-submittal-confirmation'], navigationExtras);
    }
  }

  receiveMessage = (event: any): void => {
    if (event != null && event.data != null) {
      // eslint-disable-next-line no-cond-assign
      if (event.data?.qstr?.includes('cancel')) {
        this.paymentsService.onModelClose();
        console.log('Cancel' + event.data.qstr);
        this.submittalConfirmation();
      } else if (event.data?.qstr?.includes('resizeWindow')) {
        console.log('resize window' + event.data.qstr);
      } else {
        this.transactResponse = JSON.parse(this.paymentsService.parseQueryString(event?.data));
        // transaction approved
        if (
          this.transactResponse.responseCode === '1' ||
          this.transactResponse.responseCode === '4'
        ) {
          console.log(
            'Transaction approved - account:' +
              this.transactResponse.accountNumber +
              ', total amount:' +
              this.transactResponse.totalAmount,
          );
          this.getSelectedComponentInfo = this.paymentsService.getComponent();
          if (this.getSelectedComponentInfo === this.selectedComponent) {
            this.sharedService.updateApplicationPayment(this.updatePaymentData).subscribe();
            this.paymentsService.goToPaymentSummary(this.transactResponse);
          }
        } else {
          console.log('Transaction declined, response code:' + this.transactResponse.responseCode);
        }
      }
    } else {
      console.log('Payment received bad message');
    }
  };

  onChange(selectedValue) {
    this.enableContinueButton = true;
    console.log(selectedValue);
    this.selectedPaymentOption = selectedValue;
  }

  closePaymentModal() {
    this.modalService.dismissAll();
  }
}
