<div class="container ngb-modal-container">
  <div class="modal-header">
    <h1 aria-label="additional-payment-instruction" class="modal-title">
      {{ 'SURVEY.COMPLEXHEADER.CHECKPAYMENTINS' | translate }}
    </h1>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="OnCancel()"
    >
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
  </div>
  <div class="modal-body" id="checkPaymentInstruction">
    <div *ngIf="showOrHideLoader">
      <app-loader></app-loader>
    </div>
    <div class="container-fluid dlg-container" *ngIf="!showOrHideLoader">
      <div class="dlg-body">
        <div class="row">
          <div class="col-md-12 dlg-para">
            <p>
              Additional certificate(s) order confirmation for survey #
              <span>{{ checkPaymentData?.SurveyNumber }}</span
              >. Please include this form with the mailed payment.
            </p>
          </div>
          <div class="col-md-12">
            <h2 id="checkPaymentTable" class="tableHeader">Order Summary</h2>
            <div class="table-responsive accordian-form">
              <table class="table" aria-describedby="checkPaymentTable">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="rightMargin table-header-styling"
                      *ngFor="let column of orderSummaryColumns"
                    >
                      {{ column | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of checkPaymentData?.AdditionalCerificates; let i = index">
                    <td>
                      <div
                        *ngIf="
                          data?.CompanyName === surveyDetails?.CompanyName &&
                            additionalCertificatesFormValue[i]?.AccordianData.order === 1;
                          else companyName
                        "
                      >
                        Additional copies of Complimentary Certificate
                      </div>
                      <ng-template #companyName>
                        {{ data.CompanyName }}
                      </ng-template>
                    </td>
                    <td>
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          [attr.aria-label]="'Size' + i"
                          [value]="data.Size"
                          id="inputSize{{ i }}"
                          readonly
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          [attr.aria-label]="i"
                          [value]="data.Quantity"
                          readonly
                          id="inputQuantity{{ i }}"
                        />
                      </div>
                    </td>
                    <td>
                      {{ data.Amount | currency }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="form-group col-md-12">
            <p>
              Subtotal
              <span
                >{{ checkPaymentData?.OrderTotal | currency
                }}{{ surveyDetails?.CompanyCountry?.includes('CANADA') ? ' CAD' : ' USD' }}</span
              >
            </p>
            <p>
              Applicable Tax <span>{{ checkPaymentData?.SalesTax | currency }}</span>
            </p>
            <p>
              Total
              <span
                >{{ checkPaymentData?.Total | currency
                }}{{ surveyDetails?.CompanyCountry?.includes('CANADA') ? ' CAD' : ' USD' }}</span
              >
            </p>
            <p>
              Please mail check to <span>{{ mailAddress }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn mr-2 print-button" aria-label="Print" (click)="onPrint()">
      <i class="fa fa-print mr-2" aria-hidden="true"></i>
      {{ 'COMMON.PRINT' | translate }}
    </button>
    <button type="button" class="btn" data-dismiss="modal" aria-label="Close" (click)="OnCancel()">
      <i class="fa fa-times-circle plus mr-2" aria-hidden="true"></i>
      {{ 'COMMON.CLOSE' | translate }}
    </button>
  </div>
</div>
