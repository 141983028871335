<div class="container ngb-modal-container">
  <div class="modal-header">
    <h1 aria-label="additional certificate" id="AdditionalCertiHeader" class="modal-title">
      {{ 'SURVEY.COMPLEXHEADER.ORDERADD.ADDCERTIFICATE' | translate }}
    </h1>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="OnCancel()"
    >
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="showOrHideLoader">
      <app-loader></app-loader>
    </div>
    <form (ngSubmit)="onSubmit()" *ngIf="!showOrHideLoader">
      <div class="additional-certificates-container">
        <div class="col-sm-12 col-lg-12">
          <p class="mb-3 sub-header">
            Certificates will be shipped approximately four weeks after receipt of a completed order
            and payment.
          </p>
          <p>Click the arrow to the right of the location name to review the address and programs.</p>
        </div>
        <div class="row table-padding">
          <div class="col-sm-12 col-lg-12">
            <p id="additionalCertificates" class="tableHeader col-12">Order</p>
            <div class="table-responsive">
              <table class="table global-tbl" aria-describedby="additionalCertificates">
                <thead>
                  <tr class="header-color">
                    <th scope="col" *ngFor="let column of orderCertificateColumns">
                      {{ column | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody class="tableBody cursor custom-accordian">
                  <tr
                    *ngFor="let data of additionalCertificatesForm.controls; let i = index"
                    [formGroup]="data"
                  >
                    <td>
                      <div
                        class="additional-copy"
                        *ngIf="
                          additionalCertificates[i]?.Name === surveyDetail.CompanyName && i === 0
                        "
                      >
                        Additional copies of Complimentary Certificate
                      </div>
                      <app-stepper-form
                        [accordianData]="data.controls.AccordianData.value"
                        [showStepperIcon]="false"
                        [fontStyle]="additionalCertificates[i]?.Name === surveyDetail.CompanyName"
                      >
                        <div class="pl-2">
                          <div class="global-tbl-container">
                            <div *ngIf="additionalCertificates[i]?.Address2">
                              {{ additionalCertificates[i]?.Address1 }}
                              , {{ additionalCertificates[i]?.Address2 }}
                            </div>
                            <div *ngIf="!additionalCertificates[i]?.Address2">
                              {{ additionalCertificates[i]?.Address1 }}
                            </div>
                            <div *ngIf="additionalCertificates[i]?.Address3">
                              {{ additionalCertificates[i]?.Address3 }}
                            </div>
                            <div *ngIf="countryList.includes(additionalCertificates[i]?.Country)">
                              {{ additionalCertificates[i]?.Zip }}
                              {{ additionalCertificates[i]?.City }}
                            </div>
                            <div *ngIf="!countryList.includes(additionalCertificates[i]?.Country)">
                              {{ additionalCertificates[i]?.City }}
                              , {{ additionalCertificates[i]?.State }}
                              {{ additionalCertificates[i]?.Zip }}
                            </div>
                            <div>
                              <div *ngFor="let program of additionalCertificates[i]?.Programs">
                                <div
                                  *ngIf="
                                    ( (i === 0 &&
                                      additionalCertificates[i]?.Name ===
                                        surveyDetail.CompanyName &&
                                      program.ProgramLongDescription !==
                                        'Administrative Location Only') || 
                                        (i !== 0) )                             
                                  "
                                >
                                  - {{ program.ProgramLongDescription
                                  }}<span *ngIf="program.FieldCategory">:</span>
                                  {{ program.FieldCategory }}
                                  <span *ngIf="program.PopulationServed">(</span
                                  >{{ program.PopulationServed
                                  }}<span *ngIf="program.PopulationServed">)</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </app-stepper-form>
                    </td>
                    <td>
                      <div class="form-group">
                        <select
                          [attr.aria-label]="'Size' + i"
                          id="Size{{ i }}"
                          maxlength="80"
                          formControlName="Size"
                          class="form-control size-width"
                          (change)="onValueChange(i, data.controls.Quantity.value, data)"
                        >
                          <option [ngValue]="null" [selected]="true" disabled>- Select -</option>
                          <option class="option" *ngFor="let size of sizeOptions" [ngValue]="size">
                            {{ size }}
                          </option>
                        </select>
                      </div>
                    </td>
                    <td class="quantity-width">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        [attr.aria-label]="i"
                        (input)="removeDot($event.target.value, i)"
                        formControlName="Quantity"
                        (change)="onValueChange(i, $event.target.value, data)"
                      />
                    </td>
                    <td>
                      <span *ngIf="data.controls.ItemAmount.value !== 0">
                        {{ data.controls.ItemAmount.value | currency }}
                      </span>
                    </td>
                  </tr>
                  <td [attr.colspan]="orderCertificateColumns.length" class="text-right">
                    <span class="sub-total"> Subtotal </span><br />
                    <span class="sub-value">
                      {{ subTotal | currency }}
                      {{ surveyDetail?.CompanyCountry?.includes('CANADA') ? 'CAD' : 'USD' }}
                    </span>
                  </td>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn mr-2"
      aria-label="Save"
      data-backdrop="static"
      (click)="openAdditionalPaymentModal()"
      [disabled]="subTotal <= 0"
    >
      <i class="fa fa-arrow-alt-circle-right mr-2" aria-hidden="true"></i>
      {{ 'COMMON.NEXT' | translate }}
    </button>
    <button
      type="button"
      class="btn"
      data-dismiss="modal"
      aria-label="Close"
      (click)="OnCancel()"
      #vcClose
    >
      <i class="fa fa-times-circle plus mr-2" aria-hidden="true"></i>
      {{ 'COMMON.CANCEL' | translate }}
    </button>
  </div>
</div>
