<div class="container ngb-modal-container">
  <div [hidden]="openPaymentForm">
    <div class="modal-header">
      <h1 aria-label="additional payment" class="modal-title">Payment Due</h1>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="OnCancel()"
      >
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>
    <div class="modal-body">
      <div *ngIf="showOrHideLoader">
        <app-loader></app-loader>
      </div>
      <div *ngIf="!showOrHideLoader">
        <div class="row">
          <div class="col-12">
            <p id="paymentTable" class="tableHeader col-12">Order Summary</p>
          </div>
        </div>
        <div class="table-responsive">
          <table
            class="table payAdditionalPayment-tbl table-pointer"
            aria-describedby="paymentTable"
          >
            <thead>
              <tr>
                <th scope="col" *ngFor="let column of orderCertificateColumns">
                  {{ column | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of additionalCertificatesFormValue; let i = index">
                <td>
                  <div
                    *ngIf="
                      data.Name === surveyDetail.CompanyName && data?.AccordianData.order === 1;
                      else companyName
                    "
                  >
                    Additional copies of Complimentary Certificate
                  </div>
                  <ng-template #companyName>
                    {{ data.Name }}
                  </ng-template>
                </td>
                <td>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      [attr.aria-label]="'Size' + i"
                      [value]="data.Size"
                      readonly
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      [attr.aria-label]="i"
                      [value]="data.Quantity"
                      readonly
                    />
                  </div>
                </td>
                <td>
                  {{ data.ItemAmount | currency }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form-group col-md-12">
          <p>
            Subtotal <span
              >{{ paymentModelData?.BaseAmout | currency }}
              {{ paymentModelData?.Company_Country.includes('CANADA') ? 'CAD' : 'USD' }}</span
            >
          </p>
          <p>
            Applicable Tax <span>{{ paymentModelData?.TaxAmount | currency  }}</span>
          </p>
          <p>
            Total <span
              >{{ paymentModelData?.TotalAmount | currency }}
              {{ paymentModelData?.Company_Country.includes('CANADA') ? 'CAD' : 'USD' }}</span
            >
          </p>
          <p>Please select a method of payment and click Continue.</p>
        </div>
        <div class="form-group col-md-6">
          <label for="PaymntMethod">Payment Method</label>
          <div class="form-group">
            <select
              aria-label="paymentMethod"
              class="form-control"
              (change)="changeData($event.target.value)"
            >
              <option class="option" value="" disabled selected>- Select -</option>
              <option class="option" *ngFor="let option of paymentMethod" [value]="option">
                {{ option }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div>
        <button
          type="button"
          class="btn btn-info mr-3"
          aria-label="Save"
          data-backdrop="static"
          data-dismiss="modal"
          (click)="onContinue()"
          [disabled]="disableContinue"
        >
          <i class="fa fa-arrow-alt-circle-right mr-2" aria-hidden="true"></i>
          {{ 'COMMON.CONTINUE' | translate }}
        </button>
        <button class="btn btn-info" data-dismiss="modal" aria-label="Close" (click)="OnCancel()">
          <i class="fa fa-times-circle plus mr-2" aria-hidden="true"></i>
          {{ 'COMMON.CANCEL' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="col-md overflow-auto payment-modal-padding">
    <div [hidden]="!openPaymentForm" id="iframe_holder" class="center-block" style="width: 100%">
      <button
        type="button"
        class="close-payment"
        data-dismiss="modal"
        aria-label="Close"
        (click)="OnCancel()"
      >
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
      <div *ngIf="showOrHideLoader">
        <app-loader></app-loader>
      </div>
      <iframe
        id="load_payment"
        class="embed-responsive-item frame-style frame-scroll"
        name="load_payment"
        width="100%"
        height="935px"
        title="Pay Additional Cert Fee"
      >
      </iframe>
    </div>
    <form
      id="send_hptoken"
      name="send_token"
      [action]="IFRAME_URL"
      method="post"
      target="load_payment"
      #theForm="ngForm"
    >
      <input type="hidden" name="token" [value]="tokenReceived" />
    </form>
  </div>
</div>
