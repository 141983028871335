<div id="accordion{{accordianData.order}}">
  <div class="acc-section" [class.active]="accordianData.active">
    <div class="acc-wrapper">
      <div class="acc-header"  id="heading{{ accordianData.order }}" (click)="toggle()">
        <button data-toggle="collapse" [attr.data-target]="'#collapse' + accordianData.order" aria-expanded="true"
          [attr.aria-controls]="'collapse'+accordianData.order">
          <div class="d-flex">
            <div class="icon" *ngIf="showStepperIcon" >
              <i class="{{ accordianData.stepIcon }}" aria-hidden="true">
                <i class="{{ accordianData.stepInsideIcon }}" aria-hidden="true"></i>
              </i>
              <div class="v-line"></div>
            </div>
            <div class="w-100 d-flex acc-title">
              <h3 [ngClass]="fontStyle ? 'italicStyle section-title' : 'section-title'">{{ accordianData.sectionTitle | translate }}</h3>
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </div>
          </div>
        </button>
      </div>
      <div id="collapse{{ accordianData.order }}" class="collapse {{ expanded }}" 
        [attr.data-parent]="'#accordion' + accordianData.order">
        <div class="acc-body">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>