<div class="unsave-form-container">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ dialogInfo?.title }}</h4>
      <!--Close button at top right-->
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="cancel()"
      >
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>
    <div class="modal-body d-flex"> 
      <div class="pr-2" *ngIf="dialogInfo?.showWarningIcon">
        <span [ngClass]="dialogInfo?.warningIcon" aria-hidden="true"></span>
      </div>
      <div class="p-txt" [innerHTML]="dialogInfo?.message"></div>
    </div>
    <div class="modal-footer">
      <div class="form-footer d-flex">
        <div>
          <button 
            class="btn mr-3" 
            aria-label="yes"
            type="button" 
            (click)="yes()" 
            *ngIf="dialogInfo?.showYesButton"
          >
            <i class="fa mr-2 plus" [ngClass]="dialogInfo?.yesButtonIcon" aria-hidden="true"></i>
            {{ dialogInfo?.yesButtonText }}
          </button>
          
          <button
            class="btn mr-3"
            aria-label="no"
            (click)="no()"
            *ngIf="dialogInfo?.showNoButton"
          >
            <i class="fa mr-2 plus" [ngClass]="dialogInfo?.noButtonIcon" aria-hidden="true"></i>
            {{ dialogInfo?.noButtonText }}
          </button>

          <button
            class="btn mr-3"
            data-dismiss="modal"
            aria-label="Cancel"
            (click)="cancel()"
            *ngIf="dialogInfo?.showCancelButton"
          >
            <i class="fa plus mr-2" [ngClass]="dialogInfo?.cancelButtonIcon" aria-hidden="true"></i> 
            {{ dialogInfo?.cancelButtonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
