import { CountryList } from 'src/app/shared/_constants/constants';
import { SimpleHeader } from 'src/app/shared/_models/SimpleHeader';

export class UserDetails {
  UserRole: string;
  FirstName: string;
  AIMSMidInitial: string;
  LastName: string;
  ShortName: string;
  JobTitle: string;
  CompanyId: string;
  City: string;
  State: string;
  ContactId: string;
  AIMSAzureB2CID: string;
  RnCreateDate: Date;
  Email: string;
  FullName: string;
  customerConnectAccess: boolean;
  delegatedAdministrator: boolean;
  applicationEditor: boolean;
  applicationSubmitter: boolean;
  AIMSInactive: boolean;
  RnCreateUser: string;
  RnEditUser: string;
  RnEditDate: Date;
  RnCrAIMSCCLastLoginDate: Date;
  CompanyName: string;
  Address1: string;
  Address2: string;
  Address3: string;
  Zip: string;
  Country: string;
  Title: string;
  ApplicationCredentials: string;
  ApplicationPhoneTxt: string;
  Extension: string;
  ApplicationCellTxt: string;
  ApplicationPrefPhone: string;
  ApplicationPrefPronouns: string;
  AccessToPublications: boolean;
  HeaderContent: SimpleHeader;

  constructor(res?: any) {
    if (res) {
      this.UserRole = res.UserRole;
      this.FirstName = res.First_Name;
      this.AIMSMidInitial = res.AIMS_Mid_Initial;
      this.LastName = res.Last_Name;
      this.ShortName = this.FirstName.slice(0, 1) + this.LastName.slice(0, 1);
      this.JobTitle = res.AIMS_Job_Title;
      this.CompanyId = res.Company_Id;
      this.City = res.City;
      this.State = res.State;
      this.ContactId = res.Contact_Id;
      this.AIMSAzureB2CID = res.AIMS_Azure_B2C_ID;
      this.Email = res.Email;
      this.FullName = res.Full_Name;
      this.customerConnectAccess = res.AIMS_CC_User;
      this.delegatedAdministrator = res.AIMS_CC_DA;
      this.applicationEditor = res.AIMS_CC_Editor;
      this.applicationSubmitter = res.AIMS_CC_Submitter;
      this.AIMSInactive = res.AIMS_Inactive;
      this.CompanyName = res.Company_Name;
      this.Address1 = res.Address_1;
      this.Address2 = res.Address_2;
      this.Address3 = res.Address_3;
      this.Zip = res.Zip;
      this.Country = res.Country;
      this.Title = res.Title;
      this.ApplicationCredentials = res.AIMS_Credentials;
      this.ApplicationPhoneTxt = res.AIMS_Phone_Txt;
      this.Extension = res.Extension;
      this.ApplicationCellTxt = res.AIMS_Cell_Txt;
      this.ApplicationPrefPhone = res.AIMS_Pref_Phone;
      this.ApplicationPrefPronouns = res.AIMS_Pref_Pronouns;
      this.AccessToPublications = res.AIMS_CC_Access_to_Publications;
      this.HeaderContent = new SimpleHeader();
      this.HeaderContent.Header = this.FullName;
      this.HeaderContent.Title = this.Email;
      this.HeaderContent.Department = this.CompanyName;
      if (this.Address2) {
        this.HeaderContent.Address.Line1 = `${this.Address1}, ${this.Address2}`;
      } else {
        this.HeaderContent.Address.Line1 = `${this.Address1}`;
      }
      this.HeaderContent.Address.Line2 = this.Address3;
      if (CountryList.includes(this.Country?.toUpperCase())) {
        this.HeaderContent.Address.Line3 = `${this.Zip} ${this.City}`;
      } else {
        this.HeaderContent.Address.Line3 = `${this.City}, ${this.State} ${this.Zip}`;
      }
      this.HeaderContent.Address.Line4 = this.Country;
      this.HeaderContent.ActionButton = 'Report a Change of Email/Address';
    }
  }
}
