<nav class="navbar navbar-inverse header-right" role="navigation" aria-labelledby="headerNav">
  <div class="container-fluid" id="headerNav">
    <a
      class="show-only-md toggle-bar"
      aria-label="openNav"
      (click)="openNav()"
      href="JavaScript:void(0)"
    >
      <i class="fa fa-bars" aria-hidden="true"></i>
    </a>
    <div class="d-flex align-items-center logo">
      <a
        *ngIf="router.url !== '/login' && tokens !== 0"
        routerLink="/home"
        class="d-flex show-only-lg align-items-center"
      >
        <img
          src="../../../../assets/images/Customer_Connect_logo.png"
          alt="Customer Connect Logo"
        />
      </a>
      <a
        *ngIf="router.url === '/login' || tokens === 0"
        routerLink="/login"
        class="d-flex show-only-lg align-items-center"
      >
        <img
          src="../../../../assets/images/Customer_Connect_logo.png"
          alt="Customer Connect Logo"
        />
      </a>
    </div>
    <ul class="nav navbar-right">
      <li>
        <a
          role="link"
          href="{{ robohelpURL }}"
          target="_blank"
          rel="noopener"
          class="header-nav"
          >{{ 'COMMON.HELP' | translate }}</a
        >
      </li>
      <li>
        <a role="link" routerLink="/contactUs" target="_blank" class="header-nav">{{
          'COMMON.CONTACTUS' | translate
        }}</a>
      </li>
      <li
        class="signout"
        *ngIf="
          router.url !== '/login' &&
          router.url !== '/error' &&
          router.url !== '/state' &&
          router.url !== '/code' &&
          router.url !== '/login#main-content' &&
          tokens !== 0
        "
      >
        <a role="link" href="JavaScript:void(0)" class="header-nav sign-out" (click)="logout()">{{
          'COMMON.SIGNOUT' | translate
        }}</a>
      </li>
    </ul>
  </div>
</nav>
