/* eslint-disable max-classes-per-file */
import * as moment from 'moment';
import { ApplicationList } from 'src/app/application/_models/ApplicationList';
import { AnnualReportList } from 'src/app/survey/_models/AnnualList';
import { MissingItems } from 'src/app/survey/_models/MissingItems';
import { SurveyList } from 'src/app/survey/_models/SurveyList';

export class ActionItemObject {
  id: number = 0;
  icon: string = '';
  status: string = '';
  title: string = '';
  dueDate?: Date;
  buttonText: string = '';
  buttonResponse: string = '';
  routerPath?: string = '';
  actionitemData?: any = '';
  constructor(data?: any) {
    if (data) {
      Object.assign(data);
    }
  }
}
export class ApplicationPendingSubmission {
  applicationId: string;
  applicationNumber: string;
  applicationStatus: string = '';
  applicationAsSubmittedId: string;
  dueDate: string;
  status: string = '';
  constructor(data?: any) {
    this.applicationId = data.AIMS_Intent_Id;
    this.applicationNumber = data.AIMS_Intent_Number;
    this.applicationAsSubmittedId = data.AIMS_Intent_As_Submitted_Id;
    this.applicationStatus = data.AIMS_Intent_Status;
    this.dueDate = data.AIMS_Application_Due_Dt
      ? moment(data.AIMS_Application_Due_Dt).format('YYYY-MM-DD')
      : null;
    if (this.dueDate && this.dueDate < moment().format('YYYY-MM-DD')) {
      this.status = 'OVERDUE';
    }
  }
}

export class ApplicationFeeDue {
  intentId: string;
  compSubmittingIntentId: string;
  applicationAcknowledgmentDate: Date;
  applicationFeeFullyPaidDate: Date;
  applicationApprovedToProceed: boolean;
  dueDate: string;
  status: string = '';

  constructor(data?: any) {
    this.intentId = data.AIMS_Intent_Id;
    this.compSubmittingIntentId = data.AIMS_Comp_Submttng_Intent_Id;
    this.applicationAcknowledgmentDate = data.AIMS_Intent_Acknlgmnt_Dt
      ? new Date(data.AIMS_Intent_Acknlgmnt_Dt)
      : null;
    this.applicationFeeFullyPaidDate = data.AIMS_Intent_Fee_Fully_Paid_Dt
      ? new Date(data.AIMS_Intent_Fee_Fully_Paid_Dt)
      : null;
    this.applicationApprovedToProceed = data.AIMS_Intent_Apprvd_To_Procd;
    this.dueDate = data.AIMS_Intent_Fee_Due_Dt
      ? moment(data.AIMS_Intent_Fee_Due_Dt).format('YYYY-MM-DD')
      : null;
    if (this.dueDate && this.dueDate < moment().format('YYYY-MM-DD')) {
      this.status = 'OVERDUE';
    }
  }
}

export class SurveyFeeDue {
  surveyId: string;
  surveyType: string = '';
  sisSentDate: Date;
  surveyFeeFullyPaidDate: Date;
  surveyApprovedToProceed: boolean;
  dueDate: string;
  status: string = '';

  constructor(data?: any) {
    this.surveyId = data?.Survey_Id;
    this.surveyType = data.AIMS_Survey_Type;
    this.sisSentDate = data.AIMS_Survey_Info_Lettr_Sent_Dt
      ? new Date(data.AIMS_Survey_Info_Lettr_Sent_Dt)
      : null;
    this.surveyFeeFullyPaidDate = data.AIMS_Survey_Fee_Fully_Paid_Dt
      ? new Date(data.AIMS_Survey_Fee_Fully_Paid_Dt)
      : null;
    this.surveyApprovedToProceed = data.AIMS_Survey_Apprvl_To_Procd;
    this.dueDate = data.AIMS_Dt_Survey_Fee_Due
      ? moment(data.AIMS_Dt_Survey_Fee_Due).format('YYYY-MM-DD')
      : null;
    if (this.dueDate && this.dueDate < moment().format('YYYY-MM-DD')) {
      this.status = 'OVERDUE';
    }
  }
}

export class CertificationFeeDue {
  surveyId: string;
  surveyType: string = '';
  surveyFeeFullyPaidDate: Date;
  surveyApprovedToProceed: boolean;
  dueDate: string;
  status: string = '';

  constructor(data?: any) {
    this.surveyId = data?.Survey_Id;
    this.surveyType = data.AIMS_Survey_Type;
    this.surveyFeeFullyPaidDate = data.AIMS_Survey_Fee_Fully_Paid_Dt
      ? new Date(data.AIMS_Survey_Fee_Fully_Paid_Dt)
      : null;
    this.surveyApprovedToProceed = data.AIMS_Survey_Apprvl_To_Procd;
    this.dueDate = data.AIMS_Dt_Survey_Fee_Due
      ? moment(data.AIMS_Dt_Survey_Fee_Due).format('YYYY-MM-DD')
      : null;
    if (this.dueDate && this.dueDate < moment().format('YYYY-MM-DD')) {
      this.status = 'OVERDUE';
    }
  }
}

export class MissingApplicationItems {
  checkListId: string;
  applicationAcknowledgementDate: Date;
  surveyStartDate: Date;
  itemDateReceived: Date;
  itemsChecklistRequired: boolean;
  calculatedDueDate: Date;
  dueDate: string;
  status: string = '';

  constructor(data?: any) {
    this.checkListId = data?.AIMS_Survey_Items_Checklist_Id;
    this.applicationAcknowledgementDate = data.AIMS_Intent_Acknlgmnt_Dt
      ? new Date(data.AIMS_Intent_Acknlgmnt_Dt)
      : null;
    this.surveyStartDate = data.AIMS_Survey_Start_Dt ? new Date(data.AIMS_Survey_Start_Dt) : null;
    this.itemDateReceived = data.AIMS_Items_Chklst_Dt_Received
      ? new Date(data.AIMS_Items_Chklst_Dt_Received)
      : null;
    this.itemsChecklistRequired = data.AIMS_Items_Chklst_Required;

    this.calculatedDueDate = this.applicationAcknowledgementDate
      ? new Date(
          this.applicationAcknowledgementDate.setDate(
            this.applicationAcknowledgementDate.getDate() + 30,
          ),
        )
      : null;

    this.dueDate = this.calculatedDueDate
      ? moment(this.calculatedDueDate).format('YYYY-MM-DD')
      : null;
    if (this.dueDate && this.dueDate < moment().format('YYYY-MM-DD')) {
      this.status = 'OVERDUE';
    }
  }
}

export class AnnualReportDue {
  acqrId: string;
  surveyId: string;
  surveyNumber: string;
  acqrExpirationAnniversaryDate: Date;
  acqrReceivedDate: Date;
  dueDate: string;
  status: string = '';

  constructor(data?: any) {
    this.acqrId = data?.AIMS_ACQR_Id;
    this.surveyId = data?.AIMS_Survey_Id;
    this.surveyNumber = data?.AIMS_Survey_Number;
    this.acqrExpirationAnniversaryDate = data.AIMS_ACQR_Anniversary_Date
      ? new Date(data.AIMS_ACQR_Anniversary_Date)
      : null;
    this.acqrReceivedDate = data.AIMS_ACQR_Received_Date
      ? new Date(data.AIMS_ACQR_Received_Date)
      : null;
    this.dueDate = data?.AIMS_ACQR_Anniversary_Date
      ? moment(data.AIMS_ACQR_Anniversary_Date).format('YYYY-MM-DD')
      : null;
    if (this.dueDate && this.dueDate < moment().format('YYYY-MM-DD')) {
      this.status = 'OVERDUE';
    }
  }
}

export class AnnualMaintenanceFeeDue {
  acqrId: string;
  surveyId: string;
  acqrExpirationAnniversaryDate: Date;
  feeReceivedDate: Date;
  primaryStandardsManual: string = '';
  dueDate: string;
  status: string = '';

  constructor(data?: any) {
    this.acqrId = data?.AIMS_ACQR_Id;
    this.surveyId = data?.AIMS_Survey_Id;
    this.acqrExpirationAnniversaryDate = data.AIMS_ACQR_Anniversary_Date
      ? new Date(data.AIMS_ACQR_Anniversary_Date)
      : null;
    this.feeReceivedDate = data.AIMS_ACQR_Fee_Recd_Dt ? new Date(data.AIMS_ACQR_Fee_Recd_Dt) : null;
    this.primaryStandardsManual = data.AIMS_Standards_Manual_Name;
    this.dueDate = data?.AIMS_ACQR_Anniversary_Date
      ? moment(data.AIMS_ACQR_Anniversary_Date).format('YYYY-MM-DD')
      : null;
    if (this.dueDate && this.dueDate < moment().format('YYYY-MM-DD')) {
      this.status = 'OVERDUE';
    }
  }
}

export class QualityImprovementPlanDue {
  surveyId: string;
  surveyNumber: string;
  surveyType: string = '';
  qipDueDate: Date;
  qipReceivedDate: Date;
  qipNeedsMoreDueDate: Date;
  qipNeedsMoreReceivedDate: Date;
  qipOverdue: boolean = false;
  qipNeedsMoreOverdue: boolean = false;
  dueDate: string = '';
  status: string = '';
  companyNotifiedOfOutcomeDate: Date;

  constructor(data?: any) {
    this.surveyId = data?.Survey_Id;
    this.surveyNumber = data?.AIMS_Survey_Number;
    this.surveyType = data.AIMS_Survey_Type;

    this.qipDueDate = data.AIMS_Dt_QIP_Due ? new Date(data.AIMS_Dt_QIP_Due) : null;
    this.qipNeedsMoreDueDate = data.AIMS_QIP_Needs_More_Due_Dt
      ? new Date(data.AIMS_QIP_Needs_More_Due_Dt)
      : null;

    this.qipReceivedDate = data.AIMS_QIP_Recvd_Dt ? new Date(data.AIMS_QIP_Recvd_Dt) : null;
    this.qipNeedsMoreReceivedDate = data.AIMS_QIP_Needs_More_Recvd_Dt
      ? new Date(data.AIMS_QIP_Needs_More_Recvd_Dt)
      : null;

    this.companyNotifiedOfOutcomeDate = data.AIMS_Comp_Notifd_Of_Outcome_Dt
      ? new Date(data.AIMS_Comp_Notifd_Of_Outcome_Dt)
      : null;

    this.qipOverdue =
      this.qipDueDate &&
      moment(this.qipDueDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD');
    this.qipNeedsMoreOverdue =
      this.qipNeedsMoreDueDate &&
      moment(this.qipNeedsMoreDueDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD');

    if (
      (this.qipOverdue && this.qipReceivedDate === null) ||
      (this.qipNeedsMoreOverdue && this.qipNeedsMoreReceivedDate === null)
    ) {
      this.status = 'OVERDUE';
    }

    if (this.qipDueDate && this.qipReceivedDate === null) {
      this.dueDate = this.qipDueDate ? moment(this.qipDueDate).format('YYYY-MM-DD') : null;
    } else if (this.qipNeedsMoreDueDate && this.qipNeedsMoreReceivedDate === null) {
      this.dueDate = this.qipNeedsMoreDueDate
        ? moment(this.qipNeedsMoreDueDate).format('YYYY-MM-DD')
        : null;
    }
  }
}

export class PlanOfActionDue {
  surveyId: string;
  surveyNumber: string;
  surveyType: string = '';
  qipDueDate: Date;
  qipReceivedDate: Date;
  qipNeedsMoreDueDate: Date;
  qipNeedsMoreReceivedDate: Date;
  qipOverdue: boolean = false;
  qipNeedsMoreOverdue: boolean = false;
  dueDate: string = '';
  status: string = '';

  constructor(data?: any) {
    this.surveyId = data?.Survey_Id;
    this.surveyNumber = data?.AIMS_Survey_Number;
    this.surveyType = data.AIMS_Survey_Type;

    this.qipDueDate = data.AIMS_Dt_QIP_Due ? new Date(data.AIMS_Dt_QIP_Due) : null;
    this.qipNeedsMoreDueDate = data.AIMS_QIP_Needs_More_Due_Dt
      ? new Date(data.AIMS_QIP_Needs_More_Due_Dt)
      : null;

    this.qipReceivedDate = data.AIMS_QIP_Recvd_Dt ? new Date(data.AIMS_QIP_Recvd_Dt) : null;
    this.qipNeedsMoreReceivedDate = data.AIMS_QIP_Needs_More_Recvd_Dt
      ? new Date(data.AIMS_QIP_Needs_More_Recvd_Dt)
      : null;

    this.qipOverdue =
      this.qipDueDate &&
      moment(this.qipDueDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD');
    this.qipNeedsMoreOverdue =
      this.qipNeedsMoreDueDate &&
      moment(this.qipNeedsMoreDueDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD');

    if (
      (this.qipOverdue && this.qipReceivedDate === null) ||
      (this.qipNeedsMoreOverdue && this.qipNeedsMoreReceivedDate === null)
    ) {
      this.status = 'OVERDUE';
    }

    if (this.qipDueDate && this.qipReceivedDate === null) {
      this.dueDate = this.qipDueDate ? moment(this.qipDueDate).format('YYYY-MM-DD') : null;
    } else if (this.qipNeedsMoreDueDate && this.qipNeedsMoreReceivedDate === null) {
      this.dueDate = this.qipNeedsMoreDueDate
        ? moment(this.qipNeedsMoreDueDate).format('YYYY-MM-DD')
        : null;
    }
  }
}

export class OrderAdditionalCertificates {
  surveyId: string;
  positiveSurveyOutcome: string;
  companyNotifiedOfOutcomeDate: Date;
  dueDate: Date;
  status: string = '';

  constructor(data?: any) {
    this.surveyId = data?.Survey_Id;
    this.positiveSurveyOutcome = data.AIMS_Accred_Decision;
    this.companyNotifiedOfOutcomeDate = data.AIMS_Comp_Notifd_Of_Outcome_Dt
      ? new Date(data.AIMS_Comp_Notifd_Of_Outcome_Dt)
      : null;
    this.dueDate = null;
    this.status = '';
  }
}

export class PosterAvailable {
  schedulingNotificationDate: Date;
  surveyStartDate: Date;
  dueDate: Date;
  status: string = '';

  constructor(data?: any) {
    this.schedulingNotificationDate = data.AIMS_Schedling_Notification_Dt
      ? new Date(data.AIMS_Schedling_Notification_Dt)
      : null;
    this.surveyStartDate = data.AIMS_Survey_Start_Dt ? new Date(data.AIMS_Survey_Start_Dt) : null;
    this.dueDate = null;
    this.status = '';
  }
}

export class SAMHSAImplementationReportDue {
  acqrId: string;
  surveyId: string;
  surveyNumber: string;
  sirReceivedDate: Date;
  sirDueDate: Date;
  status: string = '';

  constructor(data?: any) {
    this.surveyId = data?.Survey_Id;
    this.surveyNumber = data?.AIMS_Survey_Number;
    this.sirReceivedDate = data.AIMS_SIR_Recvd_Dt ? new Date(data.AIMS_SIR_Recvd_Dt) : null;
    this.sirDueDate = data.AIMS_SIR_Due_Dt ? new Date(data.AIMS_SIR_Due_Dt) : null;
  }
}
export class ActionItems {
  applicationPendingSubmission: ApplicationPendingSubmission[] = [];
  applicationFeeDue: ApplicationFeeDue[] = [];
  actionItemObject: ActionItemObject[] = [];
  surveyFeeDue: SurveyFeeDue[] = [];
  certificationFeeDue: CertificationFeeDue[] = [];
  missingApplicationItems: MissingApplicationItems[] = [];
  annualReportDue: AnnualReportDue[] = [];
  annualMaintenanceFeeDue: AnnualMaintenanceFeeDue[] = [];
  qualityImprovementPlanDue: QualityImprovementPlanDue[] = [];
  planOfActionDue: PlanOfActionDue[] = [];
  orderAdditionalCertificates: OrderAdditionalCertificates[] = [];
  posterAvailable: PosterAvailable[] = [];
  samhsaImplementationReportDue: SAMHSAImplementationReportDue[] = [];

  constructor(item?: any) {
    if (item) {
      if (item.ApplicationPendingSubmission.length > 0) {
        const appList: any[] = [];
        item.ApplicationPendingSubmission.forEach((data) => {
          this.applicationPendingSubmission.push(new ApplicationPendingSubmission(data));
          appList.push(new ApplicationList(data));
        });
        if (this.applicationPendingSubmission.length > 0) {
          const title =
            this.applicationPendingSubmission.length > 1
              ? `Application Pending Submission (${this.applicationPendingSubmission.length})`
              : 'Application Pending Submission';
          const dueDate =
            this.applicationPendingSubmission.length > 1
              ? null
              : this.applicationPendingSubmission[0].dueDate;
          const status = this.applicationPendingSubmission.some((ele) => ele.status === 'OVERDUE')
            ? 'OVERDUE'
            : '';
          this.updateActionItemObject(
            'fa fa-file-import',
            title,
            dueDate,
            'Open Application',
            this.applicationPendingSubmission.length > 1 ? '/application' : 'ApplicationDetails',
            status,
            'PendingApplicaions',
            this.applicationPendingSubmission.length > 1
              ? appList
              : this.applicationPendingSubmission[0],
          );
        }
      }
      if (item.ApplicationFeeDue.length > 0) {
        const appList: any[] = [];
        item.ApplicationFeeDue.forEach((ele) => {
          this.applicationFeeDue.push(new ApplicationFeeDue(ele));
          appList.push(new ApplicationList(ele));
        });
        if (this.applicationFeeDue.length > 0) {
          const title =
            this.applicationFeeDue.length > 1
              ? `Application Fee Due (${this.applicationFeeDue.length})`
              : 'Application Fee Due';
          const dueDate =
            this.applicationFeeDue.length > 1 ? null : this.applicationFeeDue[0].dueDate;
          const status = this.applicationFeeDue.some((ele) => ele.status === 'OVERDUE')
            ? 'OVERDUE'
            : '';
          this.updateActionItemObject(
            'fa fa-file-invoice-dollar',
            title,
            dueDate,
            'Pay Application Fee',
            this.applicationFeeDue.length > 1 ? '/application' : 'openAppFeeModal',
            status,
            'AppFeeDues',
            this.applicationFeeDue.length > 1 ? appList : this.applicationFeeDue[0],
          );
        }
      }
      if (item.SurveyFeeDue.length > 0) {
        const surveyList: any[] = [];
        item.SurveyFeeDue.forEach((ele) => {
          this.surveyFeeDue.push(new SurveyFeeDue(ele));
          surveyList.push(new SurveyList(ele));
        });
        if (this.surveyFeeDue.length > 0) {
          const title =
            this.surveyFeeDue.length > 1
              ? `Survey Fee Due (${this.surveyFeeDue.length})`
              : 'Survey Fee Due';
          const dueDate = this.surveyFeeDue.length > 1 ? null : this.surveyFeeDue[0].dueDate;
          const status = this.surveyFeeDue.some((ele) => ele.status === 'OVERDUE') ? 'OVERDUE' : '';
          this.updateActionItemObject(
            'fa fa-file-invoice-dollar',
            title,
            dueDate,
            'Pay Survey Fee',
            this.surveyFeeDue.length > 1 ? '/survey' : 'openSurveyFeeModal',
            status,
            'SurveyFeeDues',
            this.surveyFeeDue.length > 1 ? surveyList : this.surveyFeeDue[0],
          );
        }
      }
      if (item.CertificationFeeDue.length > 0) {
        const surveyList: any[] = [];
        item.CertificationFeeDue.forEach((ele) => {
          this.certificationFeeDue.push(new CertificationFeeDue(ele));
          surveyList.push(new SurveyList(ele));
        });
        if (this.certificationFeeDue.length > 0) {
          const title =
            this.certificationFeeDue.length > 1
              ? `Certification Fee Due (${this.certificationFeeDue.length})`
              : 'Certification Fee Due';
          const dueDate =
            this.certificationFeeDue.length > 1 ? null : this.certificationFeeDue[0].dueDate;
          const status = this.certificationFeeDue.some((ele) => ele.status === 'OVERDUE')
            ? 'OVERDUE'
            : '';
          this.updateActionItemObject(
            'fa fa-file-invoice-dollar',
            title,
            dueDate,
            'Pay Certification Fee',
            this.certificationFeeDue.length > 1 ? '/survey' : 'openSurveyFeeModal',
            status,
            'CertificationFeeDues',
            this.certificationFeeDue.length > 1 ? surveyList : this.certificationFeeDue[0],
          );
        }
      }
      if (item.MissingApplicationItems.length > 0) {
        const missingList: any[] = [];
        item.MissingApplicationItems.forEach((ele) => {
          this.missingApplicationItems.push(new MissingApplicationItems(ele));
          missingList.push(new MissingItems(ele));
        });
        if (this.missingApplicationItems.length > 0) {
          const title =
            this.missingApplicationItems.length > 1
              ? `Missing Application Items (${this.missingApplicationItems.length})`
              : 'Missing Application Items';
          const dueDate =
            this.missingApplicationItems.length > 1
              ? null
              : this.missingApplicationItems[0].dueDate;
          const status = this.missingApplicationItems.some((ele) => ele.status === 'OVERDUE')
            ? 'OVERDUE'
            : '';
          this.updateActionItemObject(
            'fa fa-file-import',
            title,
            dueDate,
            'Upload Missing Items',
            '/survey/missingitemslist',
            status,
            'MissingItems',
            missingList,
          );
        }
      }
      if (item.AnnualReportDue.length > 0) {
        const annualList: any[] = [];
        item.AnnualReportDue.forEach((ele) => {
          this.annualReportDue.push(new AnnualReportDue(ele));
          annualList.push(new AnnualReportList(ele));
        });
        if (this.annualReportDue.length > 0) {
          const title =
            this.annualReportDue.length > 1
              ? `Annual Report Due (${this.annualReportDue.length})`
              : 'Annual Report Due';
          const dueDate = this.annualReportDue.length > 1 ? null : this.annualReportDue[0].dueDate;
          const status = this.annualReportDue.some((ele) => ele.status === 'OVERDUE')
            ? 'OVERDUE'
            : '';
          this.updateActionItemObject(
            'fa fa-file-import',
            title,
            dueDate,
            'Submit Annual Report',
            this.annualReportDue.length > 1 ? '/survey/annualreportlist' : 'AnnualReportPage',
            status,
            'AnnualReport',
            this.annualReportDue.length > 1 ? annualList : this.annualReportDue[0],
          );
        }
      }
      if (item.AnnualMaintenanceFeeDue.length > 0) {
        const annualList: any[] = [];
        item.AnnualMaintenanceFeeDue.forEach((ele) => {
          this.annualMaintenanceFeeDue.push(new AnnualMaintenanceFeeDue(ele));
          annualList.push(new AnnualReportList(ele));
        });
        if (this.annualMaintenanceFeeDue.length > 0) {
          const title =
            this.annualMaintenanceFeeDue.length > 1
              ? `Annual Maintenance Fee Due (${this.annualMaintenanceFeeDue.length})`
              : 'Annual Maintenance Fee Due';
          const dueDate =
            this.annualMaintenanceFeeDue.length > 1
              ? null
              : this.annualMaintenanceFeeDue[0].dueDate;
          const status = this.annualMaintenanceFeeDue.some((ele) => ele.status === 'OVERDUE')
            ? 'OVERDUE'
            : '';
          this.updateActionItemObject(
            'fa fa-file-invoice-dollar',
            title,
            dueDate,
            'Pay Annual Maintenance Fee',
            this.annualMaintenanceFeeDue.length > 1
              ? '/survey/annualreportlist'
              : 'openMaintainceFeeModal',
            status,
            'AnnualMaintenanceFee',
            this.annualMaintenanceFeeDue.length > 1 ? annualList : this.annualMaintenanceFeeDue[0],
          );
        }
      }
      if (item.QualityImprovementPlanDue.length > 0) {
        const surveyList: any[] = [];
        item.QualityImprovementPlanDue.forEach((ele) => {
          this.qualityImprovementPlanDue.push(new QualityImprovementPlanDue(ele));
          surveyList.push(new SurveyList(ele));
        });
        if (this.qualityImprovementPlanDue.length > 0) {
          const title =
            this.qualityImprovementPlanDue.length > 1
              ? `Quality Improvement Plan Due (${this.qualityImprovementPlanDue.length})`
              : 'Quality Improvement Plan Due';
          const dueDate =
            this.qualityImprovementPlanDue.length > 1
              ? null
              : this.qualityImprovementPlanDue[0].dueDate;
          const status = this.qualityImprovementPlanDue.some((ele) => ele.status === 'OVERDUE')
            ? 'OVERDUE'
            : '';
          this.updateActionItemObject(
            'fa fa-file-import',
            title,
            dueDate,
            'Upload Quality Improvement Plan',
            this.qualityImprovementPlanDue.length > 1 ? '/survey' : 'SurveyDetails',
            status,
            'QualityImpPlanDues',
            this.qualityImprovementPlanDue.length > 1
              ? surveyList
              : this.qualityImprovementPlanDue[0],
          );
        }
      }
      if (item.PlanofActionDue.length > 0) {
        const surveyList: any[] = [];
        item.PlanofActionDue.forEach((ele) => {
          this.planOfActionDue.push(new PlanOfActionDue(ele));
          surveyList.push(new SurveyList(ele));
        });
        if (this.planOfActionDue.length > 0) {
          const title =
            this.planOfActionDue.length > 1
              ? `Plan of Action Due (${this.planOfActionDue.length})`
              : 'Plan of Action Due';
          const dueDate = this.planOfActionDue.length > 1 ? null : this.planOfActionDue[0].dueDate;
          const status = this.planOfActionDue.some((ele) => ele.status === 'OVERDUE')
            ? 'OVERDUE'
            : '';
          this.updateActionItemObject(
            'fa fa-file-import',
            title,
            dueDate,
            'Upload Plan of Action',
            this.planOfActionDue.length > 1 ? '/survey' : 'SurveyDetails',
            status,
            'PlanActionDues',
            this.planOfActionDue.length > 1 ? surveyList : this.planOfActionDue[0],
          );
        }
      }
      if (item.OrderAdditionalCertificates.length > 0) {
        const surveyList: any[] = [];
        item.OrderAdditionalCertificates.forEach((ele) => {
          this.orderAdditionalCertificates.push(new OrderAdditionalCertificates(ele));
          surveyList.push(new SurveyList(ele));
        });
        if (this.orderAdditionalCertificates.length > 0) {
          const title =
            this.orderAdditionalCertificates.length > 1
              ? `Order Additional Certificates (${this.orderAdditionalCertificates.length})`
              : 'Order Additional Certificates';
          this.updateActionItemObject(
            'fa fa-shopping-cart',
            title,
            null,
            'Submit Order',
            this.orderAdditionalCertificates.length > 1 ? '/survey' : 'openAdditionalCertiForm',
            '',
            'AdditionalCertiDues',
            this.orderAdditionalCertificates.length > 1
              ? surveyList
              : this.orderAdditionalCertificates[0],
          );
        }
      }
      if (item.PostersAvailable.length > 0) {
        item.PostersAvailable.forEach((ele) => {
          this.posterAvailable.push(new PosterAvailable(ele));
        });
        if (this.posterAvailable.length > 0) {
          this.updateActionItemObject(
            'fa fa-passport',
            'Posters Available',
            null,
            'Print Survey Posters',
            '/resources/postersConsumer',
            '',
            'SurveyPosters',
          );
        }
      }
      if (item.SAMHSAImplementationReportDue) {
        const surveyList: any[] = [];
        item.SAMHSAImplementationReportDue.forEach((data) => {
          this.samhsaImplementationReportDue.push(new SAMHSAImplementationReportDue(data));
          surveyList.push(new SurveyList(data));
        });
        if (this.samhsaImplementationReportDue.length > 0) {
          const title =
            this.samhsaImplementationReportDue.length > 1
              ? `SAMHSA Implementation Report Due (${this.samhsaImplementationReportDue.length})`
              : 'SAMHSA Implementation Report Due';
          const dueDate =
            this.samhsaImplementationReportDue.length > 1
              ? null
              : moment(this.samhsaImplementationReportDue[0].sirDueDate).format('YYYY-MM-DD');
          const status = this.samhsaImplementationReportDue.some((ele) => ele.status === 'OVERDUE')
            ? 'OVERDUE'
            : '';
          this.updateActionItemObject(
            'fa fa-file-import',
            title,
            dueDate,
            'Upload SAMHSA Implementation Report',
            this.samhsaImplementationReportDue.length > 1 ? '/survey' : 'SurveyDetails',
            status,
            'PendingSurveys',
            this.samhsaImplementationReportDue.length > 1
              ? surveyList
              : this.samhsaImplementationReportDue[0],
          );
        }
      }
    }
    if (this.actionItemObject.length === 0) {
      this.updateActionItemObject(
        'fa fa-clipboard-check',
        'No Current Actions Needed',
        null,
        null,
        null,
        '',
        null,
      );
    }
  }

  updateActionItemObject(
    icon,
    title,
    dueDate,
    buttonText,
    buttonResponse,
    status,
    routerPath?,
    actionitemData?: any,
  ) {
    this.actionItemObject.push(new ActionItemObject());
    this.actionItemObject[this.actionItemObject.length - 1].id = this.actionItemObject.length;
    this.actionItemObject[this.actionItemObject.length - 1].icon = icon;
    this.actionItemObject[this.actionItemObject.length - 1].title = title;
    this.actionItemObject[this.actionItemObject.length - 1].dueDate = dueDate;

    this.actionItemObject[this.actionItemObject.length - 1].buttonText = buttonText;
    this.actionItemObject[this.actionItemObject.length - 1].buttonResponse = buttonResponse;
    this.actionItemObject[this.actionItemObject.length - 1].status = status;
    this.actionItemObject[this.actionItemObject.length - 1].routerPath = routerPath;
    this.actionItemObject[this.actionItemObject.length - 1].actionitemData = actionitemData;
  }
}
