import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from './_components/footer/footer.component';
import { HeaderComponent } from './_components/header/header.component';
import { SidenavComponent } from './_components/sidenav/sidenav.component';
import { GridComponent } from './_components/grid/grid.component';
import { SimpleHeaderComponent } from './_components/simple-header/simple-header.component';
import { QuickLinksComponent } from './_components/quick-links/quick-links.component';
import { UpdateProfileFormComponent } from './_components/update-profile-form/update-profile-form.component';
import { ProgressBarComponent } from './_components/progress-bar/progress-bar.component';
import { ButtonBarComponent } from './_components/button-bar/button-bar.component';
import { ComplexHeaderComponent } from './_components/complex-header/complex-header.component';
import { ModalDialogComponent } from './_components/modal-dialog/modal-dialog.component';
import { AddContactFormComponent } from './_components/add-contact-form/add-contact-form.component';
import { DeleteModalComponent } from './_components/delete-modal/delete-modal.component';
import { NavigationTileComponent } from './_components/navigation-tile/navigation-tile.component';
import { StepperFormComponent } from './_components/stepper-form/stepper-form.component';
import { ContactLinkComponent } from './_components/contact-link/contact-link.component';
import { PhoneDirective } from './directives/phone-mask-directive';
import { ErrorComponent } from './_components/error/error.component';
import { LoaderComponent } from './_components/loader/loader.component';
import { LoaderService } from './_services/loader.service';
import { SharedService } from './_services/shared.service';
import { ConfirmDialogComponent } from './_components/confirm-dialog/confirm-dialog.component';
import { ConfirmationDialogService } from './_services/confirm-dialog.service';
import { LoadingModalComponent } from './_components/loading-modal/loading-modal.component';
import { HelpDataComponent } from './_components/help-data/help-data.component';
import { SurveyApplAgreementModalComponent } from './_components/payment/survey-appl-agreement-modal/survey-appl-agreement-modal.component';
import { InvalidApplSubmitterModalComponent } from './_components/payment/invalid-appl-submitter-modal/invalid-appl-submitter-modal.component';
import { FailedValidationModalComponent } from './_components/payment/failed-validation-modal/failed-validation-modal.component';
import { ApplFeePaymentModalComponent } from './_components/payment/appl-fee-payment-modal/appl-fee-payment-modal.component';
import { PaymentSummaryComponent } from './_components/payment/payment-summary/payment-summary.component';
import { ApplicationSubmittalConfirmationComponent } from './_components/payment/application-submittal-confirmation/application-submittal-confirmation.component';
import { SortPipe } from './_pipes/sort.pipe';
import { TermsOfUseComponent } from './_components/terms-of-use/terms-of-use.component';
import { ContactUsComponent } from './_components/contact-us/contact-us.component';
import { ResourceLinkComponent } from './_components/resource-link/resource-link.component';
import { PaymentsService } from './_components/payment/services/payments.service';
import { AttachmentUploadComponent } from './_components/attachment-upload/attachment-upload.component';
import { NoScrollInputDirective } from './directives/input-type-number.directive';
import { ApplFeePaymentInstructionsComponent } from './_components/payment/appl-fee-payment-instructions/appl-fee-payment-instructions.component';
import { AddSaveModalComponent } from './_components/save-modal/save-modal.component';
import { RequiredToSaveModalComponent } from './_components/required-to-save-modal/required-to-save-modal.component';
import { DynamicDialogComponent } from './_components/dynamic-dialog/dynamic-dialog';
import { DynamicDialogService } from './_services/dynamic-dialog.service';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    SidenavComponent,
    GridComponent,
    SimpleHeaderComponent,
    QuickLinksComponent,
    UpdateProfileFormComponent,
    ProgressBarComponent,
    ButtonBarComponent,
    ComplexHeaderComponent,
    ModalDialogComponent,
    AddContactFormComponent,
    AddSaveModalComponent,
    RequiredToSaveModalComponent,
    DeleteModalComponent,
    NavigationTileComponent,
    StepperFormComponent,
    ContactLinkComponent,
    PhoneDirective,
    NoScrollInputDirective,
    ErrorComponent,
    LoaderComponent,
    ConfirmDialogComponent,
    LoadingModalComponent,
    HelpDataComponent,
    SurveyApplAgreementModalComponent,
    InvalidApplSubmitterModalComponent,
    FailedValidationModalComponent,
    ApplFeePaymentModalComponent,
    PaymentSummaryComponent,
    ApplicationSubmittalConfirmationComponent,
    SortPipe,
    TermsOfUseComponent,
    ContactUsComponent,
    ResourceLinkComponent,
    AttachmentUploadComponent,
    ApplFeePaymentInstructionsComponent,
    DynamicDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    TranslateModule,
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    SidenavComponent,
    SimpleHeaderComponent,
    QuickLinksComponent,
    UpdateProfileFormComponent,
    ProgressBarComponent,
    ButtonBarComponent,
    ComplexHeaderComponent,
    ModalDialogComponent,
    AddContactFormComponent,
    AddSaveModalComponent,
    RequiredToSaveModalComponent,
    DeleteModalComponent,
    NavigationTileComponent,
    StepperFormComponent,
    GridComponent,
    ContactLinkComponent,
    LoaderComponent,
    ConfirmDialogComponent,
    LoadingModalComponent,
    TranslateModule,
    PhoneDirective,
    NoScrollInputDirective,
    HelpDataComponent,
    ApplFeePaymentModalComponent,
    SurveyApplAgreementModalComponent,
    InvalidApplSubmitterModalComponent,
    FailedValidationModalComponent,
    SortPipe,
    ResourceLinkComponent,
    AttachmentUploadComponent,
    DynamicDialogComponent,
  ],
  providers: [
    LoaderService,
    SharedService,
    ConfirmationDialogService,
    SortPipe,
    PaymentsService,
    DynamicDialogService,
  ],
})
export class SharedModule {}
