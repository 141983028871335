<div class="unsave-form-container">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ dialogInfo?.title }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="dismiss()"
      >
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>
    <div class="modal-body d-flex">
      <div class="pr-2">
        <span class="fa fa-exclamation-triangle warning" aria-hidden="true"></span>
      </div>
      <div class="p-txt" [innerHTML]="dialogInfo?.message"></div>
    </div>
    <div class="modal-footer">
      <div class="form-footer d-flex justify-content-between w-100">
        <div>
          <button class="btn mr-3" type="button" (click)="decline()">
            <i class="fa mr-2 plus" [ngClass]="dialogInfo?.btnOkIcon" aria-hidden="true"></i>
            {{ dialogInfo?.btnOkText }}
          </button>
          <button
            class="btn mr-3"
            aria-label="Close"
            (click)="accept()"
            *ngIf="dialogInfo?.btnCancelText"
          >
            <i class="fa mr-2 plus" [ngClass]="dialogInfo?.btnCancelIcon" aria-hidden="true"></i>
            {{ dialogInfo.btnCancelText }}
          </button>
        </div>
        <button
          class="btn"
          data-dismiss="modal"
          aria-label="Close"
          (click)="dismiss()"
          *ngIf="dialogInfo?.hideCancelBtn"
        >
          <i class="fa fa-times-circle plus mr-2" aria-hidden="true"></i> Cancel
        </button>
      </div>
    </div>
  </div>
</div>
