/* eslint-disable @typescript-eslint/no-use-before-define */
// eslint-disable-next-line max-classes-per-file
export class OnGoingAdminItems {
  ACQRId: string;
  OCAdminItems: OCAdminItems;
  OCSignificantItems: OCSignificantItems;
  OCSignature: OCSignature;
  constructor(data?: any) {
    if (data) {
      this.ACQRId = data.AIMS_ACQR_Id;
      this.OCAdminItems = new OCAdminItems(data.administrativeItems);
      this.OCSignificantItems = new OCSignificantItems(data.significantEvents);
      this.OCSignature = new OCSignature(data.signature);
    }
  }
}
export class OngoingItems {
  ACQRId: string;
  OCItems: OCItems;
  OCSignature: OCSignature;

  constructor(data?: any) {
    if (data) {
      this.ACQRId = data.AIMS_ACQR_Id;
      this.OCItems = new OCItems(data.ongoingItems);
      this.OCSignature = new OCSignature(data.signature);
    }
  }
}
export class OCAdminItems {
  AdminleaderShip: string;
  AdminLeaderShipType: string;
  AdminOwnerShip: string;
  AdminOwnerShipType: string;
  AdminOrganizationName: string;
  AdminOrganizationType: string;
  AdminMailingAddress: string;
  AdminMailAddressType: string;
  AdminPersonnel: string;
  AdminPersonnelType: string;
  AdminReduction: string;
  AdminReductionType: string;
  AdminFianancialDistress: string;
  AdminFianancialDistressType: string;
  AdminConsolidation: string;
  AdminConsolidationType: string;
  constructor(data?: any) {
    if (data) {
      this.AdminleaderShip = data.AIMS_Change_in_Leadership;
      this.AdminLeaderShipType = data.AIMS_Change_In_Leadership_Type;
      this.AdminOwnerShip = data.AIMS_Change_in_Ownership;
      this.AdminOwnerShipType = data.AIMS_Change_In_Ownership_Type;
      this.AdminOrganizationName = data.AIMS_Org_Name_Change;
      this.AdminOrganizationType = data.AIMS_Org_Name_Change_Type;
      this.AdminMailingAddress = data.AIMS_Change_In_Mailing_Addr;
      this.AdminMailAddressType = data.AIMS_Change_In_Mail_Addr_Type;
      this.AdminPersonnel = data.AIMS_Reorg_of_Personnel;
      this.AdminPersonnelType = data.AIMS_Reorg_of_Personnel_Type;
      this.AdminReduction = data.AIMS_Expansion_or_Reduction;
      this.AdminReductionType = data.AIMS_Expansion_Reduction_Type;
      this.AdminFianancialDistress = data.AIMS_Severe_Financial_Distress;
      this.AdminFianancialDistressType = data.AIMS_Severe_Fin_Distress_Type;
      this.AdminConsolidation = data.AIMS_Merger_or_Consolidation;
      this.AdminConsolidationType = data.AIMS_Merger_Consolidation_Type;
    }
  }
}
export class OCSignificantItems {
  Investigation: string;
  InvestigationType: string;
  MaterialLitigation: string;
  MaterialLitigationType: string;
  Catastrophe: string;
  CatastropheType: string;
  SentinelEvent: string;
  SentinelEventType: string;
  CmsSanction: string;
  CmsSanctionType: string;

  constructor(data?: any) {
    if (data) {
      this.Investigation = data.AIMS_Investigation;
      this.InvestigationType = data.AIMS_Investigation_Type;
      this.MaterialLitigation = data.AIMS_Material_Litigation;
      this.MaterialLitigationType = data.AIMS_Material_Litigation_Type;
      this.Catastrophe = data.AIMS_Major_Catastrophe;
      this.CatastropheType = data.AIMS_Major_Catastrophe_Type;
      this.SentinelEvent = data.AIMS_Sentinel_Event;
      this.SentinelEventType = data.AIMS_Sentinel_Event_Type;
      this.CmsSanction = data.AIMS_CMS_Sanction;
      this.CmsSanctionType = data.AIMS_CMS_Sanction_Type;
    }
  }
}
export class OCItems {
  Leadership: string;
  LeadershipType: string;
  OwnerShip: string;
  OwnerShipType: string;
  OrgName: string;
  OrgNameType: string;
  MailingAddress: string;
  MailingAddressType: string;
  Relocation: string;
  RelocationType: string;
  LocChange: string;
  LocChangeType: string;
  EliminateServices: string;
  EliminateServicesType: string;
  LicensureChange: string;
  LicensureChangeType: string;

  constructor(data?: any) {
    if (data) {
      this.Leadership = data.AIMS_Change_in_Leadership;
      this.LeadershipType = data.AIMS_Change_In_Leadership_Type;
      this.OwnerShip = data.AIMS_Change_in_Ownership;
      this.OwnerShipType = data.AIMS_Change_In_Ownership_Type;
      this.OrgName = data.AIMS_Org_Name_Change;
      this.OrgNameType = data.AIMS_Org_Name_Change_Type;
      this.MailingAddress = data.AIMS_Change_In_Mailing_Addr;
      this.MailingAddressType = data.AIMS_Change_In_Mail_Addr_Type;
      this.Relocation = data.AIMS_Cert_Relocation;
      this.RelocationType = data.AIMS_Cert_Relocation_Type;
      this.LocChange = data.AIMS_Cert_LOC_Change;
      this.LocChangeType = data.AIMS_Cert_LOC_ChangeType;
      this.EliminateServices = data.AIMS_Cert_EliminateServices;
      this.EliminateServicesType = data.AIMS_Cert_EliminateServiceType;
      this.LicensureChange = data.AIMS_Cert_LicensureChange;
      this.LicensureChangeType = data.AIMS_Cert_LicensureChangeType;
    }
  }
}
export class OCSignature {
  CompanyName: string;
  SubmittedBy: string;
  FullName: string;

  constructor(data?) {
    if (data) {
      this.CompanyName = data.Company_Name;
      this.SubmittedBy = data.AIMS_ACQR_AR_SubmittedBy;
      this.FullName = data.Full_Name;
    }
  }
}
