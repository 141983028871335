<div class="upload-form-container ngb-modal-container">
  <div class="modal-content">
    <div class="modal-header" id="AttachmentModal">
      <h1 *ngIf="!isApplicationItems" class="modal-title">Upload Ongoing Communication Form</h1>
      <h1 *ngIf="isApplicationItems" class="modal-title">Upload Application Item</h1>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="onModelClose()"
        [disabled]="showOrHideLoader"
      >
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>
    <div class="modal-body">
      <ng-container *ngIf="showOrHideLoader">
        <app-loader></app-loader>
      </ng-container>
      <form
        [formGroup]="editUploadForm"
        (ngSubmit)="onSubmit()"
        role="form"
        aria-label="uploadAttachment"
        class="upload-form"
        *ngIf="!showOrHideLoader"
      >
        <div>
          <div class="row" *ngIf="isApplicationItems">
            <div class="col-md-12">
              <div class="form-group">
                <label for="item">Item</label>
                <input
                  class="form-control form-control-lg"
                  type="text"
                  id="item"
                  formControlName="item"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="row pl-3 pr-3" *ngIf="!isApplicationItems">
            <div class="form-group search-options-wrapper">
              <label for="companyDetails"
                >Which company is this Ongoing Communication Form for?</label
              >
              <select
                id="companyDetails"
                class="form-control ocf-select-box"
                aria-label="CompanyDetails"
                formControlName="CompanyList"
                (change)="changeCompanyData()"
                [attr.disabled]="disableDropdown ? 'disabled' : null"
                [ngClass]="{
                  'is-invalid': submitted && editUploadForm.controls.CompanyList.errors
                }"
              >
                <option class="option" value="" selected disabled>- Select -</option>
                <option class="option" *ngFor="let list of dropdownCompList" [ngValue]="list">
                  {{ list.companyName }}
                </option>
              </select>
            </div>
          </div>
          <div class="row" *ngIf="isApplicationItems">
            <div class="col-md-6">
              <div class="form-check custom-checkbox ml-2 mt-3 mb-4">
                <input
                  class="form-check-input form-check-input-lg custom-control-input"
                  formControlName="required"
                  id="requiredField"
                  type="checkbox"
                />
                <label class="form-check-label custom-control-label" for="requiredField">
                  Required
                </label>
              </div>
            </div>
          </div>
          <div *ngIf="showAttachmentDetails">
            <div class="form-row form-group pl-2" *ngIf="!isApplicationItems">
              <p>
                Upload the Ongoing Communication Form and any supporting documentation (if
                required).
              </p>
            </div>
            <div
              *ngIf="showHideButton && isFormEditable"
              class="p-2 mb-4 justify-content-end d-flex col-lg-12 btn-cls"
            >
              <button
                type="button"
                class="file-upload-button btn mr-2 complexHeader upload-button"
                aria-hidden="true"
                tabindex="-1"
              >
                <span
                  class="fa fa-file-upload file-icon mr-2"
                  (click)="fileInput.click()"
                  aria-hidden="true"
                ></span>
                <input
                  type="file"
                  title=" "
                  #fileInput
                  id="fileSelect"
                  aria-label="uploadFile"
                  tabindex="-1"
                  name="fileSelect"
                  (change)="onFileSelect(fileInput.files)"
                />
                <span class="span-text" for="fileSelect">Upload File</span>
              </button>
            </div>
            <div class="row table-padding">
              <div class="col-sm-12 col-lg-12">
                <p id="attachmentCaption" class="tableHeader col-12">Attachments</p>
                <form (ngSubmit)="onSubmit()">
                  <div class="table-responsive">
                    <table class="table attatchments-tbl" aria-describedby="attachmentCaption">
                      <thead>
                        <tr>
                          <th scope="col" *ngFor="let column of tableDataColumns">
                            {{ column | translate }}
                          </th>
                          <th scope="col" *ngIf="showHideButton && isFormEditable">Actions</th>
                        </tr>
                      </thead>
                      <tbody
                        class="tableBody"
                        [ngClass]="isApplicationItems ? 'pointer-class' : 'nopointer-class'"
                      >
                        <tr
                          *ngFor="
                            let group of isApplicationItems
                              ? attachmentItems?.attachmentList
                              : ocfTableData;
                            let i = index
                          "
                          (click)="onRowClick(group)"
                        >
                          <td>
                            {{ group.documentURL }}
                          </td>
                          <td>
                            {{ group.attAddedDt }}
                          </td>
                          <td>
                            {{ group.attType }}
                          </td>
                          <td>
                            {{ group.attSize }}
                          </td>
                          <td *ngIf="showHideButton && isFormEditable">
                            <div class="d-flex action-btns">
                              <button
                                type="button"
                                class="btn btn-danger mr-2"
                                (click)="deleteRow(group, $event, i)"
                              >
                                <span class="fa fa-trash-alt mr-2"> </span
                                >{{ 'COMMON.DELETE' | translate }}
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row form-errors">
          <div class="col-md-12 error-msg" *ngIf="errorMsg.duplicateFile">
            Duplicate file name found. Each attachment must have a unique file name.
          </div>
          <div class="col-md-12 error-msg" *ngIf="errorMsg.emptyFile">
            File must not be empty.
          </div>
          <div class="col-md-12 error-msg" *ngIf="errorMsg.exceedFileLimit">
            File must not exceed 10 Megabytes in size.
          </div>
          <div class="col-md-12 error-msg" *ngIf="errorMsg.invalidFileName">
            Filename must not contain # or ' or multiple periods.
          </div>
          <div class="col-md-12 error-msg" *ngIf="errorMsg.invalidFileNameLength">
            Filename including extension must be shorter than 65 characters.
          </div>
          <div class="col-md-12 error-msg" *ngIf="errorMsg.invalidFileType">
            Invalid file type.
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div class="form-footer">
        <button
          *ngIf="isFormEditable && !isApplicationItems"
          class="btn btn-info mr-3"
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          (click)="onSubmit()"
          [disabled]="!isDirty"
        >
          <i class="fa fa-save plus mr-2" aria-hidden="true"></i>
          {{ 'COMMON.SAVE' | translate }}
        </button>
        <button
          *ngIf="!isApplicationItems"
          type="button"
          class="btn btn-info"
          data-dismiss="modal"
          aria-label="Close"
          (click)="onModelClose()"
          [disabled]="showOrHideLoader"
          #vcClose
        >
          <i class="fa fa-times-circle plus mr-2" aria-hidden="true"></i>
          {{ 'COMMON.CANCEL' | translate }}
        </button>
        <button
          *ngIf="isApplicationItems"
          type="button"
          class="btn btn-info"
          data-dismiss="modal"
          aria-label="Close"
          (click)="onModelClose()"
          [disabled]="showOrHideLoader"
          #vcClose
        >
          <i class="fa fa-times-circle plus mr-2" aria-hidden="true"></i>
          {{ 'COMMON.CLOSE' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
