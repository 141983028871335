import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

export class Utils {
  static dateToStringData(date?: any) {
    if (date) {
      return `${date.month}/${date.day}/${date.year}`;
    }
    return null;
  }
  static stringToDate(value?: any) {
    if (value) {
      const date = moment(value).get('date');
      const month = moment(value).get('month') + 1;
      const year = moment(value).get('year');
      return new NgbDate(year, month, date);
    }
    return null;
  }
  static formatLongDate(value?: any) {
    if (value) {
      return moment(value).format('LL');
    }
    return null;
  }
  static convertToDate(value?: any) {
    if (value) {
      const updatedDate = this.dateToStringData(value);
      return moment.utc(updatedDate).format().substring(0, 19);
    }
    return null;
  }
  static addDays(value: any, days: number) {
    const date = moment(value).format('LL');
    return moment(date).add(days, 'days');
  }
  static updateStepIcon(length: number, status: string) {
    if (status === null) {
      return 'fa-thin-cirlce';
    }
    return length > 0 ? 'fa-error-cirlce' : 'fa-solid-cirlce';
  }
  static updateStepInsideIcon(length: number, status: string) {
    if (status === null) {
      return '';
    }
    return length > 0 ? 'fa fa-exclamation-triangle' : 'fa fa-check';
  }
  static testEmailAddress(emailToTest) {
    // check for @ atSymbol
    const atSymbol = emailToTest?.match(/@/gm);
    const dottest = emailToTest?.indexOf('@') + 1;
    const index1 = emailToTest?.substring(dottest);
    const domainNamePattern = /^[a-zA-Z0-9.-]*$/;
    const profileIDPattern = /^[a-zA-Z0-9-@_.!#\\$%&+=*?^`'{}|~]*$/;
    const index2 = index1?.indexOf('.');
    const dotSubstring = index1?.substring(index2);

    if (
      emailToTest?.indexOf('@-') > 0 ||
      emailToTest?.indexOf('.@') > 0 ||
      emailToTest?.indexOf('@.') > 0 ||
      emailToTest?.indexOf('--') > 0 ||
      emailToTest?.indexOf('.-') > 0 ||
      emailToTest?.indexOf('-.') > 0 ||
      emailToTest?.includes('..') ||
      atSymbol === null ||
      atSymbol?.length !== 1 ||
      !domainNamePattern?.test(index1) ||
      !profileIDPattern?.test(emailToTest) ||
      emailToTest?.startsWith('.') ||
      emailToTest?.endsWith('.') ||
      emailToTest?.endsWith('@') ||
      emailToTest?.indexOf('@-')?.length === emailToTest?.length - 1 ||
      emailToTest?.indexOf('.@')?.length === emailToTest?.length - 1 ||
      emailToTest?.indexOf('..')?.length === emailToTest?.length - 1 ||
      dotSubstring?.length === 1
    ) {
      return true;
    }
    if (index2 > 1) {
      return false;
    }
    if (index2 === -1 && dotSubstring?.length > 1) {
      return false;
    }
    return false;
  }
}
