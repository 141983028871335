/* eslint-disable max-classes-per-file */
export class LineItemInfoData {
  ItemId: string;
  Name: string;
  Description: string;
  Quantity: number;
  UnitPrice: number;
  Taxable: boolean;

  constructor(data?: any) {
    this.ItemId = data?.ItemId;
    this.Name = data?.Name;
    this.Description = data?.Description;
    this.Quantity = data?.Quantity;
    this.UnitPrice = data?.UnitPrice;
    this.Taxable = data?.Taxable;
  }
}

export class AdditionalCertificatePayment {
  SurveyId: string;
  CompSubmittingIntentId: string;
  SurveyType: string;
  SurveyNumber: string;
  InvoiceNumber: string;
  OrderDescription: string;
  BalanceDue: number;
  BaseAmout: number;
  TaxAmount: number;
  TotalAmount: number;
  LineItems: LineItemInfoData[] = [];
  DivisionCode: string;
  AsDept: string;
  Company_Country: string;
  Currency: string;
  TaxRate: number;
  StateTax: number;
  OfficeName: string;
  CompanyNumber: string;
  TaxAddress1: string;
  TaxCity: string;
  TaxState: string;
  TaxCountry: string;
  TaxZip: string;

  constructor(res?: any) {
    if (res) {
      this.SurveyId = res?.Survey_Id;
      this.CompSubmittingIntentId = res?.AIMS_Comp_Submttng_Intent_Id;
      this.SurveyType = res?.AIMS_Survey_Type;
      this.SurveyNumber = res?.AIMS_Survey_Number;
      this.OrderDescription = res?.OrderDescription;
      this.BaseAmout = res?.BaseAmount;
      this.TaxAmount = res?.TaxAmount;
      this.TotalAmount = res?.TotalAmount;
      this.DivisionCode = res?.Division_Code;
      this.AsDept = res?.AS_Dept;
      this.Company_Country = res?.Company_Country;
      this.Currency = res?.Currency;
      this.TaxRate = res?.Tax_Rate;
      this.StateTax = res?.State_Tax;
      this.OfficeName = res?.Office_Name;
      this.CompanyNumber = res?.Company_Number;
      this.TaxAddress1 = res?.Tax_Address_1;
      this.TaxCity = res?.Tax_City;
      this.TaxState = res?.Tax_State;
      this.TaxCountry = res?.Tax_Country;
      this.TaxZip = res?.Tax_Zip;
      this.LineItems = res?.LineItemInfo?.map((item) => new LineItemInfoData(item));
    }
  }
}

export class CertificateDetails {
  AIMS_Company: string;
  AIMS_Free: boolean;
  AIMS_Size: string;
  AIMS_Quantity: number;

  constructor(data?: any) {
    this.AIMS_Company = data?.CompanyId;
    this.AIMS_Size = data?.Size;
    this.AIMS_Quantity = +data?.Quantity;
    this.AIMS_Free = data?.Taxable;
  }
}

export class PostAdditionalCertificatePayment {
  AIMS_Survey_Id: string;
  AIMS_OrderedBy: string;
  AIMS_Number_Certs_Ordered: number;
  AIMS_OrderTotal: number;
  AIMS_SalesTax: number;
  AIMS_Total: number;
  certDetails: CertificateDetails[] = [];

  constructor(res?: any) {
    if (res) {
      this.AIMS_Survey_Id = res?.SurveyId;
      this.AIMS_OrderedBy = res?.ContactId;
      this.AIMS_Number_Certs_Ordered = res?.noOfOrders;
      this.AIMS_OrderTotal = res?.BaseAmout;
      this.AIMS_SalesTax = res?.TaxAmount;
      this.AIMS_Total = res?.TotalAmount;
      this.certDetails = res.LineItems?.map((item) => new CertificateDetails(item));
    }
  }
}
