// eslint-disable-next-line max-classes-per-file
export class AgreementandSignatureTermsConditions {
  SectionNumber: number;
  SectionTitle: string;
  TermsandConditions: string;

  constructor(data?: any) {
    this.SectionNumber = data?.AIMS_Section_Number;
    this.SectionTitle = data?.AIMS_Section_Title;
    this.TermsandConditions = data?.AIMS_Terms_And_Conditions;
  }
}

export class TermsandConditionsAgreement {
  IntroductoryText: string;
  tableDataList: AgreementandSignatureTermsConditions[] = [];

  constructor(res?: any) {
    if (res) {
      this.IntroductoryText = res?.AIMS_Introductory_Text;
      res.TermsandConditions.forEach((element) =>
        this.tableDataList.push(new AgreementandSignatureTermsConditions(element)),
      );
    }
  }
}
