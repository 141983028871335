import { Component, Input, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentsService } from '../services/payments.service';

@Component({
  selector: 'app-appl-fee-payment-instructions',
  templateUrl: './appl-fee-payment-instructions.component.html',
  styleUrls: ['./appl-fee-payment-instructions.component.scss'],
})
export class ApplFeePaymentInstructionsComponent implements OnInit, OnInit {
  @Input() selectedPaymentOption: any;
  @Input() paymentModelData: any;
  mailingAddress: string;
  officeName: any;
  @Output() printAction: EventEmitter<any> = new EventEmitter<any>();

  constructor(private modalService: NgbModal, public paymentsService: PaymentsService) {}

  ngOnInit(): void {
    this.officeName = this.paymentModelData?.Office_Name;
    if (this.officeName === 'CARF International Headquarters') {
      this.mailingAddress = 'CARF PO Box 674401, Dallas, TX 75267-4401.';
    } else if (this.officeName === 'CARF Canada') {
      this.mailingAddress = 'CARF Canada c/o C07402C PO Box 45 Station M Calgary, Alberta T2P 2G9.';
    } else {
      this.mailingAddress = 'CARF 6951 E. Southpoint Road, Tucson, AZ 85756.';
    }
  }

  modalClose() {
    this.modalService.dismissAll();
  }
  onPrint() {
    this.printAction.emit({
      selectedPaymentOption: this.selectedPaymentOption,
      paymentModelData: this.paymentModelData,
      mailingAddress: this.mailingAddress,
    });
    this.modalClose();
  }
}
