import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserDetails } from '../_models/User';
import { UserService } from '../_services/user.service';

@Injectable()
export class Resolver implements Resolve<any> {
  private baseUrl = '';
  userDetails: UserDetails;
  constructor(private http: HttpClient, private userService: UserService) {
    this.baseUrl = environment.apiUrl;
  }

  resolve(): Observable<UserDetails> {
    if (this.userDetails) {
      return of(this.userDetails);
    }
    const operationName = '/user';
    const encodeURL = this.baseUrl + operationName;
    return this.http.get<any>(encodeURL).pipe(
      map((res: any) => {
        this.userDetails = new UserDetails(res);
        this.userService.updateData(this.userDetails);
        return this.userDetails;
      }),
      catchError((err) => Observable.throw(err.json().error)),
    );
  }
}
