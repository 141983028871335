<div [ngClass]="!data.buttonText ? 'noaction-class' : (data.status === 'OVERDUE' ? 'overdue-class' : 'due-class')" class="" (click)="!data.buttonText || tileNavigation(data)">
  <div class="tile">
    <div class="row tile-body">
      <div class="d-flex w-100">
        <i class="{{ data.icon }}" [ngClass]="data.status === 'OVERDUE' ? 'due-icon' : 'def-icon'"  aria-hidden="true"></i>
        <div class="card-text">
          <div class="title" [ngClass]="data.status === 'OVERDUE' ? 'overdue-title' : 'due-title'">
            {{ data.title }}
          </div>
          <div class="status" [ngClass]="data.status === 'OVERDUE' ? 'overdue-status' : 'due-status'">
            {{ data.status }}
            {{ finalDueDate }}
          </div>
        </div>
      </div>
      <div class="col-md-12 col-xl-12">
        <button *ngIf="data.buttonText" title="{{ data.buttonText }}" class="btn btn-light btn-lg action-btns">
          {{ data.buttonText }}
        </button>
      </div>
    </div>
  </div>
</div>
<div class="printPageData" *ngIf="printFlag" id="section">
  <div class="modal-header">
    <h1 aria-label="payment instruction" class="modal-title">Payment Instructions</h1>
  </div>
  <div class="modal-body" id="sectionToPrint">
    <div>
      <div>
        <p>
          Application Number: <span>{{ response?.paymentModelData?.ApplicationNumber }}</span>
        </p>
      </div>
      <div>
        <p>
          Application Fee: <span
            >{{ response?.paymentModelData?.BaseAmount | currency }}
            {{ response?.paymentModelData?.Company_Country === 'CANADA' ? 'CAD' : 'USD' }}</span
          >
        </p>
      </div>
      <div *ngIf="response?.paymentModelData?.TaxAmount > 0">
        <p>
          Applicable Tax: <span>{{ response?.paymentModelData?.TaxAmount | currency }}</span>
        </p>
      </div>
      <div>
        <p>
          Total Invoice Amount: <span
            >{{ response?.paymentModelData?.TotalAmount | currency}}
            {{ response?.paymentModelData?.Company_Country === 'CANADA' ? 'CAD' : 'USD' }}.</span
          ><span *ngIf="response?.paymentModelData?.Office_Name === 'CARF Europe'"
            >CARF will contact you if taxes apply.</span
          >
        </p>
      </div>
      <div *ngIf="response?.selectedPaymentOption === 'Wire Transfer'">
        <p>This does not include applicable wire transfer fees.</p>
        <p>
          If you have not yet initiated the wire transfer, email your request for CARF’s wire
          information to
          <a class="instruction-links" href="mailto:bookstore@carf.org" aria-label="abookstore link"
            >bookstore@carf.org</a
          >. Include your organization’s name and country in this request.
        </p>
      </div>
      <div *ngIf="response?.selectedPaymentOption === 'Check'">
        <p>Send a copy of this confirmation with your check to {{ response?.mailingAddress }}</p>
      </div>
      <div *ngIf="response?.selectedPaymentOption === 'Electronic Payment'">
        <p>
          If you have not yet initiated the electronic payment, email your request for CARF’s
          electronic payment information to
          <a
            class="instruction-links"
            href="mailto:Finance_Europe@carf.org"
            aria-label="abookstore link"
            >Finance_Europe@carf.org.</a
          >
          Include your organization’s name and country in this request.
        </p>
      </div>
    </div>
  </div>
</div>