import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { UserDetails } from 'src/app/core/_models/User';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../_services/dashboard.service';
import { ActionItems } from '../models/ActionItems';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  dataReceived: any;
  homeCardData: any;
  eventCardData: any;
  storeCardData: any;
  carfStandards: any;
  userInfo: UserDetails;
  quickLinksData: boolean = false;
  showOrHideLoader = true;

  constructor(
    private nameData: DashboardService,
    private route: ActivatedRoute,
    private titleService: Title,
    private router: Router,
  ) {
    this.titleService.setTitle('Customer Connect – Home');
  }

  ngOnInit(): void {
    this.userInfo = this.route.snapshot.data.userDetail;
    this.quickLinksData = this.userInfo.AccessToPublications;
    this.onClick();
    this.setQuickLinksData();
  }

  onClick() {
    this.subscriptions.push(
      this.nameData.getActionTileData(this.userInfo.ContactId).subscribe((res: any) => {
        this.showOrHideLoader = false;
        this.dataReceived = new ActionItems(res).actionItemObject;
      }),
    );
  }

  // The news.component.ts file also contains a copy of the quick link cards. These cards
  // only display when the scaled versions of the cards are required. When changes to the cards
  // are needed, make the changes here and in that file.
  setQuickLinksData() {
    this.homeCardData = {
      imageUrl: '../assets/images/CC_QL_OngoingCommunicationForms.jpg',
      imageAltTag: 'Ongoing Communication Forms',
      cardCaption: 'Report changes or significant events?',
      cardLink: 'DOWNLOAD AN ONGOING COMMUNICATION FORM',
      url: '/resources/ocf',
      labelledby: 'ongoingComm',
    };
    this.storeCardData = {
      imageUrl: '../assets/images/CC_QL_CARFStandards.jpg',
      imageAltTag: 'CARF Standards',
      cardCaption: 'Keep current with CARF Standards',
      cardLink: 'GO TO CARF BOOKSTORE',
      url: 'http://bookstore.carf.org/miva/merchant.mvc?Screen=SFNT&Store_Code=CB',
      labelledby: 'bookStore',
    };
    this.eventCardData = {
      imageUrl: '../assets/images/CC_QL_EducationalOpportunities.jpg',
      imageAltTag: 'Educational Opportunities',
      cardCaption: 'CARF Educational Opportunities',
      cardLink: 'GO TO OPPORTUNITIES',
      url: '/resources/educationalOpportunities',
      labelledby: 'events',
    };
    this.carfStandards = {
      imageUrl: '../assets/images/CC_QL_StandardsManuals_Workbooks.jpg',
      imageAltTag: 'enter button',
      cardCaption: 'Standards Manuals and Workbooks',
      cardLink: 'GET STANDARDS',
      url: '/resources/stdManualNWorkbooks',
      labelledby: 'manual',
    };
  }

  navigateCard(cardData) {
    if (
      cardData.cardLink === 'GET STANDARDS' ||
      cardData.cardLink === 'DOWNLOAD AN ONGOING COMMUNICATION FORM'
    ) {
      this.router.navigate([cardData.url]);
    } else {
      window.open(cardData.url, '_blank');
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
