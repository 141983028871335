import * as moment from 'moment';
import { Utils } from 'src/app/shared/_models/common/Date-Formatter';
import { ButtonList } from 'src/app/shared/_models/ComplexHeader';
import { ContactLink } from 'src/app/shared/_models/ContactLink';

export class SurveyDetails {
  SurveyId: string;
  SurveyNumber: string;
  CompanyName: string;
  Status: string;
  SurveyType: string;
  AccredDecision: string;
  AccredExpirationDate: string;
  RolloverExpirationDate: string;
  TempExtensionDate: string;
  CompNotifdOfOutcomeDt: string;
  IsUnannouncedSurvey: boolean;
  SurveyStartDate: string;
  SurveyEndDate: string;
  SurevyInfoLetterSentDt: string;
  SurveyFeeFullyPaidDate: string;
  ApprovalToProceed: boolean;
  SurveyFeeDueDate: string;
  QIPDueDate: string;
  QIPReceivedDate: string;
  QIPNeedsMoreDueDt: string;
  QIPNeedsMoreReceivedDate: string;
  Photo: string;
  IsPreliminary: boolean;
  FirstName: string;
  LastName: string;
  JobTitle: string;
  WorkEmail: string;
  AnniversaryDate: string;
  IsFirstAnniversary: boolean;
  StandardsManualName: string;
  ContactInfo: ContactLink;
  IsEditor: boolean;
  IsSubmitter: boolean;
  ButtonsData: Array<ButtonList> = [];
  ExpirationDates: Array<Date> = [];
  SIRDueDate: string;
  SIRNotRequired: string;
  SIRRecvdDate: string;
  SIRVerified: string;
  StandardsManualAbbr: string;
  CompanyCountry: string;
  AccredDecisionPositive: any;

  constructor(data?: any) {
    if (data) {
      this.SurveyId = data.Survey_Id;
      this.SurveyNumber = data.AIMS_Survey_Number;
      this.CompanyName = data.Company_Name;
      this.Status = data.AIMS_Survey_Status;
      this.SurveyType = data.AIMS_Survey_Type?.toLowerCase();
      this.AccredDecision = data.AIMS_Accred_Decision;
      this.AccredDecisionPositive = data.AIMS_Positive_Accred_Decision;
      this.AccredExpirationDate = Utils.formatLongDate(data.AIMS_Accred_Expiration_Dt);
      this.RolloverExpirationDate = Utils.formatLongDate(data.AIMS_Rollover_Expiration_Date);
      this.TempExtensionDate = Utils.formatLongDate(data.AIMS_Temp_Ext_Expiration_Dat);
      this.CompNotifdOfOutcomeDt = Utils.formatLongDate(data.AIMS_Comp_Notifd_Of_Outcome_Dt);
      this.IsUnannouncedSurvey = data.AIMS_Unannounced_Survey;
      this.SurveyStartDate = Utils.formatLongDate(data.AIMS_Survey_Start_Dt);
      this.SurveyEndDate = Utils.formatLongDate(data.AIMS_Survey_End_Dt);
      this.SurevyInfoLetterSentDt = Utils.formatLongDate(data.AIMS_Survey_Info_Lettr_Sent_Dt);
      this.SurveyFeeFullyPaidDate = Utils.formatLongDate(data.AIMS_Dt_Survey_Fee_Fully_Pd);
      this.ApprovalToProceed = data.AIMS_Survey_Apprvl_To_Procd;
      this.SurveyFeeDueDate = Utils.formatLongDate(data.AIMS_Dt_Survey_Fee_Due);
      this.QIPDueDate = Utils.formatLongDate(data.AIMS_Dt_QIP_Due);
      this.QIPReceivedDate = Utils.formatLongDate(data.AIMS_QIP_Recvd_Dt);
      this.QIPNeedsMoreDueDt = Utils.formatLongDate(data.AIMS_QIP_Needs_More_Due_Dt);
      this.QIPNeedsMoreReceivedDate = Utils.formatLongDate(data.AIMS_QIP_Needs_More_Recvd_Dt);
      this.Photo = data.Photo;
      this.IsPreliminary = data.AIMS_Preliminary;
      this.FirstName = data.First_Name;
      this.LastName = data.Last_Name;
      this.JobTitle = data.Job_Title;
      this.WorkEmail = data.Work_Email;
      this.AnniversaryDate = Utils.formatLongDate(data.AIMS_ACQR_1st_Anniversary);
      this.IsFirstAnniversary = !!data.AIMS_ACQR_1st_Anniversary;
      this.StandardsManualName = data.AIMS_Standards_Manual_Name;
      this.IsEditor = data.AIMS_CC_Editor;
      this.IsSubmitter = data.AIMS_CC_Submitter;
      this.SIRDueDate = Utils.formatLongDate(data.AIMS_SIR_Due_Dt);
      this.SIRNotRequired = data.AIMS_SIR_Not_Required;
      this.SIRRecvdDate = Utils.formatLongDate(data.AIMS_SIR_Recvd_Dt);
      this.SIRVerified = data.AIMS_SIR_Verified;
      this.StandardsManualAbbr = data.AIMS_Standards_Manual_Abbr;
      this.ContactInfo = new ContactLink();
      this.ContactInfo.FirstName = this.FirstName;
      this.ContactInfo.LastName = this.LastName;
      this.ContactInfo.JobTitle = this.SurveyType.includes('certifi') ? null : this.JobTitle;
      this.ContactInfo.Photo = this.Photo;
      this.ContactInfo.WorkEmail = this.WorkEmail;
      this.CompanyCountry = data.Company_Country;

      // Set button bar here
      if (
        !this.SurveyType.includes('certifi') &&
        this.SurevyInfoLetterSentDt &&
        new Date(this.SurevyInfoLetterSentDt) <= new Date() &&
        !this.SurveyFeeFullyPaidDate &&
        !this.ApprovalToProceed
      ) {
        const obj = new ButtonList();
        obj.icon = 'fas fa-money-check-alt';
        obj.text = 'SURVEY.COMPLEXHEADER.PAYSURVEYFEEBUTTON';
        this.ButtonsData.push(obj);
      }
      if (
        this.SurveyType.includes('certifi') &&
        this.SurveyFeeDueDate &&
        !this.SurveyFeeFullyPaidDate &&
        !this.ApprovalToProceed
      ) {
        const obj = new ButtonList();
        obj.icon = 'fas fa-money-check-alt';
        obj.text = 'SURVEY.COMPLEXHEADER.PAYCERTIFICATION';
        this.ButtonsData.push(obj);
      }
      if (
        !this.SurveyType.includes('certifi') &&
        this.CompNotifdOfOutcomeDt &&
        new Date(this.CompNotifdOfOutcomeDt) <= new Date() &&
        ((this.QIPDueDate && !this.QIPReceivedDate) ||
          (this.QIPNeedsMoreDueDt && !this.QIPNeedsMoreReceivedDate))
      ) {
        const obj = new ButtonList();
        obj.icon = 'fa fa-file-upload';
        obj.text = 'SURVEY.COMPLEXHEADER.UPLOADBUTTON';
        obj.type = 'upload';
        this.ButtonsData.push(obj);
      }
      if (
        this.SurveyType.includes('certifi') &&
        this.CompNotifdOfOutcomeDt &&
        new Date(this.CompNotifdOfOutcomeDt) <= new Date() &&
        ((this.QIPDueDate && !this.QIPReceivedDate) ||
          (this.QIPNeedsMoreDueDt && !this.QIPNeedsMoreReceivedDate))
      ) {
        const obj = new ButtonList();
        obj.icon = 'fa fa-file-upload';
        obj.text = 'SURVEY.COMPLEXHEADER.UPLOADPLANOFACTION';
        obj.type = 'upload';
        this.ButtonsData.push(obj);
      }
      if (
        this.StandardsManualAbbr === 'OTP' &&
        this.SurveyEndDate &&
        moment(this.SurveyEndDate) >= moment('2024-09-30') &&
        !this.IsPreliminary &&
        this.CompNotifdOfOutcomeDt &&
        moment(this.CompNotifdOfOutcomeDt) <= moment().startOf('day') &&
        this.SIRDueDate &&
        moment(this.SIRDueDate) >= moment().startOf('day') &&
        !this.SIRRecvdDate &&
        this.Status !== 'Suspended' &&
        this.Status !== 'Rescinded' &&
        this.CompanyCountry === 'US'
      ) {
        const obj = new ButtonList();
        obj.icon = 'fa fa-file-upload';
        obj.text = 'SURVEY.COMPLEXHEADER.UPLOADSIR';
        obj.type = 'upload';
        this.ButtonsData.push(obj);
      }
      if (data.AIMS_Accred_Expiration_Dt)
        this.ExpirationDates.push(new Date(data.AIMS_Accred_Expiration_Dt));
      if (data.AIMS_Rollover_Expiration_Date)
        this.ExpirationDates.push(new Date(data.AIMS_Rollover_Expiration_Date));
      if (data.AIMS_Temp_Ext_Expiration_Date)
        this.ExpirationDates.push(new Date(data.AIMS_Temp_Ext_Expiration_Date));
      if (
        this.CompNotifdOfOutcomeDt &&
        new Date(this.CompNotifdOfOutcomeDt) <= new Date() &&
        this.AccredDecisionPositive === true &&
        new Date(new Date().toDateString()) <= new Date(Math.max.apply(null, this.ExpirationDates))
      ) {
        const obj = new ButtonList();
        obj.icon = 'fa fa-shopping-cart';
        obj.text = 'SURVEY.COMPLEXHEADER.ORDERADD.ADD';
        obj.modalId = 'additionalCerti';
        this.ButtonsData.push(obj);
      }
    }
  }
}
