import { NavigationTile } from './NavigationTile';

function updateStatus(status: string) {
  if (status === 'true') {
    return 'completed';
  }
  return status === 'false' ? 'Missing Information' : '';
}
function updateStatusIcon(status: string) {
  if (status === 'true') {
    return 'fa fa-check-circle';
  }
  return status === 'false' ? 'fa fa-exclamation-triangle' : '';
}
export class ApplicationSubmitRequired {
  ProgressStatus?: number;
  OverallStatus?: boolean;
  OrgInfoStatus?: string;
  ContactInfoStatus?: string;
  StatDemoStatus?: string;
  SchedInfoStatus?: string;
  ProgramStatus?: string;
  LocationStatus?: string;
  OtherInfoStatus?: string;
  AppItemsStatus?: string;
  AgreementStatus?: string;
  NavigationTiles: Array<NavigationTile>;

  constructor(data?: any) {
    this.ProgressStatus = data.AIMS_App_Progress;
    this.OverallStatus = data?.AIMS_OverallStatus;
    this.OrgInfoStatus = data.AIMS_Intent_OrgInfoStatus;
    this.ContactInfoStatus = data.AIMS_Intent_ContactInfoStatus;
    this.StatDemoStatus = data.AIMS_Intent_StatDemoStatus;
    this.SchedInfoStatus = data.AIMS_Intent_SchedInfoStatus;
    this.ProgramStatus = data.AIMS_Intent_ProgramStatus;
    this.LocationStatus = data.AIMS_Intent_LocationStatus;
    this.OtherInfoStatus = data.AIMS_Intent_OtherInfoStatus;
    this.AppItemsStatus = data.AIMS_Intent_AppItemsStatus;
    this.AgreementStatus = data.AIMS_Intent_AgreementStatus;
    this.NavigationTiles = [
      {
        id: 'instructions',
        heading: 'APPLICATION.APPINSTRUCTIONS.TILETEXT',
        status: '',
        statusIcon: '',
        icon: 'fa fa-book-open',
        isSelected: false,
      },
      {
        id: 'organisationInfo',
        heading: 'APPLICATION.ORGINFO.TILETEXT',
        status: updateStatus(this.OrgInfoStatus),
        statusIcon: updateStatusIcon(this.OrgInfoStatus),
        icon: 'fa fa-sitemap',
        isSelected: false,
      },
      {
        id: 'contactInfo',
        heading: 'APPLICATION.CONTACTINFO.TILETEXT',
        status: updateStatus(this.ContactInfoStatus),
        statusIcon: updateStatusIcon(this.ContactInfoStatus),
        icon: 'fa fa-address-card',
        isSelected: false,
      },
      {
        id: 'statistics',
        heading: 'APPLICATION.STATINFO.TILETEXT',
        status: updateStatus(this.StatDemoStatus),
        statusIcon: updateStatusIcon(this.StatDemoStatus),
        icon: 'fa fa-chart-bar',
        isSelected: false,
      },
      {
        id: 'schedulingInfo',
        heading: 'APPLICATION.SCHEDULINGINFO.TILETEXT',
        status: updateStatus(this.SchedInfoStatus),
        statusIcon: updateStatusIcon(this.SchedInfoStatus),
        icon: 'fa fa-calendar-alt',
        isSelected: false,
      },
      {
        id: 'programs',
        heading: 'APPLICATION.PROGRAMS.TILETEXT',
        status: updateStatus(this.ProgramStatus),
        statusIcon: updateStatusIcon(this.ProgramStatus),
        icon: 'fa fa-list-alt',
        isSelected: false,
      },
      {
        id: 'locations',
        heading: 'APPLICATION.LOCATIONS.TILETEXT',
        status: updateStatus(this.LocationStatus),
        statusIcon: updateStatusIcon(this.LocationStatus),
        icon: 'fa fa-map-marked-alt',
        isSelected: false,
      },
      {
        id: 'otherInfo',
        heading: 'APPLICATION.OTHERINFO.TILETEXT',
        status: updateStatus(this.OtherInfoStatus),
        statusIcon: updateStatusIcon(this.OtherInfoStatus),
        icon: 'fa fa-info-circle',
        isSelected: false,
      },
      {
        id: 'appitems',
        heading: 'APPLICATION.APPITEMS.TILETEXT',
        status: updateStatus(this.AppItemsStatus),
        statusIcon: updateStatusIcon(this.AppItemsStatus),
        icon: 'fa fa-clipboard-list',
        isSelected: false,
      },
      {
        id: 'agreement',
        heading: 'APPLICATION.AGREEMENT.TILETEXT',
        status: updateStatus(this.AgreementStatus),
        statusIcon: updateStatusIcon(this.AgreementStatus),
        icon: 'fa fa-file-signature',
        isSelected: true,
      },
    ];
  }
}
