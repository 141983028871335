import { Address } from './Address';

export class SimpleHeader {
  Header: string = '';
  Title: string = '';
  Department: string = '';
  Address: Address;
  ActionButton: string = '';

  constructor(data?: any) {
    this.Address = new Address();
    if (data) {
      Object.assign(data);
    }
  }
}
