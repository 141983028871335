import { Utils } from 'src/app/shared/_models/common/Date-Formatter';
import { IAnnualReportList } from '../_contracts/IAnnualReportList';

export class AnnualReportList implements IAnnualReportList {
  ACQRId: string;
  surveyNumber: string;
  companyName: string;
  dueDate: string;
  sortDueDate: Date;
  feeReceivedDate: Date;
  stdManualName: string;

  constructor(data: any) {
    this.ACQRId = data.AIMS_ACQR_Id;
    this.surveyNumber = data.AIMS_Survey_Number;
    this.companyName = data.Company_Name;
    this.dueDate = Utils.formatLongDate(data.AIMS_ACQR_Anniversary_Date);
    this.sortDueDate = data.AIMS_ACQR_Anniversary_Date;
    this.feeReceivedDate = data?.AIMS_ACQR_Fee_Recd_Dt;
    this.stdManualName = data?.AIMS_Standards_Manual_Name;
  }
}
