<div class="d-flex justify-content-center spinner-pad mdl-loader">
  <div class="spinner-border" role="status" 
    [style.width]="size + 'em'"
    [style.height]="size + 'em'"
    [style.color]="color">
    <span class="sr-only" id="loading" >Loading...</span>
  </div>
</div>
<div class="d-flex justify-content-center msg">
  <ng-content></ng-content>
</div>