import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { certificateFormTableConfig } from 'src/app/survey/_constants/constants';
import { CheckPaymentInstruction } from 'src/app/survey/_models/CheckPaymentInstrctn';
import { SurveyDetails } from 'src/app/survey/_models/SurveyDetails';
import { SurveyService } from 'src/app/survey/_services/survey.service';

@Component({
  selector: 'app-check-payment-ins',
  templateUrl: './check-payment-ins.component.html',
  styleUrls: ['./check-payment-ins.component.scss'],
})
export class CheckPaymentInsComponent implements OnInit {
  orderSummaryColumns = certificateFormTableConfig;
  checkPaymentData: CheckPaymentInstruction;
  modal: CheckPaymentInstruction;
  surveyDetails: SurveyDetails;
  mailAddress: string;
  showOnPrint: boolean = false;
  @Input() surveyCertId: any;
  @Input() additionalCertificatesFormValue: any;
  showOrHideLoader: boolean = false;

  constructor(private surveyService: SurveyService, private modalService: NgbModal) {}
  ngOnInit(): void {
    this.getCheckPaymentData();
    this.surveyDetails = this.surveyService.surveyDetails;
  }

  getCheckPaymentData() {
    this.showOrHideLoader = true;
    if (this.surveyCertId) {
      this.surveyService
        .getAdditionalCertCheckPaymentIns(this.surveyCertId)
        .subscribe((res: CheckPaymentInstruction) => {
          this.checkPaymentData = res;
          this.showOrHideLoader = false;
          if (this.checkPaymentData) {
            this.mailAddressCondition();
          }
        });
    }
  }

  mailAddressCondition() {
    if (this.checkPaymentData?.OfficeName === 'CARF International Headquarters') {
      this.mailAddress = 'CARF PO Box 674401, Dallas, TX 75267-4401.';
    } else if (this.checkPaymentData?.OfficeName === 'CARF Canada') {
      this.mailAddress = 'CARF Canada c/o C07402C PO Box 45 Station M Calgary, Alberta T2P 2G9.';
    } else {
      this.mailAddress = 'CARF 6951 E. Southpoint Road, Tucson, AZ 85756.';
    }
  }

  onPrint() {
    this.showOnPrint = true;
    this.surveyService.notifyAddtnlCertPrint({
      checkPaymentData: this.checkPaymentData,
      addtnlCertifData: this.additionalCertificatesFormValue,
      mailAddress: this.mailAddress,
    });
    this.OnCancel();
  }

  OnCancel() {
    this.modalService.dismissAll();
  }
}
