/* eslint-disable max-classes-per-file */
export class ButtonList {
  icon: string = '';
  text: string = '';
  bgColor?: string = '';
  modalId?: string = '';
  type?: string = '';

  constructor(data?: any) {
    if (data) {
      Object.assign(data);
    }
  }
}

export class ComplexHeader {
  headerTitle: string = '';
  headerNumber?: string = '';
  companyName: string = '';
  subHeaderTitle: string = '';
  subHeaderContent: string = '';
  buttons: Array<ButtonList> = [];
  showHelpIcon: boolean = false;
  subHeaderTranslationRequired: boolean = true;
  accredExpirationDate: string = '';
  constructor(data?: any) {
    if (data) {
      Object.assign(data);
    }
  }
}
