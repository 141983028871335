import { Injectable } from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DynamicDialogComponent } from '../_components/dynamic-dialog/dynamic-dialog';
import { IDynamicDialogData } from '../_contracts/IDynamicDialogData';

@Injectable()
export class DynamicDialogService {
  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal) {}

  public showDialog(input: IDynamicDialogData): Promise<boolean> {
    const modalRef = this.modalService.open(DynamicDialogComponent, { size: 'lg' });
    modalRef.componentInstance.dialogInfo = input;

    return modalRef.result;
  }

  public yes() {
    this.activeModal.close(true);
  }

  public no() {
    this.activeModal.close(false);
  }

  public cancel() {
    this.activeModal.dismiss();
  }
}
