import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  buttonBarContactUs: any;
  constructor(private titleService: Title, private router: Router) {
    this.titleService.setTitle('Customer Connect – Contact Us');
  }

  ngOnInit(): void {
    this.buttonBarContactUs = {
      btn1: {
        btnIcon1: 'fa fa-print',
        btntext1: 'Print',
      },
    };
  }
  onPrint() {
    window.print();
  }
}
