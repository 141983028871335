import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingModalService {
  private loadingModalSubject = new Subject<any>();

  loadModal(showHide) {
    this.loadingModalSubject.next(showHide);
  }

  showHideModal(): Observable<any> {
    return this.loadingModalSubject.asObservable();
  }
}
