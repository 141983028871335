/* eslint-disable prettier/prettier */
export const ApplicationTableConfig: any = {
  columns: [
    {
      title: 'HOME.APPLICATIONTABLECONFIG.NUMBER',
      dataProperty: 'AIMS_Intent_Number',
      sortable: false,
      filterable: false,
      direction: '',
    },
    {
      title: 'HOME.APPLICATIONTABLECONFIG.NAME',
      dataProperty: 'AIMS_Intent_Org_Unit_Name',
      sortable: false,
      filterable: false,
      direction: '',
    },
    {
      title: 'HOME.APPLICATIONTABLECONFIG.CITY',
      dataProperty: 'AIMS_Intent_Org_City',
      sortable: false,
      filterable: false,
      direction: '',
    },
    {
      title: 'HOME.APPLICATIONTABLECONFIG.STATE',
      dataProperty: 'AIMS_Intent_Org_State',
      sortable: false,
      filterable: false,
      direction: '',
    },
    {
      title: 'HOME.APPLICATIONTABLECONFIG.STATUS',
      dataProperty: 'AIMS_Intent_Status',
      sortable: false,
      filterable: false,
      direction: '',
    },
    {
      title: 'HOME.APPLICATIONTABLECONFIG.PROGRESS',
      dataProperty: 'AIMS_App_Progress',
      sortable: false,
      filterable: false,
      direction: '',
    },
  ],
};
export const SurveyTableConfig: any = {
  columns: [
    {
      title: 'HOME.SURVEYTABLECONFIG.NUMBER',
      dataProperty: 'AIMS_Survey_Number',
      sortable: false,
      filterable: false,
      direction: '',
    },
    {
      title: 'HOME.SURVEYTABLECONFIG.NAME',
      dataProperty: 'Company_Name',
      sortable: false,
      filterable: false,
      direction: '',
    },
    {
      title: 'HOME.SURVEYTABLECONFIG.TYPE',
      dataProperty: 'AIMS_Survey_Type',
      sortable: false,
      filterable: false,
      direction: '',
    },
    {
      title: 'HOME.SURVEYTABLECONFIG.STATUS',
      dataProperty: 'AIMS_Survey_Status',
      sortable: false,
      filterable: false,
      direction: '',
    },
    {
      title: 'HOME.SURVEYTABLECONFIG.DECISION',
      dataProperty: 'AIMS_Accred_Decision',
      sortable: false,
      filterable: false,
      direction: '',
    },
    {
      title: 'HOME.SURVEYTABLECONFIG.EXPDATE',
      dataProperty: 'AIMS_Accred_Expiration_Dt',
      sortable: false,
      filterable: false,
      direction: '',
    },
  ],
};
export const SubHeader = ['HOME.APPLICATIONHEADER', 'HOME.SURVEYHEADER'];
