/* eslint-disable prefer-destructuring */
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import * as moment from 'moment';
import { applicationStatusColor, surveyStatusColor } from '../../_constants/constants';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnChanges {
  @Input()
  public primaryTableConfig: any;

  @Input()
  public primaryTableData: [];

  @Input()
  public secondaryTableConfig: any;

  @Input()
  public secondaryTableData: [];
  @Input() sliceCount = '';

  @Input() isPageable = false;
  @Input() isFilterable = false;
  @Input() paginationSizes: number[] = [2, 4, 6, 10];
  @Input() header = '';
  @Input() isIntegratedTable = false;
  @Input() secondaryHeader = [];
  @Input() primaryShowViewAll = false;
  @Input() secondaryShowViewAll = false;
  @Input() placeHolder = 'Search Keywords';
  @Input() showOrHideLoader = false;
  @Input() showOrHideSecLoader = false;

  @Output()
  public startRowAction = new EventEmitter<{}>();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output()
  public onRowClicked = new EventEmitter<{}>();

  @Output()
  public viewAll = new EventEmitter<{}>();

  public pageList: number[];
  selectedPageSize = this.paginationSizes[0];
  listOfAvailablePages: Array<number> = [];
  currentPage = 1;
  footerText = '';
  gridContent: any;
  secondaryGridContent: any;
  searchText = '';
  tableContent: any;
  secondaryTableContent: any;
  applicationStatusColor = applicationStatusColor;
  surveyStatusColor = surveyStatusColor;

  public ngOnChanges() {
    if (this.primaryTableData) {
      this.tableContent = JSON.parse(JSON.stringify(this.primaryTableData));
      this.gridContent = this.tableContent;

      if (this.isPageable && this.selectedPageSize) {
        this.selectedPageSize = this.paginationSizes[0];
        this.refreshObject();
      }
    }
    if (this.secondaryTableData) {
      this.secondaryTableContent = JSON.parse(JSON.stringify(this.secondaryTableData));
      this.secondaryGridContent = this.secondaryTableContent;
    }
  }

  refreshObject() {
    this.listOfAvailablePages = [];
    const numberOfPages = Math.ceil(this.tableContent.length / this.selectedPageSize);
    for (let i = 1; i <= numberOfPages; i += 1) {
      this.listOfAvailablePages.push(i);
    }

    if (this.isPageable && numberOfPages > 1) {
      this.gridContent = [];
      this.pageChangeTriggered(1);
    } else {
      this.gridContent = this.tableContent;
    }
    this.onPageSelection();
  }

  filterData() {
    this.tableContent = [];
    this.listOfAvailablePages = [];
    if (this.searchText) {
      this.performFilter(this.primaryTableData, this.primaryTableConfig, this.tableContent);
      this.gridContent = this.tableContent;
      if (this.secondaryTableData) {
        this.secondaryTableContent = [];
        this.performFilter(
          this.secondaryTableData,
          this.secondaryTableConfig,
          this.secondaryTableContent,
        );
        this.secondaryGridContent = this.secondaryTableContent;
      }
    } else {
      this.tableContent = JSON.parse(JSON.stringify(this.primaryTableData));
      this.gridContent = this.tableContent;
      if (this.secondaryTableData) {
        this.secondaryTableContent = JSON.parse(JSON.stringify(this.secondaryTableData));
        this.secondaryGridContent = this.secondaryTableContent;
      }
    }
    this.refreshObject();
  }

  performFilter(tableData, tableConfig, updatedObj) {
    tableData.forEach((item: any) => {
      tableConfig.columns.forEach((keyName: any) => {
        if (
          keyName.dataProperty !== 'actions' &&
          keyName.dataProperty !== 'AIMS_App_Progress' &&
          item[keyName.dataProperty]
            ?.toLocaleLowerCase()
            .includes(this.searchText.toLocaleLowerCase()) &&
          updatedObj.indexOf(item) === -1
        ) {
          updatedObj.push(item);
        }
      });
    });
  }

  pageChangeTriggered(pageNum: number) {
    this.gridContent = this.tableContent.slice(
      (pageNum - 1) * this.selectedPageSize,
      (pageNum - 1) * this.selectedPageSize + +this.selectedPageSize,
    );
    this.onPageSelection();
  }

  public onPageSelection(): void {
    let startNumber = 1;
    let endNumber = 0;
    if (this.listOfAvailablePages.length === 1) {
      endNumber = this.tableContent.length;
    } else if (this.listOfAvailablePages.length > 1 && this.currentPage === 1) {
      endNumber = this.selectedPageSize;
    } else if (this.currentPage === this.listOfAvailablePages.length) {
      startNumber = (this.listOfAvailablePages.length - 1) * this.selectedPageSize + 1;
      endNumber = this.tableContent.length;
    } else {
      startNumber = (this.currentPage - 1) * this.selectedPageSize + 1;
      endNumber = this.currentPage * this.selectedPageSize;
    }
    this.footerText = `Showing ${startNumber} to ${endNumber} of ${this.tableContent.length} entries`;
  }

  public setSelectedPageSize(value: number): void {
    if (this.paginationSizes && value) {
      this.selectedPageSize = value;
      // eslint-disable-next-line prefer-destructuring
    } else this.selectedPageSize = this.paginationSizes[0];
    this.currentPage = 1;
    this.gridContent = this.tableContent.slice(
      (this.currentPage - 1) * this.selectedPageSize,
      (this.currentPage - 1) * this.selectedPageSize + +this.selectedPageSize,
    );
    this.listOfAvailablePages = [];
    const numberOfPages = Math.ceil(this.tableContent.length / this.selectedPageSize);
    for (let i = 1; i <= numberOfPages; i += 1) {
      this.listOfAvailablePages.push(i);
    }
    this.onPageSelection();
  }

  sortData(columnHead: any) {
    if (columnHead.direction === '' || columnHead.direction === 'desc') {
      if (columnHead?.type === 'Date') {
        this.tableContent = this.tableContent.sort((a: any, b: any) => {
          return moment(a[columnHead.sortProperty]).diff(b[columnHead.sortProperty]);
        });
      } else if (columnHead?.type === 'Number') {
        this.tableContent = this.sortNumberArray(
          this.tableContent,
          columnHead.sortProperty,
        ).reverse();
      } else {
        this.tableContent = this.tableContent.sort((a: any, b: any) =>
          a[columnHead.dataProperty].localeCompare(b[columnHead.dataProperty]),
        );
      }
      const obj = this.primaryTableConfig.columns.find((item) => item === columnHead);
      obj.direction = 'asc';
    } else if (columnHead.direction === 'asc') {
      if (columnHead?.type === 'Date') {
        this.tableContent = this.tableContent.sort((a: any, b: any) =>
          moment(b[columnHead.sortProperty]).diff(a[columnHead.sortProperty]),
        );
      } else if (columnHead?.type === 'Number') {
        this.tableContent = this.sortNumberArray(this.tableContent, columnHead.sortProperty);
      } else {
        this.tableContent = this.tableContent.sort((a: any, b: any) =>
          b[columnHead.dataProperty].localeCompare(a[columnHead.dataProperty]),
        );
      }
      const obj = this.primaryTableConfig.columns.find((item) => item === columnHead);
      obj.direction = 'desc';
    }
    this.refreshObject();
  }

  sortNumberArray(data, propertyName: string) {
    return data?.sort((a, b) => {
      if (a[propertyName] > b[propertyName]) return 1;
      if (a[propertyName] < b[propertyName]) return -1;
      return 0;
    });
  }

  navigate(columnData: any) {
    this.onRowClicked.emit(columnData);
  }

  public onRowActionClicked(actionType: string, clickedRowData): void {
    const userAction = {
      actionToPerform: actionType,
      rowData: clickedRowData,
    };

    this.startRowAction.emit(userAction);
  }

  viewAllClicked(clickedTable: string) {
    this.viewAll.emit(clickedTable);
  }
}
