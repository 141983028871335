import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  sortDir = 1;
  transform(data: any[], propertyName: string, sortOrder = 'asc'): Array<any> {
    this.sortDir = sortOrder.toLowerCase() === 'asc' ? 1 : -1; // 1= 'ASC' -1= DSC
    let typeObj = null;
    if (data?.length > 0) {
      typeObj = typeof data[0][propertyName];
    }
    if (typeObj === 'string') {
      return data.sort((a, b) => {
        a = a[propertyName]?.toLowerCase();
        b = b[propertyName]?.toLowerCase();
        return a?.localeCompare(b) * this.sortDir;
      });
    }
    if (typeObj === 'Date') {
      return data.sort((a, b) => {
        return moment(a.dueDate).diff(b.dueDate);
      });
    }
    if (typeObj === 'boolean') {
      return data.sort((a, b) => {
        if (a[propertyName] === b[propertyName]) {
          return 0;
        }
        return a[propertyName] ? this.sortDir : -this.sortDir;
      });
    }
    if (typeObj === 'number') {
      const sortedObj = this.sortNumberArray(data, propertyName);
      if (this.sortDir === 1) {
        return sortedObj;
      }
      return sortedObj.reverse();
    }
    return data;
  }

  sortNumberArray(data, propertyName: string) {
    return data?.sort((a, b) => {
      if (a[propertyName] > b[propertyName]) return 1;
      if (a[propertyName] < b[propertyName]) return -1;
      return 0;
    });
  }
}
