<div class="row no-gutters mb-1 d-flex flex-column">
  <div class="appl-progress mt-4" *ngIf="!this.applicationData">
    {{ title | translate }} - <span class="bold">{{ progressValue }} </span>%
  </div>
  <div class="col-lg-12 pr">
    <div [ngClass]="applicationData ? 'progress-bar-outer' : 'bar'" class="progress mt-2 mb-4">
      <div
        [ngClass]="applicationData ? 'progress-bar-application' : 'progress-bar'"
        role="progressbar"
        aria-label="progressValue"
        [attr.aria-valuenow]="progressValue"
        aria-valuemin="0"
        aria-valuemax="100"
        [style.width]="progressValue + '%'"
      ></div>
    </div>
  </div>
</div>
