<div class="row no-gutters d-flex align-items-center per-page mb-3" *ngIf="isPageable">
  <span>{{ 'COMMON.SHOW' | translate }}</span>
  <div class="col-auto mx-2">
    <select
      class="form-control custom-select mr-sm-2"
      id="inlineFormCustomSelect"
      aria-label="selectPagination"
      [(ngModel)]="selectedPageSize"
      (change)="setSelectedPageSize(selectedPageSize)"
    >
      <option class="option" value="" selected disabled>- Select -</option>
      <option *ngFor="let pageSize of paginationSizes" [value]="pageSize">{{ pageSize }}</option>
    </select>
  </div>
  <span>{{ 'COMMON.ENTRIES' | translate }}</span>
</div>
<div id="primaryTableCaption">
  <div class="row no-gutters d-flex justify-content-between table-header" *ngIf="header !== ''">
    <div class="col-md-auto col-lg-auto">
      <h1 class="mb-0">{{ header | translate }}</h1>
    </div>
    <div class="col-md-8 col-xl-8 col-lg-7" *ngIf="isFilterable">
      <div class="row no-gutters d-flex justify-content-lg-end">
        <div class="col-md-8 col-lg-7 col-xl-8">
          <div class="custom-search">
            <i class="fa fa-search" aria-hidden="true"></i>
            <div class="w-100" id="searchKeyword">
              <input
                type="search"
                class="form-control"
                placeholder="{{ placeHolder }}"
                aria-label="searchkeywords"
                [(ngModel)]="searchText"
                (input)="filterData()"
              />
            </div>
          </div>
        </div>
        <div class="col-1 col-lg-auto">
          <button class="btn btn-light ml-2 search-btn" (click)="filterData()">
            {{ 'COMMON.SEARCH' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row no-gutters d-flex align-items-center table-sub-header"
    *ngIf="secondaryHeader.length > 0"
  >
    <div class="col-sm-auto">
      {{ secondaryHeader[0] | translate }}
    </div>
  </div>
</div>
<div class="table-responsive">
  <table class="table company-tbl" aria-describedby="primaryTableCaption">
    <thead>
      <tr>
        <th
          scope="col"
          class="default-cursor"
          *ngFor="let columnHead of primaryTableConfig.columns"
        >
          {{ columnHead.title | translate }}
          <span *ngIf="columnHead.sortable" (click)="sortData(columnHead)"
            ><i class="fa fa-sort pointer-cursor" aria-hidden="true"></i>
          </span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        (click)="navigate(columnData)"
        (keyup.enter)="navigate(columnData)"
        *ngFor="let columnData of sliceCount ? (gridContent | slice: 0:sliceCount) : gridContent"
      >
        <td *ngFor="let columnHead of primaryTableConfig.columns">
          <div
            *ngIf="
              columnHead.dataProperty !== 'actions' &&
                columnHead.dataProperty !== 'AIMS_App_Progress' &&
                columnHead.dataProperty !== 'AIMS_Intent_Status' &&
                columnHead.dataProperty !== 'surveyStatus';
              else action_content
            "
          >
            {{ columnData[columnHead.dataProperty] }}
          </div>
          <ng-template #action_content>
            <div *ngIf="columnHead.dataProperty === 'actions'">
              <button class="btn editBtn mr-2" *ngIf="columnData.showEdit">
                <i class="fa fa-pencil-square-o editIcon mr-2" aria-hidden="true"></i
                >{{ 'COMMON.EDIT' | translate }}
              </button>
              <button
                class="btn deleteBtn"
                data-toggle="modal"
                data-target="#delete-modal"
                data-backdrop="static"
                (click)="openDeleteModal()"
                *ngIf="columnData.showDelete"
              >
                <i class="fa fa-trash deleteIcon mr-2" aria-hidden="true"></i
                >{{ 'COMMON.DELETE' | translate }}
              </button>
            </div>
            <div *ngIf="columnHead.dataProperty === 'AIMS_App_Progress'">
              <app-progress-bar
                [progressValue]="columnData[columnHead.dataProperty]"
                [applicationData]="true"
              ></app-progress-bar>
            </div>
            <div
              class="status-width"
              *ngIf="
                columnHead.dataProperty === 'AIMS_Intent_Status' ||
                columnHead.dataProperty === 'surveyStatus'
              "
            >
              <span
                *ngIf="columnHead.dataProperty === 'AIMS_Intent_Status'"
                class="mr-sm-2 status-default {{
                  applicationStatusColor[columnData[columnHead.dataProperty]]
                }}"
                ><i class="fa fa-circle" aria-hidden="true"></i
              ></span>
              <span
                *ngIf="columnHead.dataProperty === 'surveyStatus'"
                class="mr-sm-2 status-default {{
                  surveyStatusColor[columnData[columnHead.dataProperty]]
                }}"
                ><i class="fa fa-circle" aria-hidden="true"></i
              ></span>
              {{ columnData[columnHead.dataProperty] }}
            </div>
          </ng-template>
        </td>
      </tr>
      <tr>
        <td
          *ngIf="showOrHideLoader"
          class="text-center"
          [attr.colspan]="primaryTableConfig.columns.length"
        >
          <app-loader class="my-3"></app-loader>
        </td>
        <td
          *ngIf="primaryTableData?.length === 0 && !showOrHideLoader"
          class="text-center"
          [attr.colspan]="primaryTableConfig.columns.length"
        >
          {{ 'COMMON.NODATA' | translate }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="row no-gutters mb-3">
  <a
    href="JavaScript:void(0)"
    (click)="viewAllClicked('primary')"
    class="viewAll"
    *ngIf="sliceCount ? gridContent.length > sliceCount : false"
    >{{ 'COMMON.VIEWALL' | translate }}</a
  >
</div>

<div
  class="row no-gutters d-flex align-items-center table-sub-header"
  *ngIf="isIntegratedTable && secondaryHeader.length > 1"
>
  <div class="col-2 col-lg-3 col-md-2" id="tblCaption2">
    {{ secondaryHeader[1] | translate }}
  </div>
</div>
<table class="table company-tbl" *ngIf="isIntegratedTable" aria-describedby="tblCaption2">
  <thead>
    <tr>
      <th
        scope="col"
        class="default-cursor"
        *ngFor="let columnHead of secondaryTableConfig.columns"
      >
        {{ columnHead.title | translate }}
        <span *ngIf="isSortable" (click)="sortData(columnHead)"
          ><i *ngIf="columnHead.sortable" class="fa fa-sort pointer-cursor" aria-hidden="true"></i>
        </span>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr
      (click)="navigate(columnData)"
      (keyup.enter)="navigate(columnData)"
      *ngFor="let columnData of secondaryGridContent | slice: 0:sliceCount"
    >
      <td *ngFor="let columnHead of secondaryTableConfig.columns">
        <div
          *ngIf="
            columnHead.dataProperty !== 'actions' &&
              columnHead.dataProperty !== 'AIMS_App_Progress' &&
              columnHead.dataProperty !== 'AIMS_Survey_Status';
            else action_content
          "
        >
          {{ columnData[columnHead.dataProperty] }}
        </div>
        <ng-template #action_content>
          <div *ngIf="columnHead.dataProperty === 'actions'">
            <button class="btn editBtn mr-2" *ngIf="columnData.showEdit">
              <i class="fa fa-pencil-square-o editIcon mr-2" aria-hidden="true"></i
              >{{ 'COMMON.EDIT' | translate }}
            </button>
            <button
              class="btn deleteBtn"
              data-toggle="modal"
              data-target="#delete-modal"
              data-backdrop="static"
              (click)="openDeleteModal()"
              *ngIf="columnData.showDelete"
            >
              <i class="fa fa-trash deleteIcon mr-2" aria-hidden="true"></i
              >{{ 'COMMON.DELETE' | translate }}
            </button>
          </div>
          <div *ngIf="columnHead.dataProperty === 'AIMS_App_Progress'">
            <app-progress-bar
              [progressValue]="columnData[columnHead.dataProperty]"
              [applicationData]="true"
            ></app-progress-bar>
          </div>
          <div class="status-width" *ngIf="columnHead.dataProperty === 'AIMS_Survey_Status'">
            <span
              class="mr-sm-2 status-default {{
                surveyStatusColor[columnData[columnHead.dataProperty]]
              }}"
              ><i class="fa fa-circle" aria-hidden="true"></i
            ></span>
            {{ columnData[columnHead.dataProperty] }}
          </div>
        </ng-template>
      </td>
    </tr>
    <tr>
      <td
        *ngIf="showOrHideSecLoader"
        class="text-center"
        [attr.colspan]="secondaryTableConfig.columns.length"
      >
        <app-loader class="my-3"></app-loader>
      </td>
      <td
        *ngIf="secondaryTableData?.length === 0 && !showOrHideSecLoader"
        class="text-center"
        [attr.colspan]="secondaryTableConfig.columns.length"
      >
        {{ 'COMMON.NODATA' | translate }}
      </td>
    </tr>
  </tbody>
</table>

<div class="row no-gutters mb-3">
  <a
    href="JavaScript:void(0)"
    (click)="viewAllClicked('secondary')"
    class="viewAll"
    *ngIf="sliceCount ? secondaryGridContent.length > sliceCount : false"
    >{{ 'COMMON.VIEWALL' | translate }}</a
  >
</div>

<div class="row no-gutters footer-row" *ngIf="isPageable">
  <div class="tbl-footer">{{ footerText }}</div>
</div>

<ngb-pagination
  *ngIf="isPageable"
  [collectionSize]="tableContent?.length"
  [(page)]="currentPage"
  [pageSize]="selectedPageSize"
  [maxSize]="18"
  (pageChange)="pageChangeTriggered($event)"
>
  <ng-template ngbPaginationPrevious>{{ 'COMMON.PREVIOUS' | translate }}</ng-template>
  <ng-template ngbPaginationNext>{{ 'COMMON.NEXT' | translate }}</ng-template>
</ngb-pagination>
