import { environment } from 'src/environments/environment';

/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: environment.names,
  authorities: {
    signUpSignIn: environment.signInAuthority,
    forgotPassword: environment.forgotPasswordAuthority,
    changePassword: environment.changePasswordAuthority,
    signOut: environment.signOutAuthority,
  },
  authorityDomain: environment.authorityDomain,
};
