/* eslint-disable max-classes-per-file */
import { Program } from './SurveyInfo';

export class OrganizationLocation {
  CompanyId: string;
  Name: string;
  Number: string;
  Address1: string;
  Address2: string;
  Address3: string;
  City: string;
  State: string;
  Zip: string;
  Country: string;
  Programs: Array<Program>;

  constructor(data?: any) {
    if (data) {
      this.CompanyId = data.AIMS_Company_Id;
      this.Name = data.Company_Name;
      this.Number = data.Company_Number;
      this.Address1 = data.Address_1;
      this.Address2 = data.Address_2;
      this.Address3 = data.Address_3;
      this.City = data.City;
      this.State = data.State;
      this.Zip = data.Zip;
      this.Country = data.Country;
      this.Programs = data.Programs?.map((item) => new Program(item));
    }
  }
}
export class AdditionalCertificates {
  OrganizationLocation: Array<OrganizationLocation>;

  constructor(data?: any) {
    this.OrganizationLocation = data.OrganizationLocation?.map(
      (item) => new OrganizationLocation(item),
    );
  }
}
