/* eslint-disable no-restricted-globals */

import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { PaymentsService } from 'src/app/shared/_components/payment/services/payments.service';
import { SortPipe } from 'src/app/shared/_pipes/sort.pipe';
import { SharedService } from 'src/app/shared/_services/shared.service';
import { surveyFeePaymentHistoryCol } from 'src/app/survey/_constants/constants';
import { SurveyPayments } from 'src/app/survey/_models/SurveyPayments';
import { SurveyService } from 'src/app/survey/_services/survey.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pay-survey-fee',
  templateUrl: './pay-survey-fee.component.html',
  styleUrls: ['./pay-survey-fee.component.scss'],
})
export class PaySurveyFeeComponent implements OnInit, OnDestroy {
  @ViewChild('theForm', { static: true, read: ElementRef }) form: ElementRef;
  @Input() surveyIdInput: string = '';
  surveypaymntHistoryColumns = surveyFeePaymentHistoryCol;
  paymntAmountList: any;
  responseReceived: SurveyPayments;
  currencyValue: string;
  surveyTypeRecord: string;
  showEnterAmount: boolean = false;
  paymentAmountData: any;
  errorMessage: boolean = false;
  orderDetails: any = {};
  public acceptPaymentPageResponse = null;
  tokenReceived: any;
  showOrHideLoader = true;
  IFRAME_URL: string;
  HOSTEDPAYMENT_URL: string;
  transactResponse = null;
  openPaymentForm: boolean = false;
  historyTableSummary: any;
  surveyId: string;
  getSelectedComponentInfo: any;
  selectedComponent: string;
  orderDetailsAmount: any = {};

  constructor(
    private surveyService: SurveyService,
    private paymentsService: PaymentsService,
    private sharedService: SharedService,
    private router: Router,
    private sortPipe: SortPipe,
  ) {}

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    window.removeEventListener('message', this.receiveSurveyMessage, false);
  }
  ngOnInit(): void {
    if (window.addEventListener) {
      window.addEventListener('message', this.receiveSurveyMessage, false);
    } else {
      (<any>window).attachEvent('onmessage', this.receiveSurveyMessage, false);
    }
    this.selectedComponent = 'Survey Payment';
    this.paymentsService.setComponent(this.selectedComponent);
    this.surveyId = this.surveyIdInput
      ? this.surveyIdInput
      : this.surveyService.surveyDetails?.SurveyId;
    this.IFRAME_URL = environment.paymentUrl;
    this.HOSTEDPAYMENT_URL = environment.hostedUrl;
    this.showOrHideLoader = true;
    this.surveyService.getSurveyPaymentDetails(this.surveyId).subscribe((res: SurveyPayments) => {
      this.responseReceived = res;
      this.showOrHideLoader = false;
      this.historyTableSummary = this.sortPipe.transform(
        this.responseReceived?.SurveyFeeHistoryData,
        'sortPaymentDate',
      );
      this.showOrHideLoader = false;
      if (this.responseReceived.Company_Country?.includes('CANADA')) {
        this.currencyValue = 'CAD';
      } else {
        this.currencyValue = 'USD';
      }
      if (this.responseReceived.SurveyType?.toLowerCase().includes('certifi')) {
        this.surveyTypeRecord = 'Certification';
      } else {
        this.surveyTypeRecord = 'Survey';
      }
    });
    this.paymntAmountList = [
      { id: 1, value: 'Pay in Full' },
      { id: 2, value: 'Partial Payment' },
    ];
  }

  changeData(event) {
    if (event === 'Partial Payment') {
      this.showEnterAmount = true;
    } else {
      this.showEnterAmount = false;
      this.errorMessage = false;
    }
  }

  onContinue() {
    this.paymentsService.setSummaryData('', '', '');

    if (this.showEnterAmount) {
      if (this.paymentAmountData > 0) {
        this.openPaymentForm = true;
        this.errorMessage = false;
        this.showOrHideLoader = true;
        this.responseReceived.TotalAmount = this.paymentAmountData;
        this.orderDetails = this.paymentsService.getorderDetails(this.responseReceived);
        this.sharedService.getHostedPaymentPage(this.orderDetails).subscribe(
          (success) => {
            if (success) {
              this.acceptPaymentPageResponse = this.sharedService.acceptPaymentPageResponse;
              this.tokenReceived = this.acceptPaymentPageResponse.Token;
              setTimeout(() =>
                // eslint-disable-next-line @typescript-eslint/dot-notation
                (this.form.nativeElement as any)['submit'].apply(this.form.nativeElement),
              );
              this.showOrHideLoader = false;
            }
          },
          () => {
            this.paymentsService.onModelClose();
          },
        );
      } else {
        this.errorMessage = true;
        // eslint-disable-next-line no-useless-return
        return;
      }
    } else {
      this.showOrHideLoader = true;
      this.openPaymentForm = true;
      this.responseReceived.TotalAmount = this.responseReceived.BalanceDue;
      this.orderDetailsAmount = this.paymentsService.getorderDetails(this.responseReceived);
      this.errorMessage = false;
      this.sharedService.getHostedPaymentPage(this.orderDetailsAmount).subscribe(
        (success) => {
          if (success) {
            this.acceptPaymentPageResponse = this.sharedService.acceptPaymentPageResponse;
            this.tokenReceived = this.acceptPaymentPageResponse.Token;
            setTimeout(() =>
              // eslint-disable-next-line @typescript-eslint/dot-notation
              (this.form.nativeElement as any)['submit'].apply(this.form.nativeElement),
            );
            this.showOrHideLoader = false;
          }
        },
        () => {
          this.paymentsService.onModelClose();
        },
      );
    }
  }

  receiveSurveyMessage = (event: any): void => {
    if (event != null && event.data != null) {
      // eslint-disable-next-line no-cond-assign
      if (event.data?.qstr?.includes('cancel')) {
        this.paymentsService.onModelClose();
        this.router.navigateByUrl('/');
      } else if (event.data?.qstr?.includes('resizeWindow')) {
        console.log('resize window' + event.data.qstr);
      } else {
        this.transactResponse = JSON.parse(this.paymentsService.parseQueryString(event?.data));
        // transaction approved
        if (
          this.transactResponse.responseCode === '1' ||
          this.transactResponse.responseCode === '4'
        ) {
          console.log(
            'Transaction approved - account:' +
              this.transactResponse.accountNumber +
              ', total amount:' +
              this.transactResponse.totalAmount,
          );
          this.getSelectedComponentInfo = this.paymentsService.getComponent();
          if (this.getSelectedComponentInfo === this.selectedComponent) {
            const updateSurvey = {
              'Survey_Id': this.responseReceived?.SurveyId,
              'totalAmount': this.responseReceived?.TotalAmount,
            };
            this.surveyService.updateSurveyPayment(updateSurvey).subscribe();
            this.paymentsService.goToPaymentSummary(this.transactResponse);
          }
        }
        // TO DO: handle various responses
        else {
          console.log('Transaction declined, response code:' + this.transactResponse.responseCode);
        }
      }
    } else {
      console.log('Payment received bad message');
    }
  };

  OnCancel() {
    this.paymentsService.onModelClose();
  }
}
