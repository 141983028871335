import * as moment from 'moment';
import { Utils } from 'src/app/shared/_models/common/Date-Formatter';
import { ISurveyList } from '../_contracts/ISurveyList';

export class SurveyList implements ISurveyList {
  surveyId: string;
  surveyNumber: string;
  sortingNumber: number;
  companyName: string;
  descriptor: string;
  surveyType: string;
  surveyStatus: string;
  accredDecision: string;
  accredExpirationDate: string;
  notifiedOutcomeDate: Date;
  unAnnouncedSurvey: boolean;
  surveyStartDate: string;
  surveyLetterSentDate: string;
  surveyFullyPaidDate: string;
  surveyApprovedToProd: boolean;
  surveyFeeDueDate: string;
  sortingFeeDueDate: Date;
  sortingQIPDueDate: Date;
  sortingExpirationDate: Date;

  // Action item filter variables
  surveyInfoLetterSentDate: Date;
  surveyFullyFeePaidDate: Date;
  qipReceivedDate: Date;
  qipNeedsMoreDueDate: Date;
  qipNeedsMoreReceivedDate: Date;
  accred_Decision: string;

  constructor(surveyList: any) {
    this.surveyId = surveyList.Survey_Id;
    this.surveyNumber = surveyList.AIMS_Survey_Number;
    this.sortingNumber = parseInt(this.surveyNumber, 10);
    this.companyName = surveyList.Company_Name;
    this.descriptor = surveyList.Rn_Descriptor;
    this.surveyType = surveyList.AIMS_Survey_Type;
    this.surveyStatus = surveyList.AIMS_Survey_Status;
    this.notifiedOutcomeDate = surveyList.AIMS_Comp_Notifd_Of_Outcome_Dt;
    this.accredDecision =
      this.notifiedOutcomeDate && moment(this.notifiedOutcomeDate) <= moment()
        ? surveyList.AIMS_Accred_Decision
        : '';
    this.accredExpirationDate =
      this.notifiedOutcomeDate && moment(this.notifiedOutcomeDate) <= moment()
        ? Utils.formatLongDate(surveyList.AIMS_Accred_Expiration_Dt)
        : null;
    this.sortingExpirationDate = surveyList.AIMS_Accred_Expiration_Dt;
    this.unAnnouncedSurvey = surveyList.AIMS_Unannounced_Survey;
    this.surveyStartDate = surveyList.AIMS_Survey_Start_Dt;
    this.surveyLetterSentDate = surveyList.AIMS_Survey_Info_Lettr_Sent_Dt;
    this.surveyInfoLetterSentDate = surveyList.AIMS_Survey_Info_Lettr_Sent_Dt;
    this.surveyFullyPaidDate = surveyList.AIMS_Dt_Survey_Fee_Fully_Pd;
    this.surveyFullyFeePaidDate = surveyList.AIMS_Dt_Survey_Fee_Fully_Pd;
    this.surveyApprovedToProd = surveyList.AIMS_Survey_Apprvl_To_Procd;
    this.surveyFeeDueDate = surveyList.AIMS_Dt_Survey_Fee_Due;
    this.sortingFeeDueDate = surveyList.AIMS_Dt_Survey_Fee_Due;
    this.sortingQIPDueDate = surveyList.AIMS_Dt_QIP_Due;
    this.qipReceivedDate = surveyList.AIMS_QIP_Recvd_Dt;
    this.qipNeedsMoreDueDate = surveyList.AIMS_QIP_Needs_More_Due_Dt;
    this.qipNeedsMoreReceivedDate = surveyList.AIMS_QIP_Needs_More_Recvd_Dt;
    this.accred_Decision = surveyList.AIMS_Accred_Decision;
  }
}
