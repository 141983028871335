import { IUpdateProfile } from '../_contracts/IUpdateProfile';

export class UpdateProfile implements IUpdateProfile {
  Title: string;
  AIMS_Credentials: string;
  AIMS_Job_Title: string;
  AIMS_Phone_Txt: string;
  Extension: string;
  AIMS_Cell_Txt: string;
  AIMS_Pref_Phone: string;
  AIMS_Pref_Pronouns: string;
  Contact_Id: string;

  constructor(updateData?: any) {
    this.Title = updateData.title;
    this.AIMS_Credentials = updateData.credentials;
    this.AIMS_Job_Title = updateData.jobTitle;
    this.AIMS_Phone_Txt = updateData.workPhone;
    this.Extension = updateData.extension;
    this.AIMS_Cell_Txt = updateData.cell;
    this.AIMS_Pref_Phone = updateData.preferredPhone;
    this.AIMS_Pref_Pronouns = updateData.preferredPronoun ? updateData.preferredPronoun : '';
    this.Contact_Id = '';
  }
}
