import { Utils } from 'src/app/shared/_models/common/Date-Formatter';
import { IAnnualReportHeader } from '../_contracts/IAnnaulReportHeader';

export class AnnualReportHeader implements IAnnualReportHeader {
  SurveyId: string;
  SurveyNumber: string;
  CompanyName: string;
  Status: string;
  FeeRecdDt: Date;
  AnniversaryDate: string;
  StandardsManualName: string;
  SurveyType: string;
  IsEditor: boolean;
  IsSubmitter: boolean;
  IsDelegatedAdministrator: boolean;
  CompSubmittingIntentId: string;
  DaFullName: string;

  constructor(data?: any) {
    this.SurveyId = data.AIMS_Survey_Id;
    this.SurveyNumber = data.AIMS_Survey_Number;
    this.CompanyName = data.Company_Name;
    this.Status = data.AIMS_ACQR_Status;
    this.FeeRecdDt = data.AIMS_ACQR_Fee_Recd_Dt;
    this.AnniversaryDate = Utils.formatLongDate(data.AIMS_ACQR_Anniversary_Date);
    this.SurveyType = data.AIMS_Survey_Type.toLowerCase();
    this.StandardsManualName = data.AIMS_Standards_Manual;
    this.IsEditor = data.AIMS_CC_Editor;
    this.IsSubmitter = data.AIMS_CC_Submitter;
    this.IsDelegatedAdministrator = data.AIMS_CC_DA;
    this.CompSubmittingIntentId = data.AIMS_Comp_Submttng_Intent_Id;
    this.DaFullName = data.Company_DA_Full_Name;
  }
}
