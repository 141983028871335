<nav class="cs-navbar nav-bg-dark overlay" role="navigation" 
     aria-labelledby="sideNavigation" id="sideNav" (click)="closeNav()">
  <div id="sideNavigation">
    <a class="nav-wrapper nav-user nav-item-lg nav-user-md pt-1" aria-label="profilepage"
      (click)="navigateToRoute('/profile')" [ngClass]="{ 'is-active' : currentPath.includes('/profile') }">
      <i class="user-icon" aria-hidden="true"> {{ userInfo?.ShortName | uppercase }} </i>
      <span class="nav-link profile-name">{{userInfo?.FirstName | uppercase}} {{userInfo?.LastName | uppercase}} </span>
    </a>
    <a class="nav-wrapper nav-item-lg nav-item-md" aria-label="homepage"
      (click)="navigateToRoute('/home')" [ngClass]="{ 'is-active' : currentPath.includes('/home') }">
      <i class="fa fa-home" aria-hidden="true"></i>
      <span class="nav-link">{{ 'SIDENAV.HOME' | translate }}</span>
    </a>
    <a class="nav-wrapper nav-item-lg nav-item-md" aria-label="companypage"
    (click)="navigateToRoute('/company')" [ngClass]="{ 'is-active' : currentPath.includes('/company') }">
      <i class="fa fa-users" aria-hidden="true"></i>
      <span class="nav-link">{{ 'SIDENAV.COMPANY' | translate }}</span>
    </a>
    <a class="nav-wrapper nav-item-lg nav-item-md" aria-label="applicationpage"
    (click)="navigateToRoute('/application')" [ngClass]="{ 'is-active' : currentPath.includes('/application') }">
      <i class="fa fa-file-alt" aria-hidden="true"></i>
      <span class="nav-link">{{ 'SIDENAV.APPLICATIONS' | translate }} </span>
    </a>
    <a class="nav-wrapper nav-item-lg nav-item-md"  aria-label="surveypage"
    (click)="navigateToRoute('/survey')" [ngClass]="{ 'is-active' : currentPath.includes('/survey') }">
      <i class="fa fa-folder" aria-hidden="true"></i>
      <span class="nav-link">{{ 'SIDENAV.SURVEYS' | translate }} </span>
    </a>
    <a class="nav-wrapper nav-item-lg nav-item-md" aria-label="resourcespage"
    (click)="navigateToRoute('/resources')" [ngClass]="{ 'is-active' : currentPath.includes('/resources') }">
      <i class="fa fa-question-circle" aria-hidden="true"></i>
      <span class="nav-link">{{ 'SIDENAV.RESOURCES' | translate }} </span>
    </a>
  </div>
</nav>