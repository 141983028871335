<div class="carf">
  <section class="news-section">
    <div class="row no-gutters carf-header">
      <div class="h-title" aria-label="WHAT'S NEW">WHAT'S NEW</div>
      <img src="../../../../assets/images/CARF_International_logo_CC.png" alt="carflogo" />
    </div>
    <div class="row no-gutters carf-news d-flex">
      <div *ngIf="!dataReceived && !showOrHideLoader">
        No News Posts
      </div>
      <div *ngFor="let news of dataReceived">
        <a [attr.href]="news.url" target="_blank">
          <ng-container>
            <div class="row no-gutters">
              <i class="col-1 fa fa-newspaper" aria-hidden="true"></i>
              <article class="col-11">
                <div class="title pl-md-2" [innerHTML]="news.contentTitle">
                  <span *ngIf="news.liveDate">({{ news.liveDate | date: 'longDate' }})</span>
                </div>

                <p class="news-para pl-md-2" [innerHTML]="news.teaser"></p>
              </article>
            </div>
          </ng-container>
        </a>
      </div>
      <div
        class="h-100 d-flex justify-content-center align-items-center ml"
        *ngIf="showOrHideLoader"
      >
        <app-loader></app-loader>
      </div>
      <div class="col-12 pl-2" *ngIf="dataReceived && !showOrHideLoader">
        <a role="link" class="readMorelink" href=" http://carf.org/news" target="_blank">
          READ MORE NEWS &nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </section>
  <section class="my-lg-4 d-sm-none d-lg-block">
    <app-quick-links
      [cardData]="storeCardData"
      (click)="navigateCard(storeCardData)"
      *ngIf="!quickLinksData"
    >
    </app-quick-links>
    <app-quick-links
      [cardData]="carfStandards"
      (click)="navigateCard(carfStandards)"
      *ngIf="quickLinksData"
    >
    </app-quick-links>
  </section>
  <section class="d-sm-none d-lg-block">
    <app-quick-links [cardData]="eventCardData" (click)="navigateCard(eventCardData)">
    </app-quick-links>
  </section>
</div>
