export class Address {
  Line1: string = '';
  Line2: string = '';
  Line3: string = '';
  Line4: string = '';

  constructor(data?: any) {
    if (data) {
      Object.assign(data);
    }
  }
}
