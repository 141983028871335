export class SubmitForm {
  ApplicationId: string;
  Feecalculation: number;
  ApprovedToprod: boolean;
  FullyPaidDT: string;

  constructor(data?: any) {
    data = JSON.parse(data);
    this.ApplicationId = data.AIMS_Intent_Id;
    this.Feecalculation = data.AIMS_Intent_Fee_Calculation;
    this.ApprovedToprod = data.AIMS_Intent_Apprvd_To_Procd;
    this.FullyPaidDT = data.AIMS_Intent_Fee_Fully_Paid_Dt;
  }
}
