import { Component, Input, OnInit } from '@angular/core';
import { Loader } from '../../_models/Loader';
import { LoaderService } from '../../_services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @Input() public id: string = 'global';
  @Input() size: any = '4';
  @Input() color: any = '#8ec0ea';
  public show: boolean;

  constructor(private loaderService: LoaderService) {}

  public ngOnInit(): void {
    this.loaderService.loaderStatus$.subscribe((response: Loader) => {
      this.show = this.id === response.id && response.status;
    });
  }
}
