import { IApplicationList } from '../_contracts/IApplicationList';

export class ApplicationList implements IApplicationList {
  applicationId: string;
  applicationNumber: string;
  sortingNumber: number;
  companyName: string;
  city: string;
  state: string;
  descriptor: string;
  dueDate: string;
  sortDueDate: Date;
  AIMS_Intent_Status: string;
  AIMS_App_Progress: string;
  appFeeDue: Date;
  applicationAcknowledgmentDate: Date;
  applicationFeeFullyPaidDate: Date;
  applicationApprovedToProceed: boolean;
  applicationAsSubmittedId: string;

  constructor(applicationList: any) {
    this.applicationId = applicationList.AIMS_Intent_Id;
    this.applicationNumber = applicationList.AIMS_Intent_Number;
    this.sortingNumber = parseInt(this.applicationNumber, 10);
    this.companyName = applicationList.AIMS_Intent_Org_Unit_Name;
    this.city = applicationList.AIMS_Intent_Org_City;
    this.state = applicationList.AIMS_Intent_Org_State;
    this.descriptor = applicationList.Rn_Descriptor;
    this.dueDate = this.convertDate(applicationList.AIMS_Application_Due_Dt);
    this.sortDueDate = applicationList.AIMS_Application_Due_Dt;
    this.AIMS_Intent_Status = applicationList.AIMS_Intent_Status;
    this.AIMS_App_Progress = applicationList.AIMS_App_Progress
      ? applicationList.AIMS_App_Progress
      : 0;
    this.appFeeDue = applicationList.AIMS_Application_Due_Dt;
    this.applicationAcknowledgmentDate = applicationList?.AIMS_Intent_Acknlgmnt_Dt
      ? new Date(applicationList.AIMS_Intent_Acknlgmnt_Dt)
      : null;
    this.applicationFeeFullyPaidDate = applicationList?.AIMS_Intent_Fee_Fully_Paid_Dt
      ? new Date(applicationList.AIMS_Intent_Fee_Fully_Paid_Dt)
      : null;
    this.applicationApprovedToProceed = applicationList?.AIMS_Intent_Apprvd_To_Procd;
    this.applicationAsSubmittedId = applicationList?.AIMS_Intent_As_Submitted_Id;
  }

  convertDate(dueDate) {
    if (typeof dueDate !== 'undefined' && dueDate) {
      const date = new Date(dueDate);
      return (
        date.toLocaleString('en-us', { month: 'long' }) +
        ' ' +
        date.getDate() +
        ', ' +
        date.getFullYear()
      );
    }
    return '';
  }
}
