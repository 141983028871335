<div id="rootModal" class="modal fade" tabindex="-1" role="dialog" [class.show]="toggleClass"
    aria-labelledby="exampleModalLabel" aria-hidden="true" *ngIf="visible">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only" id="loading">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>