import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/core/_services/login.service';
import { robohelp } from '../../_constants/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  tokens: any;
  robohelpURL = robohelp.url;
  constructor(public router: Router, private loginService: LoginService) {
    this.tokens = sessionStorage?.length;
  }

  openNav() {
    document.getElementById('sideNav').style.width = '290px';
  }
  logout() {
    this.loginService.logout();
  }
}
