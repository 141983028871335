import { INews } from '../contracts/application-list-interface';

export class NewsList implements INews {
  folder_id: string;
  content_title: string;
  content_html: string;
  urlaliasnm: string;
  go_live: string;
  content_teaser: string;
  folderId: string;
  contentTitle: string;
  url: string;
  liveDate: string;
  contentData: string;
  teaser: string;

  constructor(newsData: any) {
    this.folderId = newsData.folder_id;
    this.contentTitle = newsData.content_title;
    this.url = newsData.URL;
    this.liveDate = newsData.go_live;
    this.teaser = newsData.content_teaser;
  }
}
