import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { UserDetails } from 'src/app/core/_models/User';
import { attachmentsTableColumns, FileExtensions } from 'src/app/shared/_constants/constants';
import {
  ApplicationItem,
  AttachmentItem,
  UpdateOcf,
} from 'src/app/shared/_models/AttachmentsUpload';
import { SharedService } from 'src/app/shared/_services/shared.service';
import { SurveyDetails } from 'src/app/survey/_models/SurveyDetails';
import { Utils } from '../../_models/common/Date-Formatter';
import { CompanyList } from '../../_models/CompanyList';

@Component({
  selector: 'app-attachment-upload',
  templateUrl: './attachment-upload.component.html',
  styleUrls: ['./attachment-upload.component.scss'],
})
export class AttachmentUploadComponent implements OnInit, OnChanges {
  @ViewChild('vcClose', { static: true }) saveBtn: any;
  showOrHideLoader: boolean = false;
  @Input() userInfo: UserDetails;
  @Input() isFormEditable: boolean = true;
  attachmentItems: ApplicationItem;
  editUploadForm: FormGroup;
  companyName: string;
  @Input() checkListID: string = '';
  @Input() isApplicationItems: boolean = false;
  @Input() surveyDetails: SurveyDetails;
  @Output() updateProgress = new EventEmitter<any>();
  tableDataColumns = attachmentsTableColumns;
  companyID: string;
  submitted = false;
  ocfTableData: Array<AttachmentItem> = [];
  isDirty = false;
  dropdownCompList: CompanyList[] = [];
  errorMsg = {
    duplicateFile: false,
    emptyFile: false,
    exceedFileLimit: false,
    invalidFileName: false,
    invalidFileType: false,
    invalidFileNameLength: false,
  };
  showHideButton: boolean = false;
  fileExtensions = FileExtensions;
  applicationId: string;
  subscriptions: Subscription[] = [];
  ocfModalForm: FormGroup;
  showAttachmentDetails: boolean = false;
  disableDropdown: boolean = false;
  isFileUploaded: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.showHideButton = true;
    if (!this.isApplicationItems) {
      this.getCompanyList();
    }
    this.createEditContactForm();
    if (this.isApplicationItems) {
      this.getAttachmentData(this.checkListID);
    }
  }

  ngOnChanges() {
    this.createEditContactForm();
  }
  changeCompanyData() {
    const data = this.editUploadForm.get('CompanyList').value;
    this.companyID = data.companyId;
    this.companyName = data.companyName;
    this.disableDropdown = true;
    this.showAttachmentDetails = true;
    this.isDirty = false;
  }

  createEditContactForm() {
    this.editUploadForm = this.formBuilder.group({
      checkListId: [this.attachmentItems?.checkListId],
      dateReceived: [this.attachmentItems?.dateReceived],
      format: [this.attachmentItems?.format],
      item: [this.attachmentItems?.item],
      attachmentList: [this.attachmentItems?.attachmentList],
      CompanyList: ['', Validators.required],
    });
    this.editUploadForm.addControl(
      'required',
      new FormControl({ value: this.attachmentItems?.required, disabled: true }),
    );
    this.editUploadForm.valueChanges.subscribe(() => {
      if (this.editUploadForm.dirty) this.isDirty = true;
    });
  }

  getCompanyList() {
    this.showOrHideLoader = true;
    this.subscriptions.push(
      this.sharedService.getCompanyList(this.userInfo.ContactId).subscribe((res: CompanyList[]) => {
        this.showOrHideLoader = false;
        this.dropdownCompList = res;
      }),
    );
  }

  getAttachmentData(checkListID) {
    this.showOrHideLoader = true;
    this.subscriptions.push(
      this.sharedService.getAttachmentItems(checkListID).subscribe((res: any) => {
        this.showAttachmentDetails = true;
        this.attachmentItems = res;
        this.editUploadForm.patchValue(this.attachmentItems);
        if (!this.isFormEditable) {
          this.editUploadForm.disable();
        }

        const date = new Date(
          new Date(this.surveyDetails?.SurveyStartDate).setDate(
            new Date(this.surveyDetails?.SurveyStartDate).getDate() - 15,
          ),
        );
        if (date && new Date() > date && this.surveyDetails?.SurveyStartDate)
          this.showHideButton = false;

        this.showOrHideLoader = false;
      }),
    );
  }

  get f() {
    return this.editUploadForm.controls;
  }

  deleteRow(item, event, index) {
    event.stopPropagation();
    if (this.isApplicationItems) {
      this.showOrHideLoader = true;
      this.sharedService.deleteApplicationItem(item).subscribe(() => {
        this.isFileUploaded = true;
        this.getAttachmentData(this.checkListID);
      });
    } else {
      this.showOrHideLoader = true;
      this.isDirty = false;
      const tempArray: Array<AttachmentItem> = [];
      tempArray.push(item);
      this.sharedService.deleteOcf(tempArray, this.companyID).subscribe(() => {
        this.ocfTableData.splice(index, 1);
        this.showOrHideLoader = false;
        if (this.ocfTableData.length < 1) {
          this.isDirty = false;
        } else {
          this.isDirty = true;
        }
      });
    }
  }

  onRowClick(item) {
    if (this.isApplicationItems) {
      this.sharedService.downloadApplicationItem(item).subscribe((res) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blob: any = new Blob([res.body], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = item.documentURL;
        a.click();
        window.URL.revokeObjectURL(url);
      });
    }
  }

  onSubmit() {
    this.submitted = true;
    this.isDirty = false;
    this.showOrHideLoader = true;
    if (this.editUploadForm.invalid) {
      // eslint-disable-next-line no-useless-return
      return;
    }
    const updateObj = new UpdateOcf(this.ocfTableData, this.companyName, this.companyID);
    this.sharedService.addOcf(updateObj).subscribe(
      (res) => {
        if (res) {
          this.modalService.dismissAll();
        }
      },
      () => this.modalService.dismissAll(),
    );
  }

  onFileSelect(event: any) {
    this.errorMsg = {
      duplicateFile: false,
      emptyFile: false,
      exceedFileLimit: false,
      invalidFileName: false,
      invalidFileType: false,
      invalidFileNameLength: false,
    };
    const extensions: string[] = event[0].name.split('.');
    if (extensions.length > 2) this.errorMsg.invalidFileName = true;
    this.attachmentItems?.attachmentList.forEach((item) => {
      if (item.documentURL === event[0].name) {
        this.errorMsg.duplicateFile = true;
      }
    });
    if (event[0].name.length > 65) this.errorMsg.invalidFileNameLength = true;
    this.ocfTableData?.forEach((element) => {
      if (element.documentURL === event[0].name) {
        this.errorMsg.duplicateFile = true;
      }
    });
    if (event[0].size <= 0) {
      this.errorMsg.emptyFile = true;
    }
    if (event[0].size > 10000000) this.errorMsg.exceedFileLimit = true;
    if (event[0].name.includes('#') || event[0].name.includes("'"))
      this.errorMsg.invalidFileName = true;
    if (!this.fileExtensions.includes('.' + event[0].name.split('.').pop()))
      this.errorMsg.invalidFileType = true;

    if (
      !(
        this.errorMsg.duplicateFile ||
        this.errorMsg.emptyFile ||
        this.errorMsg.exceedFileLimit ||
        this.errorMsg.invalidFileName ||
        this.errorMsg.invalidFileType ||
        this.errorMsg.invalidFileNameLength
      )
    ) {
      this.showOrHideLoader = true;
      if (this.isApplicationItems) {
        this.sharedService.uploadApplicationItem(event[0], this.checkListID).subscribe(
          (eve) => {
            this.showOrHideLoader = false;
            if (eve) {
              this.isFileUploaded = true;
              this.getAttachmentData(this.checkListID);
            }
          },
          () => this.saveBtn.nativeElement.click(),
        );
      } else {
        this.showOrHideLoader = true;
        this.isDirty = false;
        this.sharedService.uploadOcf(event[0], this.companyID).subscribe(
          (res) => {
            this.showOrHideLoader = false;
            if (res && res.toString() !== 'Duplicate File') {
              const units = ['bytes', 'KB', 'MB'];
              let l = 0;
              let n = parseInt(event[0].size, 10) || 0;
              while (n >= 1024) {
                l += 1;
                n /= 1024;
              }
              const sizeConvert = n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
              const temp = new AttachmentItem();
              temp.attID = res.toString();
              temp.attType = '.' + event[0].name.split('.').pop();
              temp.documentURL = event[0].name;
              temp.attAddedDt = Utils.formatLongDate(event[0].lastModified);
              temp.attSize = sizeConvert;
              this.ocfTableData.push(temp);
              this.showOrHideLoader = false;
              this.isDirty = true;
            } else {
              this.errorMsg.duplicateFile = true;
            }
          },
          () => this.saveBtn.nativeElement.click(),
        );
      }
    }
  }

  onModelClose() {
    this.submitted = false;
    this.editUploadForm?.reset();
    if (this.isApplicationItems) {
      if (this.editUploadForm.dirty && !this.submitted) {
        this.editUploadForm.reset();
        this.editUploadForm.patchValue(this.attachmentItems);
      }
      this.updateProgress.emit(this.isFileUploaded);
      this.modalService.dismissAll();
    } else {
      this.showOrHideLoader = true;
      this.sharedService.deleteOcf(this.ocfTableData, this.companyID).subscribe(
        () => {
          this.modalService.dismissAll();
        },
        () => this.modalService.dismissAll(),
      );
    }
  }
}
