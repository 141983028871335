// eslint-disable-next-line max-classes-per-file
import { Utils } from 'src/app/shared/_models/common/Date-Formatter';
import { ISurveyPayments } from '../_contracts/ISurveyPayments';

export class SurveyFeeHistory {
  InvoiceNumber: string;
  AmountPaid: number;
  PaymentDate: string;
  sortPaymentDate: Date;

  constructor(data?: any) {
    this.InvoiceNumber = data?.AIMS_Invoice_Number;
    this.AmountPaid = data?.AIMS_Amount_Paid;
    this.PaymentDate = Utils.formatLongDate(data.AIMS_Payment_Date);
    this.sortPaymentDate = data.AIMS_Payment_Date;
  }
}

export class LineItemInfoData {
  ItemId: string;
  Name: string;
  Description: string;
  Quantity: number;
  UnitPrice: number;
  Taxable: boolean;

  constructor(data?: any) {
    this.ItemId = data?.ItemId;
    this.Name = data?.Name;
    this.Description = data?.Description;
    this.Quantity = data?.Quantity;
    this.UnitPrice = data?.UnitPrice;
    this.Taxable = data?.Taxable;
  }
}

export class SurveyPayments implements ISurveyPayments {
  SurveyId: string;
  SurveyType: string;
  SurveyNumber: string;
  InvoiceNumber: string;
  OrderDescription: string;
  BalanceDue: number;
  BaseAmout: number;
  TaxAmount: number;
  TotalAmount: number;
  LineItems: LineItemInfoData[] = [];
  DivisionCode: string;
  AsDept: string;
  Company_Country: string;
  Currency: string;
  TaxRate: number;
  StateTax: number;
  OfficeName: string;
  CompanyNumber: string;
  TaxAddress1: string;
  TaxCity: string;
  TaxState: string;
  TaxCountry: string;
  TaxZip: string;
  SurveyFeeHistoryData: SurveyFeeHistory[] = [];

  constructor(res?: any) {
    if (res) {
      this.SurveyId = res?.Survey_Id;
      this.SurveyType = res?.AIMS_Survey_Type;
      this.SurveyNumber = res?.AIMS_Survey_Number;
      this.InvoiceNumber = res?.AIMS_Invoice_Number;
      this.OrderDescription = res?.OrderDescription;
      this.BalanceDue = res?.BalanceDue;
      this.BaseAmout = res?.BaseAmount;
      this.TaxAmount = res?.TaxAmount;
      this.TotalAmount = res?.TotalAmount;
      this.DivisionCode = res?.Division_Code;
      this.AsDept = res?.AS_Dept;
      this.Company_Country = res?.Company_Country;
      this.Currency = res?.Currency;
      this.TaxRate = res?.Tax_Rate;
      this.StateTax = res?.State_Tax;
      this.OfficeName = res?.Office_Name;
      this.CompanyNumber = res?.Company_Number;
      this.TaxAddress1 = res?.Tax_Address_1;
      this.TaxCity = res?.Tax_City;
      this.TaxState = res?.Tax_State;
      this.TaxCountry = res?.Tax_Country;
      this.TaxZip = res?.Tax_Zip;
      res?.AIMS_Survey_Fee_History?.forEach((element) =>
        this.SurveyFeeHistoryData.push(new SurveyFeeHistory(element)),
      );
      res?.LineItems?.forEach((element) => this.LineItems.push(new LineItemInfoData(element)));
    }
  }
}
