import { IPaymentData } from '../_contracts/IPaymentData';

export class PaymentData implements IPaymentData {
  Intent_Id: string;
  ApplicationNumber: string;
  SurveyType: string;
  BaseIntentFeeNull: boolean;
  FeeExempt: boolean;
  PrelimnaryFollowUp: boolean;
  StandardsManual: string;
  PrimaryStandardsManual: string;
  PrimaryStandardsManualRnDesc: string;
  FinancialArrangements: string;
  FinancialArrangementsRnDesc: string;
  DivisionCode: string;
  ASDept: string;
  BaseAmount: number;
  TaxAmount: number;
  TotalAmount: number;
  Company_Country: string;
  Currency: string;
  TaxRate: number;
  State_Tax: number;
  Office_Name: string;
  Company_Number: string;
  TaxAddress1: string;
  TaxCity: string;
  TaxState: string;
  TaxCountry: string;
  TaxZip: string;
  LineItems: [];
  OrderDescription: string;

  constructor(data: any) {
    data = JSON.parse(data);
    this.Intent_Id = data.AIMS_Intent_Id;
    this.SurveyType = data.AIMS_Survey_Type;
    this.ApplicationNumber = data.AIMS_Intent_Number;
    this.BaseIntentFeeNull = data.AIMS_Base_Intent_Fee_Is_Null;
    this.FeeExempt = data.FeeExempt;
    this.PrelimnaryFollowUp = data.AIMS_Preliminary_Follow_Up;
    this.StandardsManual = data.AIMS_Standards_Manual;
    this.PrimaryStandardsManual = data.AIMS_Primary_Standards_Manual;
    this.PrimaryStandardsManualRnDesc = data.AIMS_Primary_Standards_Manual_Rn_Descriptor;
    this.FinancialArrangements = data.AIMS_Financial_Arrangement;
    this.FinancialArrangementsRnDesc = data.AIMS_Financial_Arrangement_Rn_Descriptor;
    this.DivisionCode = data.Division_Code;
    this.ASDept = data.AS_Dept;
    this.BaseAmount = data.BaseAmount;
    this.TaxAmount = data.TaxAmount;
    this.TotalAmount = data.TotalAmount;
    this.Company_Country = data.Company_Country;
    this.Currency = data.Currency;
    this.TaxRate = data.Tax_Rate;
    this.State_Tax = data.State_Tax;
    this.Office_Name = data.Office_Name;
    this.Company_Number = data.Company_Number;
    this.TaxAddress1 = data.Tax_Address_1;
    this.TaxCity = data.Tax_City;
    this.TaxState = data.Tax_State;
    this.TaxCountry = data.Tax_Country;
    this.TaxZip = data.Tax_Zip;
    this.LineItems = data.LineItemInfo;
    this.OrderDescription = data.OrderDescription;
  }
}
