import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-button-bar',
  templateUrl: './button-bar.component.html',
  styleUrls: ['./button-bar.component.scss'],
})
export class ButtonBarComponent {
  @Input() buttonBar: any;
  @Input() modalId: any;
  @Input() button1Disabled: boolean = false;
  @Input() button2Disabled: boolean = false;
  @Input() button3Disabled: boolean = false;
  @Output() button1Action = new EventEmitter<any>();
  @Output() button2Action = new EventEmitter<any>();
  @Output() button3Action = new EventEmitter<any>();

  button1Click(event) {
    this.button1Action.emit(event);
  }
  button2Click() {
    this.button2Action.emit();
  }
  button3Click(event) {
    this.button3Action.emit(event);
  }
}
