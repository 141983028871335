/* eslint-disable no-underscore-dangle */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { LoginService } from '../_services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  showOrHideLoader = false;

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public router: Router,
    public loginService: LoginService,
  ) {}

  ngOnInit(): void {
    this.showOrHideLoader = false;
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS,
        ),
      )
      .subscribe({
        next: (result: any) => {
          if (result?.payload?.account) {
            this.authService.instance.setActiveAccount(result.payload.account);
            this.showOrHideLoader = true;
            this.router.navigate(['/home']);
          }
        },
        // eslint-disable-next-line no-console
        error: (error) => console.log(error),
      });
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$),
      )
      .subscribe(() => {
        if (this.authService.instance.getAllAccounts().length > 0) {
          this.showOrHideLoader = true;
          setTimeout(() => {
            this.router.navigate(['/home']);
          }, 100);
        }
      });
  }

  login() {
    this.loginService.login();
  }

  ngOnDestroy(): void {
    const value = undefined;
    this._destroying$.next(value);
    this._destroying$.complete();
  }
}
