import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserDetails } from 'src/app/core/_models/User';
import { NewsList } from '../../models/news-list';
import { DashboardService } from '../../_services/dashboard.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit, OnDestroy {
  mockNews: any;
  storeCardData: any;
  eventCardData: any;
  subscriptions: Subscription[] = [];
  dataReceived: NewsList[];
  @Input() quickTemplate: TemplateRef<any>;
  carfStandards: any;
  quickLinksData = false;
  showOrHideLoader = true;
  userInfo: UserDetails;

  constructor(
    private route: ActivatedRoute,
    private newsData: DashboardService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.userInfo = this.route.snapshot.data.userDetail;
    this.quickLinksData = this.userInfo.AccessToPublications;
    this.getCardData();
    this.getNewsData();
  }

  getNewsData() {
    this.subscriptions.push(
      this.newsData.getNewsData().subscribe(
        (res: NewsList[]) => {
          this.showOrHideLoader = false;
          this.dataReceived = res;
        },
        (err) => {
          this.showOrHideLoader = false;
          this.dataReceived = null;
          console.log(err);
        },
      ),
    );
  }

  // This component and the component in dashboard.component.ts need to get updated when the
  // cards require changes. This one is the default display mode, but there is duplicated code
  // in that file for displaying scaled versions of the tiles.
  getCardData() {
    this.storeCardData = {
      imageUrl: '../assets/images/CC_QL_CARFStandards.jpg',
      imageAltTag: 'CARF Standards',
      cardCaption: 'Keep current with CARF Standards',
      cardLink: 'GO TO CARF BOOKSTORE',
      url: 'http://bookstore.carf.org/miva/merchant.mvc?Screen=SFNT&Store_Code=CB',
    };
    this.eventCardData = {
      imageUrl: '../assets/images/CC_QL_EducationalOpportunities.jpg',
      imageAltTag: 'Educational Opportunities',
      cardCaption: 'CARF Educational Opportunities',
      cardLink: 'GO TO OPPORTUNITIES',
    };
    this.carfStandards = {
      imageUrl: '../assets/images/CC_QL_StandardsManuals_Workbooks.jpg',
      imageAltTag: 'enter button',
      cardCaption: 'Standards Manuals and Workbooks',
      cardLink: 'GET STANDARDS',
    };
  }

  navigateCard(cardData) {
    if (cardData.cardLink === 'GET STANDARDS') {
      this.router.navigate(['/resources/stdManualNWorkbooks']);
    } else if (cardData.cardLink === 'GO TO OPPORTUNITIES') {
      this.router.navigate(['/resources/educationalOpportunities']);
    } else {
      window.open(cardData.url, '_blank');
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
