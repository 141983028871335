<div class="container-fluid padding-layout error-container">
    <div class="row">
        <div class="col-md-12 pb-3 error-msgs">
            <h1 class="mb-4">{{ 'ERROR.TITLE' | translate}}</h1>
            <p>
                {{'ERROR.ERR_MSG1' | translate}}
            </p>
            <p>
                {{'ERROR.ERR_MSG2' | translate}}
            </p>
            <p>
                {{'ERROR.ERR_MSG3' | translate}} <a href="mailto:asc@carf.org">asc@carf.org</a> {{'ERROR.ERR_MSG4' |
                translate}}
            </p>
        </div>
    </div>
</div>