<div class="container pay-survey-fee-container">
  <div [hidden]="openPaymentForm">
    <div class="modal-header">
      <h1 aria-label="survey fee payment" id="PayFeeHeader" class="modal-title">
        Survey Fee Payment
      </h1>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="OnCancel()"
      >
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>
    <div *ngIf="showOrHideLoader">
      <app-loader></app-loader>
    </div>
    <div class="modal-body label" *ngIf="!showOrHideLoader">
      <div>
        <p>
          Your <span>{{ surveyTypeRecord }}</span> Fee for Survey #<span>{{ responseReceived?.SurveyNumber }}</span> is <span
            >{{ responseReceived?.TotalAmount | currency }} {{ currencyValue }}</span
          >
        </p>
      </div>
      <div>
        <p>
          Our records indicate the <span>{{ surveyTypeRecord }}</span> Fee balance due is {{
            responseReceived?.BalanceDue | currency
          }}
          {{ currencyValue
          }}<span>. Payments made today may not be reflected in the balance due.</span>
        </p>
      </div>
      <div *ngIf="responseReceived?.SurveyFeeHistoryData.length > 0" class="row">
        <div class="col-12">
          <p id="surveyTableCaption" class="tableHeader col-12">
            <span>{{ surveyTypeRecord }}</span> Fee Payment History
          </p>
        </div>
      </div>
      <div *ngIf="responseReceived?.SurveyFeeHistoryData.length > 0" class="table-responsive">
        <table class="table global-tbl" aria-describedby="tableCaption">
          <thead>
            <tr>
              <th scope="col" *ngFor="let column of surveypaymntHistoryColumns">{{ column }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let history of historyTableSummary">
              <td>
                {{history.InvoiceNumber}}
              </td>
              <td>
                {{history.AmountPaid | currency}}
              </td>
              <td>
                {{history.PaymentDate}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="row col-md-12"
        [ngClass]="responseReceived?.SurveyFeeHistoryData.length > 0 ? 'payment-dropdown' : 'pl-0'"
      >
        <div class="form-group col-md-6">
          <label for="PaymentAmount">Payment Amount</label>
          <select
            id="PaymentAmount"
            class="form-control"
            (change)="changeData($event.target.value)"
          >
            <option class="option" value="" disabled selected>- Select -</option>
            <option class="option" *ngFor="let opt of paymntAmountList" [value]="opt.value">
              {{ opt?.value }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-6" *ngIf="showEnterAmount">
          <label for="PaymntAmt">Enter Payment Amount</label>
          <input
            id="PaymntAmt"
            aria-label="EnterAmount"
            class="form-control"
            type="number"
            [(ngModel)]="paymentAmountData"
            [ngClass]="errorMessage ? 'is-invalid' : ''"
          />
          <span *ngIf="errorMessage" class="form-errors invalid-feedback"
            >Value must be numeric and greater than zero.</span
          >
        </div>
        <span *ngIf="errorMessage" class="form-errors errorMessageStyle pl-3"><strong>Cannot continue.</strong></span>
      </div>
    </div>
    <div class="modal-footer">
      <div>
        <button
          class="btn btn-info mr-3"
          type="button"
          data-backdrop="static"
          (click)="onContinue()"
        >
          <i class="fa fa-arrow-alt-circle-right mr-2" aria-hidden="true"></i>
          {{ 'COMMON.CONTINUE' | translate }}
        </button>
        <button class="btn btn-info" data-dismiss="modal" aria-label="Close" (click)="OnCancel()">
          <i class="fa fa-times-circle plus mr-2" aria-hidden="true"></i>
          {{ 'COMMON.CANCEL' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="col-md overflow-auto payment-modal-padding">
    <div [hidden]="!openPaymentForm" id="iframe_holder" class="center-block" style="width: 100%">
      <button
        type="button"
        class="close-payment"
        data-dismiss="modal"
        aria-label="Close"
        (click)="OnCancel()"
      >
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
      <div *ngIf="showOrHideLoader">
        <app-loader></app-loader>
      </div>
      <iframe
        id="load_payment"
        class="embed-responsive-item frame-style frame-scroll"
        name="load_payment"
        width="100%"
        height="935px"
        title="Pay Survey Fee"
      >
      </iframe>
    </div>
    <form
      id="send_hptoken"
      name="send_token"
      [action]="IFRAME_URL"
      method="post"
      target="load_payment"
      #theForm="ngForm"
    >
      <input type="hidden" name="token" [value]="tokenReceived" />
    </form>
  </div>
</div>
