import { IApplicationList } from '../contracts/application-list-interface';

export class ApplicationList implements IApplicationList {
  AIMS_Intent_Id: string;
  AIMS_Intent_Number: string;
  applicationNumber: number;
  applicationAsSubmittedId: string;
  AIMS_Intent_Org_Unit_Name: string;
  AIMS_Intent_Org_City: string;
  AIMS_Intent_Org_State: string;
  AIMS_Intent_Status: string;
  AIMS_App_Progress: string;
  constructor(appData: any) {
    this.AIMS_Intent_Id = appData.AIMS_Intent_Id;
    this.AIMS_Intent_Number = appData.AIMS_Intent_Number;
    this.applicationNumber = parseInt(this.AIMS_Intent_Number, 10);
    this.AIMS_Intent_Org_Unit_Name = appData.AIMS_Intent_Org_Unit_Name;
    this.AIMS_Intent_Org_City = appData.AIMS_Intent_Org_City;
    this.AIMS_Intent_Org_State = appData.AIMS_Intent_Org_State;
    this.AIMS_Intent_Status = appData.AIMS_Intent_Status;
    this.AIMS_App_Progress = appData.AIMS_App_Progress ? appData.AIMS_App_Progress : 0;
    this.applicationAsSubmittedId = appData.AIMS_Intent_As_Submitted_Id;
  }
}
