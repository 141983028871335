export const SurveyDetailsTableConfig: any = {
  columns: [
    {
      title: 'SURVEY.SURINFO.SURCONTACT.SURVEYDETAILCONFIG.CONTACT',
      dataProperty: 'contact',
      sortable: false,
      filterable: false,
      direction: '',
    },
    {
      title: 'SURVEY.SURINFO.SURCONTACT.SURVEYDETAILCONFIG.ACCREDITATION',
      dataProperty: 'accreditation',
      sortable: false,
      filterable: false,
      direction: '',
    },
    {
      title: 'SURVEY.SURINFO.SURCONTACT.SURVEYDETAILCONFIG.AFTERHOURS',
      dataProperty: 'afterHrs',
      sortable: false,
      filterable: false,
      direction: '',
    },
    {
      title: 'SURVEY.SURINFO.SURCONTACT.SURVEYDETAILCONFIG.TRALOD',
      dataProperty: 'travelLod',
      sortable: false,
      filterable: false,
      direction: '',
    },
    {
      title: 'SURVEY.SURINFO.SURCONTACT.SURVEYDETAILCONFIG.FINANCE',
      dataProperty: 'finance',
      sortable: false,
      filterable: false,
      direction: '',
    },
    {
      title: 'SURVEY.SURINFO.SURCONTACT.SURVEYDETAILCONFIG.IOM',
      dataProperty: 'iom',
      sortable: false,
      filterable: false,
      direction: '',
    },
  ],
};

export const SurvetListTableConfig: any = {
  columns: [
    {
      title: 'SURVEY.SURVEYLISTCONFIG.SURNUMBER',
      dataProperty: 'surveyNumber',
      sortProperty: 'sortingNumber',
      sortable: true,
      filterable: false,
      direction: '',
      type: 'Number',
    },
    {
      title: 'SURVEY.SURVEYLISTCONFIG.COMNAME',
      dataProperty: 'companyName',
      sortable: true,
      filterable: true,
      direction: '',
    },
    {
      title: 'SURVEY.SURVEYLISTCONFIG.SURTYPE',
      dataProperty: 'surveyType',
      sortable: true,
      filterable: false,
      direction: '',
    },
    {
      title: 'SURVEY.SURVEYLISTCONFIG.PRIMANUAL',
      dataProperty: 'descriptor',
      sortable: true,
      filterable: true,
      direction: '',
    },
    {
      title: 'SURVEY.SURVEYLISTCONFIG.STATUS',
      dataProperty: 'surveyStatus',
      sortable: true,
      filterable: false,
      direction: '',
    },
    {
      title: 'SURVEY.SURVEYLISTCONFIG.SURDECISION',
      dataProperty: 'accredDecision',
      sortable: true,
      filterable: false,
      direction: '',
    },
    {
      title: 'SURVEY.SURVEYLISTCONFIG.EXPDATE',
      dataProperty: 'accredExpirationDate',
      sortProperty: 'sortingExpirationDate',
      sortable: true,
      filterable: false,
      direction: '',
      type: 'Date',
    },
  ],
};

export const MissingListTableConfig: any = {
  columns: [
    {
      title: 'SURVEY.MISSINGLISTCONFIG.SURNUMBER',
      dataProperty: 'surveyNumber',
      sortable: true,
      filterable: true,
      direction: '',
    },
    {
      title: 'SURVEY.MISSINGLISTCONFIG.COMNAME',
      dataProperty: 'companyName',
      sortable: true,
      filterable: true,
      direction: '',
    },
    {
      title: 'SURVEY.MISSINGLISTCONFIG.ITEM',
      dataProperty: 'item',
      sortable: true,
      filterable: true,
      direction: '',
    },
    {
      title: 'SURVEY.MISSINGLISTCONFIG.DUEDATE',
      dataProperty: 'dueDate',
      sortable: true,
      filterable: true,
      direction: '',
    },
  ],
};

export const SurveyDocumentTableConfig: any = {
  columns: [
    {
      title: 'SURVEY.SURVEYDOCUMENTCONFIG.DOCUMENT',
      dataProperty: 'AttachmentUrl',
      sortable: false,
      filterable: false,
      direction: '',
    },
    {
      title: 'SURVEY.SURVEYDOCUMENTCONFIG.DESC',
      dataProperty: 'FileDescription',
      sortable: false,
      filterable: false,
      direction: '',
    },
    {
      title: 'SURVEY.SURVEYDOCUMENTCONFIG.DATEADDED',
      dataProperty: 'DateFileAdded',
      sortable: false,
      filterable: false,
      direction: '',
    },
    {
      title: 'SURVEY.SURVEYDOCUMENTCONFIG.TYPE',
      dataProperty: 'FileType',
      sortable: false,
      filterable: false,
      direction: '',
    },
    {
      title: 'SURVEY.SURVEYDOCUMENTCONFIG.SIZE',
      dataProperty: 'FileSize',
      sortable: false,
      filterable: false,
      direction: '',
    },
  ],
};
export const certificateFormTableConfig = [
  'SURVEY.COMPLEXHEADER.ORDERADD.CERTIFICATECONFIG.ORGANIZATIONLOC',
  'SURVEY.COMPLEXHEADER.ORDERADD.CERTIFICATECONFIG.SIZE',
  'SURVEY.COMPLEXHEADER.ORDERADD.CERTIFICATECONFIG.QUANTITY',
  'SURVEY.COMPLEXHEADER.ORDERADD.CERTIFICATECONFIG.AMOUNT',
];

// static Form Data
export const certificateFormTableContent: any = [
  {
    accordianData: {
      sectionTitle: 'Singapore Anglican Community Services',
      active: false,
      stepIcon: '',
      stepInsideIcon: '',
      order: 1,
    },
    size: ['11 x 12', '11 x 13', '11 x 15', '11 x 20'],
    amount: 65.0,
  },
  {
    accordianData: {
      sectionTitle: 'Singapore Anglican Community Services',
      active: false,
      stepIcon: '',
      stepInsideIcon: '',
      order: 2,
    },
    size: ['11 x 12', '11 x 13', '11 x 15', '11 x 20'],
    amount: 55.0,
  },
  {
    accordianData: {
      sectionTitle: 'Anglican Care Centre (Simei)',
      active: false,
      stepIcon: '',
      stepInsideIcon: '',
      order: 3,
    },
    size: ['11 x 12', '11 x 13', '11 x 15', '11 x 20'],
    amount: 0,
  },
  {
    accordianData: {
      sectionTitle: 'Anglican Care Centre (Bukit Batok)',
      active: false,
      stepIcon: '',
      stepInsideIcon: '',
      order: 4,
    },
    size: ['11 x 12', '11 x 13', '11 x 15', '11 x 20'],
    amount: 0,
  },
  {
    accordianData: {
      sectionTitle: 'Anglican Care Centre (Hougang)',
      active: false,
      stepIcon: '',
      stepInsideIcon: '',
      order: 5,
    },
    size: ['11 x 12', '11 x 13', '11 x 15', '11 x 20'],
    amount: 0,
  },
  {
    accordianData: {
      sectionTitle: 'Anglican Care Centre (Pasir Ris)',
      active: false,
      stepIcon: '',
      stepInsideIcon: '',
      order: 6,
    },
    size: ['11 x 12', '11 x 13', '11 x 15', '11 x 20'],
    amount: 0,
  },
  {
    accordianData: {
      sectionTitle: 'Anglican Care Centre (Yishun)',
      active: false,
      stepIcon: '',
      stepInsideIcon: '',
      order: 7,
    },
    size: ['11 x 12', '11 x 13', '11 x 15', '11 x 20'],
    amount: 0,
  },
];

export const OtherSurveyColumns: any = [
  'Contact',
  'Alternate Contact',
  'After-Hours Contact',
  'Finance Contact',
  'List on Final Report',
];

export const CertificateOrderColumns: any = [
  'Certificate Type',
  'Order Received',
  'Order Paid',
  'Certificate Sent',
];
export const AnnualReportListTableConfig: any = {
  columns: [
    {
      title: 'SURVEY.ANNUALLIST.SURNUMBER',
      dataProperty: 'surveyNumber',
      sortable: true,
      filterable: true,
      direction: '',
      type: 'string',
    },
    {
      title: 'SURVEY.ANNUALLIST.COMNAME',
      dataProperty: 'companyName',
      sortable: true,
      filterable: true,
      direction: '',
      type: 'string',
    },
    {
      title: 'SURVEY.ANNUALLIST.DUEDATE',
      dataProperty: 'dueDate',
      sortProperty: 'sortDueDate',
      sortable: true,
      filterable: true,
      direction: '',
      type: 'Date',
    },
  ],
};

export const annualReportColumns: any = [
  'Due',
  'Received',
  'Status',
  'Conformance Letter Sent',
  'Annual Maintenance Fee Received',
  'Actions',
];

export const surveyFeePaymentHistoryCol: any = ['Invoice Number', 'Amount Paid', 'Payment Date'];

export const itemsChklistCol: any = ['Item', 'Required', 'Format', 'Date Received', 'Actions'];
