import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDetails } from 'src/app/core/_models/User';
import { UserService } from 'src/app/core/_services/user.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnChanges {
  userShortName: string;
  userInfo: UserDetails;
  currentPath: any = '/home';
  @Input() routerURL: any;
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.userService.data.subscribe((data) => {
      this.userInfo = data;
    });
    const currentUrl = window.location.href.substring(window.location.href.lastIndexOf('#') + 1);
    this.currentPath = currentUrl;
  }

  ngOnChanges() {
    this.currentPath = this.routerURL;
  }

  closeNav() {
    document.getElementById('sideNav').style.width = '0%';
  }
  navigateToRoute(routerLink: string) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([routerLink]);
    this.currentPath = routerLink;
  }
}
