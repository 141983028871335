<div class="quick-card-body">
  <figure
    [attr.aria-labelledby]="cardData.labelledby"
    class="d-flex flex-column align-items-center"
  >
    <img class="img-fluid img-lg" [src]="cardData.imageUrl" [alt]="cardData.imageAltTag" />
    <figcaption id="{{ cardData.labelledby }}">
      <div class="title">{{ cardData.cardCaption }}</div>
      <a class="link" role="link">
        {{ cardData.cardLink }}<i class="fa fa-arrow-right ml-1" aria-hidden="true"></i
      ></a>
    </figcaption>
  </figure>
</div>
