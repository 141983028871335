import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoadingModalService } from 'src/app/shared/_services/loading-modal.service';
import { LoginService } from '../_services/login.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private loadinModalSvc: LoadingModalService,
    private loginService: LoginService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(1),
      catchError((httperror: HttpErrorResponse) =>
        // Checking if it is an Authentication Error (401)
        {
          let message = '';
          this.loadinModalSvc.loadModal('hide');
          if (httperror.status === 401) {
            this.loginService.logout();
            setTimeout(() => this.loginService.login(), 500);
          }
          if (httperror.error instanceof ErrorEvent) {
            // handle client-side error
            message = `Error: ${httperror.error.message}`;
            this.router.navigate(['/errors']);
          } else if (
            httperror.url.match('/application/submit') ||
            httperror.url.match('/payment/annualreport') ||
            httperror.url === '/payment/survey' ||
            httperror.url.match('/payment/additionalcertificates') ||
            httperror.url.match('/payment/application') ||
            httperror.url.includes('/servertask') ||
            httperror.url.match('/payment/application/paymethod') ||
            httperror.url.match('/news/list')
          ) {
            // handle server-side error
            httperror.error.status = `${httperror.status}`;
          } else if (httperror.error === 'AccountExists') {
            // capture the error message and return it to the subscribe method
            return throwError(httperror.error);
          } else {
            message = `Error Status: ${httperror.status}\nMessage: ${httperror.message}`;
            this.router.navigate(['/errors']);
          }
          return throwError(message);
        },
      ),
    );
  }
}
