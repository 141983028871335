/* eslint-disable max-classes-per-file */
import * as moment from 'moment';
import { Utils } from 'src/app/shared/_models/common/Date-Formatter';

export class AttachmentItem {
  checkListId?: string;
  attID?: string;
  documentURL?: string;
  downloadURL?: string;
  attAddedDt?: string;
  attType?: string;
  attSize?: string;
  dateReceived?: string;

  constructor(res?: any) {
    if (res) {
      this.checkListId = res.AIMS_Survey_Items_Checklist_Id;
      this.attID = res.AIMS_Intent_Item_Attachment_Id;
      this.documentURL = res.AIMS_Document_URL;
      this.downloadURL = res.AIMS_Download_URL;
      this.attAddedDt = moment(res.AIMS_Intent_Item_Att_Added_Dt).format('MMMM D, YYYY');
      this.attType = res.AIMS_Intent_Item_Att_Type;
      this.attSize = res.AIMS_File_Size;
    }
  }
}
export class ApplicationItem {
  checkListId: string;
  item: string;
  required: boolean;
  format: string;
  dateReceived: string;
  attachmentList: Array<AttachmentItem>;

  constructor(res?: any) {
    if (res) {
      this.checkListId = res.AIMS_Survey_Items_Checklist_Id;
      this.item = res.AIMS_Items_Chklst_Item;
      this.required = res.AIMS_Items_Chklst_Required;
      this.format = res.AIMS_Items_Chklst_Medium;
      this.dateReceived = Utils.formatLongDate(res.AIMS_Items_Chklst_Dt_Received);
      this.attachmentList = res.attachmentList?.map((item) => new AttachmentItem(item));
    }
  }

  addNewRow(data) {
    const obj = new AttachmentItem(data);
    this.attachmentList.push(obj);
    return obj;
  }
}
export class ApplicationItemList {
  IntentID: string;
  ItemCheckList: Array<ApplicationItem>;

  constructor(data?: any) {
    this.IntentID = data.AIMS_Intent_Id;
    this.ItemCheckList = data.itemCheckList.map((item) => new ApplicationItem(item));
  }
}
export class DeleteApplicationItem {
  AIMS_Intent_Item_Attachment_Id: string;
  AIMS_Document_URL: string;
  AIMS_Survey_Items_Checklist_Id: string;

  constructor(res?: any) {
    this.AIMS_Intent_Item_Attachment_Id = res.attID;
    this.AIMS_Document_URL = res.documentURL;
    this.AIMS_Survey_Items_Checklist_Id = res.checkListId;
  }
}
export class DownloadApplicationItem {
  AIMS_Document_URL: string;
  AIMS_Survey_Items_Checklist_Id: string;

  constructor(res?: any) {
    this.AIMS_Document_URL = res.documentURL;
    this.AIMS_Survey_Items_Checklist_Id = res.checkListId;
  }
}
export class DocUrlList {
  AIMS_Company_Attachments_Id: string;
  AIMS_Document_URL: string;

  constructor(res?: AttachmentItem) {
    this.AIMS_Company_Attachments_Id = res.attID;
    this.AIMS_Document_URL = res.documentURL;
  }
}
export class DeleteOcfItem {
  DocumentURLList: Array<DocUrlList>;
  AIMS_Company_Id: string;

  constructor(res?: Array<AttachmentItem>, companyID?: string) {
    this.DocumentURLList = res?.map((item) => new DocUrlList(item));
    this.AIMS_Company_Id = companyID;
  }
}
export class UpdateOcf {
  AIMS_Company_Id: string;
  DocumentURLList: Array<DocUrlList>;
  Rn_Descriptor: string;

  constructor(res?: Array<AttachmentItem>, companyName?: string, companyId?: string) {
    this.DocumentURLList = res?.map((item) => new DocUrlList(item));
    this.Rn_Descriptor = companyName;
    this.AIMS_Company_Id = companyId;
  }
}
