<div class="contain">
  <div class="row-sm-12">
    <div class="grid-view">
      <app-grid
        [primaryTableConfig]="applicationTableConfig"
        [primaryTableData]="applicationTableContent"
        [header]="'HOME.APPLICATIONSURVEY'"
        [isFilterable]="true"
        [secondaryHeader]="subHeader"
        [isIntegratedTable]="true"
        [secondaryTableConfig]="surveyTableConfig"
        [secondaryTableData]="surveyTableContent"
        [placeHolder]="'Search by application or survey number'"
        [sliceCount]="5"
        [showOrHideLoader]="showOrHideLoader"
        [showOrHideSecLoader]="showOrHideSecLoader"
        (onRowClicked)="navigatetoApplication($event)"
        (viewAll) = "navigateToList($event)"
      ></app-grid>
    </div>
  </div>
</div>
