<div class="container-fluid">
  <div class="row main-row">
    <div class="col-lg-12 bg-clr pd-lg-l no-display-on-print">
      <h1 class="user-name" >{{ 'HOME.HOMETITLE' | translate}} {{userInfo?.FirstName}}</h1>
    </div>
    <div class="col-lg-9 col-md-12 order-md-1 order-lg-1 pb-4 pt-3 bg-clr pd-lg-ai-l pd-md-ai-r">
      <div class="row action-items">
        <div class="col-md-4 p-2" *ngFor="let key of dataReceived | slice: 0:8; let i = index">
          <app-action-items [data]="key"></app-action-items>
        </div>
      </div>
      <div class="h-100 d-flex justify-content-center align-items-center no-display-on-print" *ngIf="showOrHideLoader">
          <app-loader></app-loader>
      </div>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-4 order-md-3 order-lg-2 py-4 c-bg-clr pd-lg-r pd-md-l no-display-on-print">
      <div class="carf-home">
        <app-quick-links [cardData]="homeCardData" (click) = "navigateCard(homeCardData)"> </app-quick-links>
      </div>
    </div>
    <div class="col-xl-9 col-lg-9 col-md-12 order-md-2 order-lg-3 pt-4 pd-lg-l pd-md-r no-display-on-print">
      <app-application-survey></app-application-survey>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-8 order-md-4 order-lg-4 pt-4 pd-lg-r pd-md-r no-display-on-print">
      <app-news></app-news>
    </div>
  </div>

  <div class="row d-flex home-quicklinks d-lg-none d-xl-none no-display-on-print">
    <div class="row">
      <div class="col-md-4 no-display-on-print">
        <app-quick-links [cardData]="storeCardData" (click) = "navigateCard(storeCardData)" *ngIf="!quickLinksData"> </app-quick-links>
        <app-quick-links [cardData]="carfStandards" (click) = "navigateCard(carfStandards)" *ngIf="quickLinksData"> </app-quick-links>
      </div>
      <div class="col-md-4 no-display-on-print">
        <app-quick-links [cardData]="eventCardData" (click) = "navigateCard(eventCardData)"> </app-quick-links>
      </div>
    </div>
  </div>
</div>
