/* eslint-disable import/no-cycle */
/* eslint-disable no-restricted-globals */
import { Component, Input } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { PaymentsService } from '../services/payments.service';

@Component({
  selector: 'app-payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['./payment-summary.component.scss'],
})
export class PaymentSummaryComponent {
  @Input() public transactResponse: any = null;
  summaryData: any;
  applicationId: any;
  officeName: any;
  selectedPaymentOption: any;
  isSubmitted: any;
  constructor(private router: Router, private paymentsService: PaymentsService) {
    if (history.state.data != null) {
      this.transactResponse = history.state.data;
      console.log(
        'Payment Summary loaded for transaction id: ' +
          this.transactResponse.transId +
          ', total amount: ' +
          this.transactResponse.totalAmount,
      );
    }
    this.summaryData = this.paymentsService.getSummaryData();
    console.log(this.summaryData);
    this.applicationId = this.summaryData?.applicationId;
    this.officeName = this.summaryData?.officeName;
    this.selectedPaymentOption = this.summaryData?.selectedPaymentOption;
    this.isSubmitted = this.summaryData?.isSubmitted;
    this.paymentsService.setSummaryData('', '', '');
    this.paymentsService.setComponent('');
  }

  continue() {
    this.onCancel();
    if (this.isSubmitted) {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          id: this.applicationId,
          officeName: this.officeName,
          selectedPaymentOption: this.selectedPaymentOption,
        },
      };
      this.router.navigate(['application/application-submittal-confirmation'], navigationExtras);
    } else {
      this.onCancel();
    }
  }

  onCancel() {
    this.paymentsService.onModelClose();
  }
}
