<div class="container-fluid padding-layout contactUs" id="contactUs">
  <div class="pt-3 col-12">
    <h1 class="header">Corporate Offices</h1>
  </div>
  <div class="row rowstyle">
    <div class="col-md-4">
      <div class="form-group title">CARF International</div>
      <p>
        Toll Free: (888) 281-6531 <br />
        <a href="http://www.carf.org" target="_blank" rel="noopener">http://www.carf.org</a>
      </p>
      <p>
        <strong>Tucson, Arizona, USA (Headquarters)</strong> <br />
        6951 E. Southpoint Road <br />
        Tucson, AZ 85756-9407, USA
      </p>
      <p>
        <strong>Washington, District of Columbia, USA</strong><br />
        1775 Eye Street, Suite 1150 <br />
        Washington, DC 20006-2435, USA
      </p>
    </div>
    <div class="col-md-4">
      <div class="form-group title">CARF Canada</div>
      <div>
        <p>
          Toll Free: (888) 281-6531 <br />
          <a href="http://www.carf.org" target="_blank" rel="noopener">http://www.carf.org</a>
        </p>
      </div>
      <div>
        <p>
          <strong>Edmonton, Alberta, Canada</strong> <br />
          501-10154 104 Street NW <br />
          Edmonton, AB T5J-1A7, Canada
        </p>
        <p>
          <strong>Toronto, Ontario, Canada</strong> <br />
          1 Yonge Street, Suite 1801 <br />
          Toronto, ON M5E 1W7, Canada
        </p>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group title">CARF Europe</div>
      <p>
        Phone: 001 (520) 325-1044<br />
        <a href="http://www.carf.org" target="_blank" rel="noopener">http://www.carf.org</a>
      </p>
      <p>
        <strong> London, England, United Kingdom </strong> <br />
        4th Floor, Rex House, 4-12 Regent Street <br />
        London, SW1Y 4RG, UK
      </p>

      <p class="fontsize">
        CARF Europe is a charitable company limited by guaranty, registered in England and Wales.
        Company #06772442, Charity #1134454
      </p>
    </div>
  </div>
  <div class="pt-4 col-12">
    <p class="header">Accreditation</p>
  </div>
  <div class="row rowstyle">
    <div class="col-md-12">
      <div class="title">Accreditation Support Team</div>
      <p class="fontItalic">
        For Aging Services, Behavioral Health, Child and Youth <br />
        Services, Employment and Community Services, Medical <br />
        Rehabilitation, and Opioid Treatment Program
      </p>
      <p>
        (888) 281-6531 ext. 7080 <br />
        <a href="mailto:asc@carf.org">asc@carf.org</a>
      </p>
    </div>
  </div>

  <div class="pt-4 col-12">
    <p class="header">Level of Care Certification</p>
  </div>
  <div class="row rowstyle">
    <div class="col-md-12">
      <div class="title">Certification Support Team</div>
      <p>
        (888) 281-6531 ext. 7080 <br />
        <a href="mailto:LOCcertification@carf.org">LOCcertification@carf.org</a>
      </p>
    </div>
  </div>
  <div class="pt-4 col-12">
    <p class="header">Other Departments</p>
  </div>
  <div class="row rowstyle">
    <div class="col-md-4">
      <div class="title">Survey Services</div>
      <p class="fontItalic">
        Survey scheduling, logistics, coordinators, reports, and certificates
      </p>
      <p>
        (888) 281-6531 ext. 7100 <br />
        <a href="mailto:surveyservices@carf.org">surveyservices@carf.org</a>
      </p>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <div class="title">Education</div>
        <p class="fontItalic">Information about upcoming educational opportunities</p>
        <p>
          (888) 281-6531 ext. 7114 <br />

          <a href="mailto:education@carf.org">education@carf.org</a>
        </p>
      </div>
    </div>
  </div>
  <div class="col-md-12 col-lg-12 app-btn-bar noprint">
    <app-button-bar
      [buttonBar]="buttonBarContactUs"
      (button1Action)="onPrint()"
    ></app-button-bar>
  </div>
</div>
 <!-- Print HTML -->
<div class="container-fluid padding-layout printPage" id="contactUs">
  <div class="pt-3 col-12">
    <h1 class="header">Corporate Offices</h1>
  </div>
  <div class="row rowstyle">
    <div class="col-md-4">
      <div class="form-group title">CARF International</div>
      <p>
        Toll Free: (888) 281-6531 <br />
        <a href="http://www.carf.org" target="_blank" rel="noopener">http://www.carf.org</a>
      </p>
      <p>
        <strong>Tucson, Arizona, USA (Headquarters)</strong> <br />
        6951 E. Southpoint Road <br />
        Tucson, AZ 85756-9407, USA
      </p>
      <p>
        <strong>Washington, District of Columbia, USA</strong><br />
        1775 Eye Street, Suite 1150 <br />
        Washington, DC 20006-2435, USA
      </p>
    </div>
    <div class="col-md-4">
      <div class="form-group title">CARF Canada</div>
      <div>
        <p>
          Toll Free: (888) 281-6531 <br />
          <a href="http://www.carf.org" target="_blank" rel="noopener">http://www.carf.org</a>
        </p>
      </div>
      <div>
        <p>
          <strong>Edmonton, Alberta, Canada</strong> <br />
          501-10154 104 Street NW <br />
          Edmonton, AB T5J-1A7, Canada
        </p>
        <p>
          <strong>Toronto, Ontario, Canada</strong> <br />
          1 Yonge Street, Suite 1801 <br />
          Toronto, ON M5E 1W7, Canada
        </p>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group title">CARF Europe</div>
      <p>
        Phone: 001 (520) 325-1044<br />
        <a href="http://www.carf.org" target="_blank" rel="noopener">http://www.carf.org</a>
      </p>
      <p>
        <strong> London, England, United Kingdom </strong> <br />
        4th Floor, Rex House, 4-12 Regent Street <br />
        London, SW1Y 4RG, UK
      </p>

      <p class="fontsize">
        CARF Europe is a charitable company limited by guaranty, registered in England and Wales.
        Company #06772442, Charity #1134454
      </p>
    </div>
  </div>
  <div class="pt-4 col-12">
    <p class="header">Accreditation</p>
  </div>
  <div class="row rowstyle">
    <div class="col-md-12">
      <div class="title">Accreditation Support Team</div>
      <p class="fontItalic">
        For Aging Services, Behavioral Health, Child and Youth <br />
        Services, Employment and Community Services, Medical <br />
        Rehabilitation, and Opioid Treatment Program
      </p>
      <p>
        (888) 281-6531 ext. 7080 <br />
        <a href="mailto:asc@carf.org">asc@carf.org</a>
      </p>
    </div>
  </div>

  <div class="pt-4 col-12">
    <p class="header">Level of Care Certification</p>
  </div>
  <div class="row rowstyle">
    <div class="col-md-12">
      <div class="title">Certification Support Team</div>
      <p>
        (888) 281-6531 ext. 7080 <br />
        <a href="mailto:LOCcertification@carf.org">LOCcertification@carf.org</a>
      </p>
    </div>
  </div>
  <div class="pt-4 col-12">
    <p class="header">Other Departments</p>
  </div>
  <div class="row rowstyle">
    <div class="col-md-4">
      <div class="title">Survey Services</div>
      <p class="fontItalic">
        Survey scheduling, logistics, coordinators, reports, and certificates
      </p>
      <p>
        (888) 281-6531 ext. 7100 <br />
        <a href="mailto:surveyservices@carf.org">surveyservices@carf.org</a>
      </p>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <div class="title">Education</div>
        <p class="fontItalic">Information about upcoming educational opportunities</p>
        <p>
          (888) 281-6531 ext. 7114 <br />

          <a href="mailto:education@carf.org">education@carf.org</a>
        </p>
      </div>
    </div>
  </div>
</div>
