<app-loading-modal></app-loading-modal>
<div *ngIf="openSessionModal" class="modal-form-container session-dlg">
  <div
    class="modal exampleModalClass"
    id="exampleModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="sessionLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title" id="sessionLabel">Session Timeout</h1>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="continueSession()"
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="d-flex">
            <div>
              <span class="fa fa-exclamation-triangle mr-2 warning-font" aria-hidden="true"></span>
            </div>
            <div>
              <p class="label-data">Your session is about to expire due to inactivity.</p>
              <p class="label-data">To keep working, select Continue Session.</p>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-end">
          <button type="button" class="btn btn-primary button-styling" (click)="continueSession()">
            <i class="fa fa-check-circle mr-2" aria-hidden="true"></i>
            Continue Session
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid main pageBackground">
  <header>
    <div class="skipnav" id="skip-link">
      <a href="javascript:void(0);" (click)="skipToMainContent()" aria-label="SkipLink"
        >Skip to Main Content</a
      >
    </div>
    <app-header *ngIf="!router.url.includes('surveyPosters')"></app-header>
  </header>

  <main class="layout" #maincontent tabindex="-1">
    <div class="d-flex">
      <div
        *ngIf="
          router.url !== '/login' &&
          router.url !== '/error' &&
          router.url !== '/state' &&
          router.url !== '/code' &&
          router.url !== '/login#main-content' &&
          tokens !== 0 &&
          !router.url.includes('surveyPosters')
        "
      >
        <app-sidenav [routerURL]="router.url"></app-sidenav>
      </div>
      <div class="w-100" (click)="closeNav()">
        <router-outlet></router-outlet>
        <div class="row no-gutters">
          <div class="col-sm-12 col-lg-12">
            <footer>
              <app-footer
                *ngIf="
                  router.url !== '/login' &&
                  router.url !== '/error' &&
                  router.url !== '/state' &&
                  router.url !== '/code' &&
                  router.url !== '/login#main-content' &&
                  !router.url.includes('surveyPosters')
                "
              ></app-footer>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
