<div class="container">
  <div [hidden]="openPaymentForm">
    <div class="modal-header">
      <div class="modal-title" id="AnnualHeader">Annual Maintenance Fee Payment</div>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="fa fa-times" aria-hidden="true" (click)="onCancel()"></i>
      </button>
    </div>
    <div class="modal-body">
      <div *ngIf="showOrHideLoader" class="d-flex justify-content-center">
        <app-loader></app-loader>
      </div>
      <ng-container *ngIf="!showOrHideLoader">
        <p>
          Your Annual Maintenance Fee for Survey #<span>{{ responseReceived?.SurveyNumber }}</span> is 
          ${{ responseReceived?.BaseAmount }} {{ currencyValue }}
        </p>
        <p *ngIf="responseReceived?.TaxAmount > 0">
          Applicable Tax ${{ responseReceived?.TaxAmount }}
        </p>
        <p>
          Our records indicate the Annual Maintenance Fee balance due is ${{
            responseReceived?.TotalAmount
          }}
          {{ currencyValue }}
        </p>
      </ng-container>
    </div>
    <div class="modal-footer">
      <div class="form-footer">
        <button
          class="btn btn-info mr-3"
          type="button"
          (click)="onPay()"
          [disabled]="showOrHideLoader"
        >
          <i class="fa fa-money-check-alt mr-2" aria-hidden="true"></i>
          Pay
        </button>
        <button class="btn btn-info" data-dismiss="modal" aria-label="Close" (click)="onCancel()">
          <i class="fa fa-times-circle plus mr-2" aria-hidden="true"></i>
          Close
        </button>
      </div>
    </div>
  </div>
  <div class="col-md overflow-auto payment-modal-padding">
    <div [hidden]="!openPaymentForm" id="iframe_holder" class="center-block" style="width: 100%">
      <button
        type="button"
        class="close-payment"
        data-dismiss="modal"
        aria-label="Close"
        (click)="onCancel()"
      >
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
      <div *ngIf="showOrHideLoader" class="d-flex justify-content-center">
        <app-loader></app-loader>
      </div>
      <iframe
        id="load_payment"
        class="embed-responsive-item frame-style frame-scroll"
        name="load_payment"
        width="100%"
        height="900px"
        title="Pay Annual Fee"
      >
      </iframe>
    </div>
    <form
      id="send_hptoken"
      name="send_token"
      [action]="IFRAME_URL"
      method="post"
      target="load_payment"
      #theForm="ngForm"
    >
      <input type="hidden" name="token" [value]="tokenReceived" />
    </form>
  </div>
</div>
