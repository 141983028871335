import { Injectable } from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from '../_components/confirm-dialog/confirm-dialog.component';
import { ConfirmPopup } from '../_models/Loader';

@Injectable()
export class ConfirmationDialogService {
  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal) {}

  public confirm(input: ConfirmPopup): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmDialogComponent, { size: 'lg' });
    modalRef.componentInstance.dialogInfo = input;

    return modalRef.result;
  }

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
}
