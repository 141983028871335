import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { UserDetails } from 'src/app/core/_models/User';
import { UserService } from 'src/app/core/_services/user.service';
import { CountryList } from 'src/app/shared/_constants/constants';
import { SortPipe } from 'src/app/shared/_pipes/sort.pipe';
import { certificateFormTableConfig } from 'src/app/survey/_constants/constants';
import {
  AdditionalCertificates,
  OrganizationLocation,
} from 'src/app/survey/_models/AdditionalCertificateOrderForm';
import { SurveyDetails } from 'src/app/survey/_models/SurveyDetails';
import { SurveyService } from 'src/app/survey/_services/survey.service';
import { AdditionalCertificatePaymentComponent } from '../additional-certificate-payment/additional-certificate-payment.component';

@Component({
  selector: 'app-additional-certificate-form',
  templateUrl: './additional-certificate-form.component.html',
  styleUrls: ['./additional-certificate-form.component.scss'],
})
export class AdditionalCertificateFormComponent implements OnInit {
  orderCertificateColumns = certificateFormTableConfig;
  additionalCertificatesForm = new FormArray([]);
  additionalCertificatesFormValue: any = [];
  additionalCertificates: OrganizationLocation[];
  countryList = CountryList;
  surveyDetail: SurveyDetails;
  sizeOptions: any[] = [];
  subTotal: number = 0;
  quantitySmall: number = 0;
  quantityLarge: number = 0;
  modalReference: NgbModalRef;
  showOrHideLoader: boolean = false;
  userInfo: UserDetails;
  surveyId: string;
  @Input() surveyIdInput: string = '';
  @ViewChild('vcClose', { static: true }) saveBtn: any;
  subscriptions: Subscription[] = [];

  constructor(
    private surveyService: SurveyService,
    private sortPipe: SortPipe,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.surveyDetail = this.surveyService.surveyDetails;
    this.userService.data.subscribe((res) => {
      this.userInfo = res;
    });
    this.surveyId = this.surveyIdInput ? this.surveyIdInput : this.surveyDetail?.SurveyId;
    if (this.surveyIdInput) {
      this.getSurveyDetails();
    }
    if (this.surveyDetail?.SurveyType?.includes('certifi')) this.sizeOptions = ['8.5x11'];
    else this.sizeOptions = ['11x17', '8.5x11'];
    this.getAdditionalCertificateData();
  }
  getSurveyDetails() {
    this.surveyService
      .getSurveyDetails(this.surveyId, this.userInfo.ContactId)
      .subscribe((res: SurveyDetails) => {
        this.surveyDetail = res;
      });
  }
  openAdditionalPaymentModal() {
    this.quantitySmall = 0;
    this.quantityLarge = 0;
    this.modalService.dismissAll();
    this.additionalCertificatesForm.value.forEach((element) => {
      if (element.Size === '11x17') this.quantityLarge += +element.Quantity;
      else this.quantitySmall += +element.Quantity;
      if (element.Quantity && element.Quantity > 0)
        this.additionalCertificatesFormValue.push(element);
    });
    this.modalReference = this.modalService.open(AdditionalCertificatePaymentComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: true,
      centered: true,
    });
    this.modalReference.componentInstance.quantitySmall = this.quantitySmall;
    this.modalReference.componentInstance.quantityLarge = this.quantityLarge;
    this.modalReference.componentInstance.additionalCertificatesFormValue =
      this.additionalCertificatesFormValue;
  }

  getAdditionalCertificateData() {
    this.showOrHideLoader = true;
    this.subscriptions.push(
      this.surveyService.getAdditionalCertificates(this.surveyId).subscribe(
        (res: AdditionalCertificates) => {
          this.additionalCertificates = res.OrganizationLocation;
          this.showOrHideLoader = false;
          const csa = this.additionalCertificates?.splice(0, 2);
          this.additionalCertificates?.sort((a, b) => a.Name.localeCompare(b.Name));
          this.additionalCertificates?.unshift(csa[0], csa[1]);
          this.additionalCertificates?.forEach((element) => {
            // Sort programs alphabetically
            element?.Programs.sort((a, b) =>
              a.FormedProgramLongDescription.localeCompare(b.FormedProgramLongDescription),
            );
            // Find Governance Standards and put it at the end
            const govStd = element?.Programs.findIndex((item) =>
              item.ProgramLongDescription.includes('Governance Standards'),
            );
            if (govStd >= 0)
              element?.Programs.splice(
                element?.Programs.length - 1,
                0,
                element?.Programs.splice(govStd, 1)[0],
              );
          });
          this.addTableData();
          this.getSubTotal();
        },
        () => {
          this.showOrHideLoader = false;
          this.saveBtn.nativeElement.click();
        },
      ),
    );
  }

  addTableData() {
    this.additionalCertificates.forEach((control, i) => {
      const group = new FormGroup({
        Name: new FormControl(control?.Name),
        CompanyId: new FormControl(control?.CompanyId),
        ItemAmount: new FormControl(),
        Quantity: new FormControl(),
        Size: new FormControl(this.sizeOptions[0]),
        AccordianData: new FormControl({
          sectionTitle: control.Name,
          active: i === 0,
          stepIcon: '',
          stepInsideIcon: '',
          order: i + 1,
        }),
      });
      this.additionalCertificatesForm.push(group);
    });
  }

  removeDot(event, index) {
    const data = event.replace(/\./g, '');
    this.additionalCertificatesForm.controls[index]
      .get('Quantity')
      .setValue(data.replace(/[^0-9]/g, ''));
    this.additionalCertificatesForm.controls[index].get('Quantity').updateValueAndValidity();
  }

  onValueChange(index, value, group) {
    this.additionalCertificatesForm.controls[index]
      .get('ItemAmount')
      .setValue(group.controls.Size.value === '11x17' ? 65 * value : 55 * value);
    this.additionalCertificatesForm.controls[index].get('ItemAmount').updateValueAndValidity();
    this.getSubTotal();
  }

  getSubTotal() {
    this.subTotal = 0;
    this.additionalCertificatesForm.value.forEach((element) => {
      this.subTotal += element.ItemAmount;
    });
  }

  onSubmit() {
    if (this.additionalCertificatesForm.invalid) {
      // eslint-disable-next-line no-useless-return
      return;
    }
  }

  OnCancel() {
    this.modalService.dismissAll();
  }
}
