import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { SubmitForm } from '../_models/SubmitForm';
import { UpdateProfile } from '../_models/UpdateProfile';
import { PaymentData } from '../_models/PaymentData';
import { AgreementandSignature } from '../_models/AgreementandSignature';
import { TermsandConditionsAgreement } from '../_models/AgreementandSignatureTermsConditions';
import { CompanyList } from '../_models/CompanyList';
import {
  ApplicationItem,
  DeleteApplicationItem,
  DeleteOcfItem,
  DownloadApplicationItem,
  UpdateOcf,
} from '../_models/AttachmentsUpload';
import { ApplicationSubmitRequired } from '../_models/ApplicationSubmitRequired';

type HttpOptions = {
  headers?: HttpHeaders | { [header: string]: string | string[] };
  observe?: 'body';
  params?: HttpParams | { [param: string]: string | string[] };
  reportProgress?: boolean;
  responseType?: 'json' /* or "text" as "json" */;
  withCredentials?: boolean;
  body?: {};
};

const getHttpOptionsText = (): HttpOptions => {
  return {
    observe: 'body',
    responseType: 'text' as 'json',
  };
};

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private baseUrl = '';
  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  public acceptPaymentPageResponse: any = null;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.apiUrl;
  }

  updateProfile(formData, contactId): Observable<void> {
    const options = getHttpOptionsText();
    const reqBody = new UpdateProfile(formData);
    reqBody.Contact_Id = contactId;
    const operationName = '/user/userprofile';
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.put<void>(encodeURL, reqBody, options);
  }

  getSignNagreementModal(applicationId) {
    const operationName = `/surveyappagreement/${applicationId}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.get<any>(encodeURL).pipe(
      map((res: any) => {
        return new TermsandConditionsAgreement(res);
      }),
    );
  }
  getSubmittalConfirmation(updateRequest) {
    const operationName = `/appsubmittalconfirmation`;
    const encodeURL = this.baseUrl + operationName;
    const reqBody = updateRequest;
    const HttpOptions = getHttpOptionsText();
    return this.httpClient.post<any>(encodeURL, reqBody, HttpOptions).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  isEmailDuplicate(email: any): Observable<boolean> {
    const operationName = `/company/email`;
    const encodeURL = this.baseUrl + operationName;
    const reqBody = {
      'EmailAddress': email,
    };
    const HttpOptions = getHttpOptionsText();
    return this.httpClient.post<any>(encodeURL, reqBody, HttpOptions).pipe(
      map((res: any) => {
        return JSON.parse(res);
      }),
    );
  }

  getSubmitterInfo(applicationId: any): Observable<any> {
    const operationName = `/application/agreementsignature/${applicationId}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.get<any>(encodeURL).pipe(
      map((res: any) => {
        return new AgreementandSignature(res);
      }),
    );
  }

  getHostedPaymentPage(orderDetails: any): Observable<boolean> {
    // eslint-disable-next-line prettier/prettier
    const operationName = `/payment/`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.post(encodeURL, orderDetails, this.httpOptions).pipe(
      map((data: any) => {
        this.acceptPaymentPageResponse = data;
        return true;
      }),
    );
  }
  getPaymentsData(applicationId, companyId) {
    const operationName = `/payment/application/${applicationId}/company/${companyId}`;
    const encodeURL = this.baseUrl + operationName;
    const options = getHttpOptionsText();
    const reqBody = '';
    return this.httpClient.put<PaymentData>(encodeURL, reqBody, options).pipe(
      map((res: any) => {
        return new PaymentData(res);
      }),
    );
  }

  submitApplicationForm(updateRequest): Observable<any> {
    const operationName = `/application/submit/required`;
    const encodeURL = this.baseUrl + operationName;
    const reqBody = updateRequest;
    const HttpOptions = getHttpOptionsText();
    return this.httpClient.post<any>(encodeURL, reqBody, HttpOptions).pipe(
      map((res: any) => {
        return new ApplicationSubmitRequired(JSON.parse(res));
      }),
    );
  }

  getAttachmentItems(checkListID: string): Observable<any> {
    const operationName = `/applicationitem/${checkListID}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.get<any>(encodeURL).pipe(
      map((res: any) => {
        return new ApplicationItem(res);
      }),
    );
  }

  uploadApplicationItem(file: File, checkListID): Observable<object> {
    const options = getHttpOptionsText();
    const formData: FormData = new FormData();
    formData.append('file', file);
    options.headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    const operationName = `/applicationitem/attachment/${checkListID}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.put(encodeURL, formData, options).pipe(map((response) => response));
  }

  deleteApplicationItem(item: any) {
    const options = getHttpOptionsText();
    options.body = new DeleteApplicationItem(item);
    const operationName = '/applicationitem/attachment';
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.delete(encodeURL, options);
  }

  downloadApplicationItem(item) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'body',
      responseType: 'blob' as 'json',
    };
    const reqBody = new DownloadApplicationItem(item);
    const operationName = '/applicationitem/downloadattachment';
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.put<any>(encodeURL, reqBody, options).pipe(map((response) => response));
  }

  updateApplicationPayment(updateApplicationId): Observable<any> {
    const operationName = `/payment/application`;
    const encodeURL = this.baseUrl + operationName;
    const reqBody = updateApplicationId;
    const HttpOptions = getHttpOptionsText();
    return this.httpClient.put<any>(encodeURL, reqBody, HttpOptions).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  updateAnnualMaintainancePayment(updateACQRID): Observable<any> {
    const operationName = `/payment/annualreport`;
    const encodeURL = this.baseUrl + operationName;
    const reqBody = updateACQRID;
    const HttpOptions = getHttpOptionsText();
    return this.httpClient.put<any>(encodeURL, reqBody, HttpOptions).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  updateCertificatesPayment(updateCertID): Observable<any> {
    const operationName = `/payment/additionalcertificates`;
    const encodeURL = this.baseUrl + operationName;
    const reqBody = updateCertID;
    const HttpOptions = getHttpOptionsText();
    return this.httpClient.put<any>(encodeURL, reqBody, HttpOptions).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  getSubmitFormPaymentsData(updateRequest): Observable<SubmitForm> {
    const operationName = `/application/submit`;
    const encodeURL = this.baseUrl + operationName;
    const reqBody = updateRequest;
    const HttpOptions = getHttpOptionsText();
    return this.httpClient.post<any>(encodeURL, reqBody, HttpOptions).pipe(
      map((res: any) => {
        return new SubmitForm(res);
      }),
    );
  }

  uploadOcf(file: File, companyID): Observable<object> {
    const options = getHttpOptionsText();
    const formData: FormData = new FormData();
    formData.append('file', file);
    options.headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    const operationName = `/ocf/${companyID}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.put(encodeURL, formData, options).pipe(map((response) => response));
  }

  deleteOcf(item: any, companyID: string): Observable<any> {
    const options = getHttpOptionsText();
    options.body = new DeleteOcfItem(item, companyID);
    const operationName = '/ocf';
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.delete<any>(encodeURL, options);
  }

  addOcf(obj: UpdateOcf): Observable<any> {
    const options = getHttpOptionsText();
    const operationName = `/ocf/ocftodo`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.post<any>(encodeURL, obj, options);
  }

  getCompanyList(contactId: string): Observable<any> {
    const operationName = `/company/list/${contactId}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.get<any>(encodeURL).pipe(
      map((res) => {
        return res.map((item) => new CompanyList(item));
      }),
    );
  }

  updateOtherPayment(updateOtherPaymentData): Observable<any> {
    const operationName = `/payment/application/paymethod`;
    const encodeURL = this.baseUrl + operationName;
    const reqBody = updateOtherPaymentData;
    const HttpOptions = getHttpOptionsText();
    return this.httpClient.put<any>(encodeURL, reqBody, HttpOptions).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }
}
