/* eslint-disable max-classes-per-file */
function getAmount(data?: any) {
  if (data?.AIMS_Size === '11x17') {
    return data?.AIMS_Quantity * 65;
  }
  return data?.AIMS_Size === '8.5x11' ? data?.AIMS_Quantity * 55 : 0;
}
export class AdditionalCert {
  SurveyCertificationID: string;
  CompanyName: string;
  Size: string;
  Quantity: string;
  Amount: number;
  constructor(data?: any) {
    this.SurveyCertificationID = data?.AIMS_Survey_Certifications_Id;
    this.CompanyName = data?.Company_Name;
    this.Size = data?.AIMS_Size;
    this.Quantity = data?.AIMS_Quantity;
    this.Amount = getAmount(data);
  }
}

export class CheckPaymentInstruction {
  SurveyNumber: string;
  OrderTotal: string;
  SalesTax: string;
  Total: string;
  OfficeName: string;
  AdditionalCerificates: Array<AdditionalCert>;

  constructor(data?: any) {
    this.SurveyNumber = data?.AIMS_Survey_Number;
    this.OrderTotal = data?.AIMS_OrderTotal;
    this.SalesTax = data?.AIMS_SalesTax;
    this.Total = data?.AIMS_Total;
    this.OfficeName = data?.Office_Name;
    this.AdditionalCerificates = data.AdditionalCertificates?.map(
      (item) => new AdditionalCert(item),
    );
  }
}
