export class ContactLink {
  FirstName: string = '';
  LastName: string = '';
  Photo: string = '';
  JobTitle: string = '';
  WorkEmail: string = '';

  constructor(data?: any) {
    if (data) {
      Object.assign(data);
    }
  }
}
