import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { DashboardComponent } from './dashboard/_components/dashboard.component';
import { LoginComponent } from './core/login/login.component';
import { ErrorComponent } from './shared/_components/error/error.component';
import { LoaderComponent } from './shared/_components/loader/loader.component';
import { Resolver } from './core/_guards/resolver.service';
import { TermsOfUseComponent } from './shared/_components/terms-of-use/terms-of-use.component';
import { ContactUsComponent } from './shared/_components/contact-us/contact-us.component';
import { CustomPreloadingService } from './custom-preloading.service';

const routes: Routes = [
  {
    path: 'home',
    component: DashboardComponent,
    canActivate: [MsalGuard],
    resolve: {
      userDetail: Resolver,
    },
  },
  {
    path: 'company',
    loadChildren: () => import('./company/company.module').then((m) => m.CompanyModule),
  },
  {
    path: 'resources',
    loadChildren: () => import('./resources/resources.module').then((m) => m.ResourcesModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'application',
    loadChildren: () => import('./application/application.module').then((m) => m.ApplicationModule),
    data: { preload: true }, // Custom property using to track what route to be preloaded
  },
  {
    path: 'survey',
    loadChildren: () => import('./survey/survey.module').then((m) => m.SurveyModule),
  },
  {
    path: 'ocf',
    loadChildren: () => import('./ocf/ocf.module').then((m) => m.OcfModule),
  },
  {
    path: 'termsOfUse',
    component: TermsOfUseComponent,
  },
  {
    path: 'contactUs',
    component: ContactUsComponent,
  },
  {
    // Needed for hash routing
    path: 'state',
    component: LoaderComponent,
  },
  {
    // Needed for hash routing
    path: 'error',
    component: LoaderComponent,
  },
  {
    // Needed for hash routing
    path: 'code',
    component: LoaderComponent,
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'errors', component: ErrorComponent },
  { path: '**', redirectTo: 'errors' },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      useHash: true,
      // Don't perform initial navigation in iframes
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled',
      preloadingStrategy: CustomPreloadingService,
      relativeLinkResolution: 'legacy',
    }),
  ],

  exports: [RouterModule],
})
export class AppRoutingModule {}
