/* eslint-disable import/no-cycle */
/* eslint-disable object-shorthand */
import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { PaymentSummaryComponent } from '../payment-summary/payment-summary.component';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  SummaryData: {};
  componentSelected: any = '';
  modalReference: NgbModalRef;
  constructor(private modalService: NgbModal) {}

  orderDetails = {
    'HostedFormSettings': {
      'HostedPaymentReturnOptions_ShowReceipt': false,
      'HostedPaymentReturnOptions_CancelUrl': environment.hostedUrl,
      'HostedPaymentReturnOptions_CancelUrlText': 'Cancel',
      'HostedPaymentButtonOptions_Text': 'Pay',
      'HostedPaymentStyleOptions_BgColor': '#568AB9',
      'HostedPaymentPaymentOptions_CardCodeRequired': true,
      'HostedPaymentPaymentOptions_ShowCreditCard': true,
      'HostedPaymentPaymentOptions_ShowBankAccount': false,
      'HostedPaymentPaymentOptions_CustomerProfileId': false,
      'HostedPaymentSecurityOptions_Captcha': true,
      'HostedPaymentBillingAddressOptions_Show': true,
      'HostedPaymentBillingAddressOptions_Required': true,
      'HostedPaymentCustomerOptions_ShowEmail': true,
      'HostedPaymentCustomerOptions_RequiredEmail': true,
      'HostedPaymentCustomerOptions_AddPaymentProfile': false,
      'HostedPaymentOrderOptions_Show': true,
      'HostedPaymentIFrameCommunicatorUrl': environment.hostedUrl,
    },
    transactionType: 'authCaptureTransaction',
    TotalAmount: null,
    TaxAmount: null,
    CurrencyCode: '',
    InvoiceNumber: '',
    OrderDescription: '',
    LineItems: [],
  };

  setSummaryData(id, officeName, selectedPaymentOption, isSubmitted = false) {
    this.SummaryData = {
      applicationId: id,
      officeName: officeName,
      selectedPaymentOption: selectedPaymentOption,
      isSubmitted: isSubmitted,
    };
  }

  getSummaryData() {
    return this.SummaryData;
  }

  setComponent(selectedComponent) {
    this.componentSelected = selectedComponent;
  }

  getComponent() {
    return this.componentSelected;
  }

  getorderDetails = (res: any): any => {
    this.orderDetails.TotalAmount = res?.TotalAmount;
    this.orderDetails.TaxAmount = res?.TaxAmount;
    this.orderDetails.CurrencyCode = res?.Currency;
    this.orderDetails.InvoiceNumber = res?.InvoiceNumber;
    this.orderDetails.OrderDescription = res?.OrderDescription;
    this.orderDetails.LineItems = res?.LineItems;
    if (res?.Company_Country === 'US' || res?.Company_Country === 'USA') {
      this.orderDetails.HostedFormSettings.HostedPaymentPaymentOptions_ShowBankAccount = true;
    } else {
      this.orderDetails.HostedFormSettings.HostedPaymentPaymentOptions_ShowBankAccount = false;
    }
    return this.orderDetails;
  };

  goToPaymentSummary(transactResponse: any): void {
    const transactResponseData = transactResponse;
    this.onModelClose();
    this.modalReference = this.modalService.open(PaymentSummaryComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: true,
      centered: true,
    });
    this.modalReference.componentInstance.transactResponse = transactResponseData;
  }

  parseQueryString(response: any) {
    console.log('ParseQueryString: ' + response);
    const jsonString = response?.qstr?.split('=', 3)[2];
    return jsonString;
  }

  onModelClose() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.modalService.dismissAll();
  }
}
