import { ConfirmPopup } from '../_models/Loader';

export const CountryList = [
  'ARGENTINA',
  'AUSTRIA',
  'BELGIUM',
  'BRAZIL',
  'CHILE',
  'CHINA',
  'DOMINICAN REPUBLIC',
  'FRANCE',
  'GERMANY',
  'GREECE',
  'GUATEMALA',
  'ICELAND',
  'ISRAEL',
  'KUWAIT',
  'LITHUANIA',
  'MALAYSIA',
  'NORWAY',
  'PARAGUAY',
  'PORTUGAL',
  'SLOVENIA',
  'SPAIN',
  'SWEDEN',
];
export const PasswordDlg: ConfirmPopup = {
  title: 'Change Password',
  message: `<div>
      The new password must meet the following criteria:
      <ul>
        <li>at least 8 characters</li>
        <li>3 out of the following 4
          <ul>
            <li>one uppercase character</li>
            <li>one lowercase character</li>
            <li>one numeric character</li>
            <li>one of the following special characters: @#$%^&*-_!+=[]{}|\`~"();</li>
          </ul>
        </li>
      </ul>
      <div>
      Please note: If you continue, you will be logged out of
      Customer Connect and redirected to a new page where you
      can change your password. You will be required to log back in
      to access your information.
      </div>
    </div>`,
  btnOkIcon: 'fa-arrow-alt-circle-right',
  btnCancelIcon: 'fa-times-circle',
  btnOkText: 'Continue',
  btnCancelText: 'Cancel',
  dialogSize: 'lg',
  hideCancelBtn: false,
};
export const UnSaveDlg: ConfirmPopup = {
  title: 'Save?',
  message: `<p>Unsaved changes have been made to the form.</p> <p><strong>Do you want to save changes?</strong></p>`,
  btnOkIcon: 'fa-save',
  btnCancelIcon: 'fa-trash-alt',
  btnOkText: 'Save',
  btnCancelText: 'Discard',
  dialogSize: 'lg',
  hideCancelBtn: true,
};
export const ButtonBarInfo = {
  btn1: {
    btnIcon1: 'fa fa-save',
    btntext1: 'COMMON.SAVE',
  },
  btn2: {
    btnIcon2: 'fa fa-times-circle',
    btntext2: 'COMMON.CANCEL',
  },
  btn3: {
    btnIcon3: 'fa fa-lock',
    btntext3: 'COMMON.CHANGEPASSWORD',
  },
};
export const applicationStatusColor = {
  'Accepted': 'accepted',
  'Application Review': 'review',
  'Pending Submission': 'pending',
  'Submitted': 'submitted',
  'Application': 'submitted',
  'Accredited': 'accepted',
  'Nonaccreditation': 'nonaccerediation',
};
export const surveyStatusColor = {
  'Application': 'submitted',
  'Scheduling': 'pending',
  'Survey Pending': 'pending',
  'Survey Report Processing': 'pending',
  'Accredited': 'accepted',
  'Certification': 'accepted',
  'Nonaccreditation': 'nonaccerediation',
  'Rescinded': 'suspended',
  'Suspended': 'suspended',
  'Not Accredited': 'nonaccerediation',
  'Not Certified': 'nonaccerediation',
  'Not Continuing': 'suspended',
  'Merged into Active Accreditation': 'nonaccerediation',
  ' Merged into Active Certification': 'nonaccerediation',
};
export const FileExtensions: any = [
  '.doc',
  '.docx',
  '.pdf',
  '.xls',
  '.xlsx',
  '.rtf',
  '.txt',
  '.ppt',
  '.pptx',
  '.gif',
  '.jpg',
  '.bmp',
  '.tif',
  '.tff',
  '.tiff',
  '.zip',
];
export const attachmentsTableColumns: any = ['Attachment', 'Date Added', 'Type', 'Size'];
export const applFeePaymentCols: any = ['Section Number', 'Section Title', 'Terms and Conditions'];

export const robohelp: any = {
  url: 'https://help.carf.org',
};
