// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  environmentName: 'prod',
  clientId: '0b71b8bb-b171-4416-b1c8-09aeb57c5ccf',
  signInAuthority:
    'https://carfproductionapps.b2clogin.com/carfproductionapps.onmicrosoft.com/B2C_1A_SignUp_SignIn',
  authorityDomain: 'carfproductionapps.b2clogin.com',
  names: {
    signUpSignIn: 'B2C_1A_SignUp_SignIn',
    forgotPassword: 'B2C_1A_PasswordReset',
    changePassword: 'B2C_1A_ChangePassword',
  },
  forgotPasswordAuthority:
    'https://carfproductionapps.b2clogin.com/carfproductionapps.onmicrosoft.com/B2C_1A_PasswordReset',
  changePasswordAuthority:
    'https://carfproductionapps.b2clogin.com/carfproductionapps.onmicrosoft.com/B2C_1A_ChangePassword',
  signOutAuthority:
    'https://carfproductionapps.b2clogin.com/carfproductionapps.onmicrosoft.com/B2C_1A_SignUp_SignIn/oauth2/v2.0/logout?post_logout_redirect_uri=https%3A%2F%2Fcustomerconnect.carf.org%2F',
  apiUrl: 'https://customerconnect.carf.org/api',
  paymentUrl: 'https://accept.authorize.net/payment/payment',
  hostedUrl: 'https://customerconnect.carf.org/assets/IFrameCommunicator/IFrameCommunicator.html',
};
