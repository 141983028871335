<div class="container" aria-label="application payment">
  <div id="contentData">
    <div class="modal-header">
      <h1 aria-label="payment instruction" class="modal-title">Payment Instructions</h1>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="modalClose()"
      >
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <div>
          <p class="static-text">
            Application Number: <span>{{ paymentModelData?.ApplicationNumber }}</span>
          </p>
          <p class="static-text">
            Application Fee: <span
              >{{ paymentModelData?.BaseAmount | currency }}
              {{ paymentModelData?.Company_Country === 'CANADA' ? 'CAD' : 'USD' }}</span
            >
          </p>
        </div>
        <div *ngIf="paymentModelData?.TaxAmount > 0">
          <p class="static-text">
            Applicable Tax: <span>{{ paymentModelData?.TaxAmount | currency }}</span>
          </p>
        </div>
        <div>
          <p class="static-text">
            Total Invoice Amount: <span
              >{{ paymentModelData?.TotalAmount | currency }}
              {{ paymentModelData?.Company_Country === 'CANADA' ? 'CAD' : 'USD' }}.</span
            >
          </p>
        </div>
        <div *ngIf="paymentModelData?.Office_Name === 'CARF Europe'">
          <p>CARF will contact you if taxes apply.</p>
        </div>
        <div *ngIf="selectedPaymentOption === 'Wire Transfer'">
          <p class="static-text">This does not include applicable wire transfer fees.</p>
          <p class="static-text">
            If you have not yet initiated the wire transfer, email your request for CARF’s wire
            information to
            <a
              class="instruction-links"
              href="mailto:bookstore@carf.org"
              aria-label="abookstore link"
              >bookstore@carf.org</a
            >. Include your organization’s name and country in this request.
          </p>
        </div>
        <div *ngIf="selectedPaymentOption === 'Check'">
          <p class="static-text">Send a copy of this confirmation with your check to {{ mailingAddress }}</p>
        </div>
        <div *ngIf="selectedPaymentOption === 'Electronic Payment'">
          <p class="static-text">
            If you have not yet initiated the electronic payment, email your request for CARF’s
            electronic payment information to
            <a
              class="instruction-links"
              href="mailto:Finance_Europe@carf.org"
              aria-label="abookstore link"
              >Finance_Europe@carf.org.</a
            >
            Include your organization’s name and country in this request.
          </p>
        </div>
      </div>
    </div>
    <div class="modal-footer" id="button-barData">
      <div class="form-footer">
        <button class="btn btn-info mr-3" type="button" (click)="onPrint()">
          <i class="fa fa-save plus mr-2" aria-hidden="true"></i>
          Print
        </button>
        <button class="btn btn-info" data-dismiss="modal" aria-label="Close" (click)="modalClose()">
          <i class="fa fa-times-circle plus mr-2" aria-hidden="true"></i>
          Close
        </button>
      </div>
    </div>
  </div>
</div>
