import { ChangeDetectorRef, Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingModalService } from '../../_services/loading-modal.service';

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss'],
})
export class LoadingModalComponent {
  visible: boolean = false;
  toggleClass: boolean = false;
  loadingModalServiceSub: Subscription;

  constructor(private loadingModalService: LoadingModalService, private cdRef: ChangeDetectorRef) {
    this.loadingModalServiceSub = this.loadingModalService.showHideModal().subscribe((res) => {
      if (res) {
        if (res === 'show') {
          this.openLoaderModal();
        } else if (res === 'hide') {
          this.closeLoaderModal();
        }
      }
    });
  }

  public openLoaderModal() {
    this.visible = true;
    setTimeout(() => {
      this.toggleClass = true;
    });
  }

  public closeLoaderModal() {
    this.toggleClass = false;
    setTimeout(() => {
      this.visible = false;
      this.cdRef.markForCheck();
    }, 200);
  }
}
