import { Utils } from 'src/app/shared/_models/common/Date-Formatter';
import { IMissingItems } from '../_contracts/IMissingItems';

export class MissingItems implements IMissingItems {
  checklistId: string;
  surveyNumber: string;
  companyName: string;
  item: string;
  dueDate: string;
  sortDueDate: Date;
  surveyId: string;
  constructor(data: any) {
    this.checklistId = data.AIMS_Survey_Items_Checklist_Id;
    this.surveyNumber = data.AIMS_Survey_Number;
    this.companyName = data.Company_Name;
    this.item = data.AIMS_Items_Chklst_Item;
    this.dueDate = Utils.formatLongDate(Utils.addDays(data.AIMS_Intent_Acknlgmnt_Dt, 30));
    this.sortDueDate = data.AIMS_Intent_Acknlgmnt_Dt ? data.AIMS_Intent_Acknlgmnt_Dt : null;
    this.surveyId = data.AIMS_Survey_Id;
  }
}
