import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-stepper-form',
  templateUrl: 'stepper-form.component.html',
  styleUrls: ['stepper-form.component.scss'],
})
export class StepperFormComponent {
  @Input() accordianData: any;
  @Input() showStepperIcon = true;
  @Input() fontStyle = false;
  expanded: string = '';

  ngOnInit(): void {
    if (this.accordianData.active) {
      this.expanded = 'show';
    }
  }

  toggle() {
    this.accordianData.active = !this.accordianData.active;
  }
}
