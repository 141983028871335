import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  token: any;
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.token = sessionStorage.getItem('carf_IdToken');
    // clone http to the custom Request and send it to the server
    if (this.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.token}`,
        },
        headers: new HttpHeaders({
          'Strict-Transport-Security': 'max-age=31536000; includeSubdomains',
          'X-Content-Type-Options': 'nosniff',
          'X-Xss-Protection': '1; mode=block',
          'Content-Security-Policy':
            "script-src https: 'unsafe-inline' 'unsafe-eval';style-src https: 'unsafe-inline' 'unsafe-eval';img-src https: data:;font-src https: data:; frame-ancestors 'self' https://*.authorize.net;",
        }),
      });
    }
    return next.handle(request);
  }
}
