<div class="d-flex btn-footer justify-content-between">
    <div>
        <button type="submit" class="btn clr mr-3" *ngIf="buttonBar.btn1" (click)="button1Click(buttonBar.btn1.btntext1)" 
                [disabled]="button1Disabled" >
            <i class="{{buttonBar.btn1.btnIcon1}} mr-2" aria-hidden="true"></i>{{buttonBar.btn1.btntext1 | translate}}
        </button>
        <button type="button" class="btn clr" *ngIf="buttonBar.btn2" (click)="button2Click()" [disabled] = "button2Disabled">
            <i class="{{buttonBar.btn2.btnIcon2}} mr-2" aria-hidden="true"></i>{{buttonBar.btn2.btntext2 | translate}}
        </button>
    </div>
    <div>
        <button type="button" class="btn clr" *ngIf="buttonBar.btn3" (click)="button3Click(buttonBar.btn3.btntext3)" 
                data-toggle="modal" [attr.data-target]="'#' + modalId" data-backdrop="static" [disabled] = "button3Disabled">
            <i class="{{buttonBar.btn3.btnIcon3}} mr-2" aria-hidden="true"></i>{{buttonBar.btn3.btntext3  | translate}}
        </button>
    </div>
</div>