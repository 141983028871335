import { NavigationEnd, Router } from '@angular/router';
/* eslint-disable no-underscore-dangle */
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
  MsalBroadcastService,
} from '@azure/msal-angular';
import {
  InteractionStatus,
  EventMessage,
  EventType,
  AuthenticationResult,
  AuthError,
} from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { b2cPolicies } from './b2c-config';
import { LoginService } from './core/_services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  idleState = 'Not started.';
  timedOut = false;
  skipLinkPath: string;
  isIframe = false;
  countdown: any;
  tokens: any;
  openSessionModal: boolean = false;
  @ViewChild('maincontent', { static: false }) skipper: ElementRef;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public router: Router,
    public translate: TranslateService,
    private loginService: LoginService,
    private idle: Idle,
  ) {
    translate.addLangs(['en']);
    translate.setDefaultLang('en');
    this.setSessionTime();
  }

  setSessionTime() {
    this.tokens = sessionStorage?.length;
    if (this.tokens > 0) {
      // sets an idle timeout of 25 mins
      this.idle.setIdle(1500);
      // sets a timeout period of 5 mins. after 30 mins of inactivity, the user will be considered timed out.
      this.idle.setTimeout(300);
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
      this.idle.onIdleEnd.subscribe(() => {
        this.openSessionModal = false;
        this.idleState = 'No longer idle.';
      });
      this.idle.onTimeout.subscribe(() => {
        this.idleState = 'Timed out!';
        this.timedOut = true;
        sessionStorage.clear();
        this.loginService.logout();
      });
      this.idle.onIdleStart.subscribe(() => {
        this.idleState = "You've gone idle!";
      });
      this.idle.onTimeoutWarning.subscribe((countdown) => {
        this.openSessionModal = true;
        this.idleState = 'You will time out in ' + countdown + ' seconds!';
      });
      this.continueSession();
    } else {
      this.idle.onTimeoutWarning.unsubscribe();
      this.openSessionModal = false;
    }
  }
  ngOnInit(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      if (!this.router.url.endsWith('#main-content')) {
        this.skipLinkPath = `${this.router.url}#main-content`;
      }
    });
    this.isIframe = window !== window.parent && !window.opener;

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$),
      )
      .subscribe();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS,
        ),
        takeUntil(this._destroying$),
      )
      .subscribe((result: EventMessage) => {
        const payload: any = <AuthenticationResult>result.payload;
        sessionStorage.setItem('carf_IdToken', payload.idToken);
        if (payload.idTokenClaims?.tfp === b2cPolicies.names.forgotPassword) {
          // eslint-disable-next-line no-alert
          window.alert(
            'Password has been reset successfully. \nPlease sign-in with your new password.',
          );
          return this.loginService.logout();
        }
        return result;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE ||
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE,
        ),
        takeUntil(this._destroying$),
      )
      .subscribe((result: EventMessage) => {
        if (result.error instanceof AuthError) {
          // Check for forgot password error
          // Learn more about AAD error codes at https://docs.microsoft.com/azure/active-directory/develop/reference-aadsts-error-codes
          if (result.error.message.includes('AADB2C90118')) {
            // login request with reset authority
            const resetPasswordFlowRequest = {
              scopes: ['openid'],
              authority: b2cPolicies.authorities.forgotPassword,
            };
            this.loginService.login(resetPasswordFlowRequest);
          } else if (result.error.message.includes('AADB2C90091')) {
            window.location.reload();
          } else {
            this.loginService.logout();
            this.loginService.login();
          }
        }
      });
  }

  skipToMainContent(): void {
    this.skipper.nativeElement.focus();
  }

  ngOnDestroy(): void {
    const value = undefined;
    this._destroying$.next(value);
    this._destroying$.complete();
  }
  closeNav() {
    const sideNav = document.getElementById('sideNav');
    if (sideNav) {
      sideNav.style.width = '0%';
    }
  }
  continueSession() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
    this.openSessionModal = false;
  }
  logOut() {
    this.loginService.logout();
  }
}
