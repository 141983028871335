/* eslint-disable max-classes-per-file */

import { Utils } from 'src/app/shared/_models/common/Date-Formatter';

export class AdditionalManual {
  RnDescriptor: string;
  constructor(data?: any) {
    this.RnDescriptor = data?.Rn_Descriptor;
  }
}

export class Information {
  SurveyType: string;
  KeyContact: string;
  Preliminary: boolean;
  SurveyStartDate: string;
  SurveyEndDate: string;
  PreliminaryFollowUp: boolean;
  PrimaryManual: string;
  UnannouncedSurvey: boolean;
  AdditionalMannuals: Array<AdditionalManual>;

  constructor(data?: any) {
    if (data) {
      this.SurveyType = data.AIMS_Survey_Type;
      this.KeyContact = data.Key_Contact;
      this.Preliminary = data.AIMS_Preliminary;
      this.SurveyStartDate = Utils.formatLongDate(data.AIMS_Survey_Start_Dt);
      this.SurveyEndDate = Utils.formatLongDate(data.AIMS_Survey_End_Dt);
      this.PreliminaryFollowUp = data.AIMS_Preliminary_Follow_Up;
      this.PrimaryManual = data.Primary_Manual;
      this.UnannouncedSurvey = data.AIMS_Unannounced_Survey;
      this.AdditionalMannuals = data.AdditionalManuals?.map((item) => new AdditionalManual(item));
    }
  }
}

export class OtherSurveycontact {
  Contact: string;
  AlternateContact: boolean;
  OtherAfterHours: boolean;
  OtherFinance: boolean;
  OtherDisplayOnReport: boolean;

  constructor(data?: any) {
    this.Contact = data?.Contact;
    this.AlternateContact = data?.AIMS_Alternate_Contact;
    this.OtherAfterHours = data?.AIMS_Other_After_Hours;
    this.OtherFinance = data?.AIMS_Other_Finance;
    this.OtherDisplayOnReport = data?.AIMS_Other_Display_On_Report;
  }
}

export class Program {
  ProgramLongDescription: string;
  FieldCategory: string;
  PopulationServed: string;
  SurveyCompanyProgramId: string;
  FormedProgramLongDescription: string;

  constructor(data?: any) {
    this.ProgramLongDescription = data?.AIMS_Program_Long_Description;
    this.FieldCategory = data?.AIMS_Field_Category;
    this.PopulationServed = data?.AIMS_Population_Served;
    this.SurveyCompanyProgramId = data?.AIMS_Survey__Company_Prgram_Id;
    this.FormedProgramLongDescription =
      data?.AIMS_Program_Long_Description +
      ' ' +
      data?.AIMS_Field_Category +
      ' ' +
      data?.AIMS_Population_Served;
  }
}

export class CompanyAndPrograms {
  Name: string;
  Number: string;
  SortingCompNumber: number;
  Address1: string;
  Address2: string;
  Address3: string;
  City: string;
  State: string;
  Zip: string;
  Programs: Array<Program>;

  constructor(data?: any) {
    if (data) {
      this.Name = data.Company_Name;
      this.Number = data.Company_Number;
      this.SortingCompNumber = parseInt(this.Number, 10);
      this.Address1 = data.Address_1;
      this.Address2 = data.Address_2;
      this.Address3 = data.Address_3;
      this.City = data.City;
      this.State = data.State;
      this.Zip = data.Zip;
      this.Programs = data.Programs?.map((item) => new Program(item));
    }
  }
}

export class CertificateOrder {
  CertificateType: string;
  OrderReceivedDate: string;
  OrderPaidDate: string;
  CertificateSentDate: string;
  OrderReceivedDataSorting: Date;

  constructor(data?: any) {
    if (data) {
      this.CertificateType = data.AIMS_Certificate_Type;
      this.OrderReceivedDate = Utils.formatLongDate(data.AIMS_Cert_Order_Recd_Date);
      this.OrderPaidDate = Utils.formatLongDate(data.AIMS_Cert_Order_Paid_Date);
      this.CertificateSentDate = Utils.formatLongDate(data.AIMS_Certificate_Sent_Date);
      this.OrderReceivedDataSorting = data.AIMS_Cert_Order_Recd_Date
        ? data.AIMS_Cert_Order_Recd_Date
        : null;
    }
  }
}

export class SurveyInfo {
  Information: Information;
  OtherSurveyContact: Array<OtherSurveycontact>;
  CompaniesAndPrograms: Array<CompanyAndPrograms>;
  CertificateOrders: Array<CertificateOrder>;

  constructor(data?: any) {
    if (data) {
      this.Information = new Information(data.SurveyInformation);
      this.OtherSurveyContact = data.OtherSurveyContacts?.map(
        (item) => new OtherSurveycontact(item),
      );
      this.CompaniesAndPrograms = data.CompaniesAndPrograms?.map(
        (item) => new CompanyAndPrograms(item),
      );
      this.CertificateOrders = data.CertificateOrders?.map((item) => new CertificateOrder(item));
    }
  }
}
