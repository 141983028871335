import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IApplicationList, ISurveyList, INews } from '../contracts/application-list-interface';
import { ApplicationList } from '../models/application-list';
import { SurveyList } from '../models/survey-list';
import { NewsList } from '../models/news-list';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private baseUrl = '';
  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.apiUrl;
  }

  getData(): Observable<any> {
    const operationName = '/posts';
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.get(encodeURL);
  }
  getApplicationData(contactId: string): Observable<ApplicationList[]> {
    const operationName = `/application/list/${contactId}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.get<ApplicationList[]>(encodeURL).pipe(
      map((res: IApplicationList[]) => {
        return res.map((obj: IApplicationList) => new ApplicationList(obj));
      }),
    );
  }

  getSurveyData(contactId: string): Observable<SurveyList[]> {
    const operationName = `/survey/list/${contactId}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.get<SurveyList[]>(encodeURL).pipe(
      map((res: ISurveyList[]) => {
        return res.map((obj: ISurveyList) => new SurveyList(obj));
      }),
    );
  }

  getActionTileData(contactId: string): Observable<any> {
    const operationName = `/home/actionitem/${contactId}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.get<any>(encodeURL);
  }

  getNewsData(): Observable<NewsList[]> {
    const operationName = '/news/list';
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.get<NewsList[]>(encodeURL).pipe(
      map((res: INews[]) => {
        return res.map((obj: INews) => new NewsList(obj));
      }),
    );
  }
}
