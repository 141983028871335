/* eslint-disable no-console */
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentsService } from 'src/app/shared/_components/payment/services/payments.service';
import { SharedService } from 'src/app/shared/_services/shared.service';
import { AnnualMaintenanceFee } from 'src/app/survey/_models/AnnualMaintenanceFee';
import { SurveyDetails } from 'src/app/survey/_models/SurveyDetails';
import { SurveyService } from 'src/app/survey/_services/survey.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-annual-maintenance-fee',
  templateUrl: './annual-maintenance-fee.component.html',
  styleUrls: ['./annual-maintenance-fee.component.scss'],
})
export class AnnualMaintenanceFeeComponent implements OnInit, OnDestroy {
  @Input() acqrIdInput: string;
  @Input() surveyId: string;
  @ViewChild('theForm', { static: true, read: ElementRef }) form: ElementRef;
  responseReceived: AnnualMaintenanceFee;
  currencyValue: string;
  orderDetails: any = {};
  public acceptPaymentPageResponse = null;
  tokenReceived: any;
  IFRAME_URL: string;
  HOSTEDPAYMENT_URL: string;
  transactResponse = null;
  openPaymentForm: boolean = false;
  showOrHideLoader: boolean = false;
  acqrId: string;
  surveyDetails: SurveyDetails;
  selectedComponent: string;
  getSelectedComponentInfo: any;

  constructor(
    private surveyService: SurveyService,
    private paymentsService: PaymentsService,
    private sharedService: SharedService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  @HostListener('unloaded')
  ngOnDestroy() {
    window.removeEventListener('message', this.receiveMessage, false);
  }
  ngOnInit(): void {
    if (window.addEventListener) {
      window.addEventListener('message', this.receiveMessage, false);
    } else {
      (<any>window).attachEvent('onmessage', this.receiveMessage, false);
    }
    this.selectedComponent = 'Annual Maintainance Payment';
    this.paymentsService.setComponent(this.selectedComponent);
    this.IFRAME_URL = environment.paymentUrl;
    this.HOSTEDPAYMENT_URL = environment.hostedUrl;
    this.showOrHideLoader = true;
    this.route.queryParamMap.subscribe((queryParams) => {
      this.acqrId = queryParams.get('id');
    });
    this.acqrId = this.acqrId ? this.acqrId : this.acqrIdInput;
    this.surveyService.getAnnualPaymentDetails(this.acqrId, this.surveyId).subscribe(
      (res: AnnualMaintenanceFee) => {
        this.responseReceived = res;
        this.showOrHideLoader = false;
        if (this.responseReceived?.Company_Country?.includes('CANADA')) {
          this.currencyValue = 'CAD';
        } else {
          this.currencyValue = 'USD';
        }
      },
      () => {
        this.paymentsService.onModelClose();
      },
    );
  }

  onPay() {
    this.paymentsService.setSummaryData('', '', '');

    this.openPaymentForm = true;
    this.showOrHideLoader = true;
    this.orderDetails = this.paymentsService.getorderDetails(this.responseReceived);
    this.sharedService.getHostedPaymentPage(this.orderDetails).subscribe(
      (success) => {
        if (success) {
          this.acceptPaymentPageResponse = this.sharedService.acceptPaymentPageResponse;
          this.tokenReceived = this.acceptPaymentPageResponse.Token;
          setTimeout(() =>
            // eslint-disable-next-line @typescript-eslint/dot-notation
            (this.form.nativeElement as any)['submit'].apply(this.form.nativeElement),
          );
          this.showOrHideLoader = false;
        }
      },
      () => {
        this.paymentsService.onModelClose();
      },
    );
  }

  receiveMessage = (event: any): void => {
    if (event != null && event.data != null) {
      // eslint-disable-next-line no-cond-assign
      if (event.data?.qstr?.includes('cancel')) {
        this.paymentsService.onModelClose();
        this.router.navigateByUrl('/');
      } else if (event.data?.qstr?.includes('resizeWindow')) {
        console.log('resize window' + event.data.qstr);
      } else {
        this.transactResponse = JSON.parse(this.paymentsService.parseQueryString(event?.data));
        // transaction approved
        if (
          this.transactResponse.responseCode === '1' ||
          this.transactResponse.responseCode === '4'
        ) {
          console.log(
            'Transaction approved - account:' +
              this.transactResponse.accountNumber +
              ', total amount:' +
              this.transactResponse.totalAmount,
          );

          this.getSelectedComponentInfo = this.paymentsService.getComponent();
          if (this.getSelectedComponentInfo === this.selectedComponent) {
            const updateAnnualMaintanancePayment = {
              'ACQR_Id': this.acqrId,
            };
            this.sharedService
              .updateAnnualMaintainancePayment(updateAnnualMaintanancePayment)
              .subscribe();
            this.paymentsService.goToPaymentSummary(this.transactResponse);
          }
        }
        // TO DO: handle various responses
        else {
          console.log('Transaction declined, response code:' + this.transactResponse.responseCode);
        }
      }
    } else {
      console.log('Payment received bad message');
    }
  };

  onCancel() {
    this.paymentsService.onModelClose();
  }
}
