<div class="container-fluid padding-layout custom">    
    <div class="pl-2">
        <h1>CUSTOMER CONNECT TERMS OF USE</h1>
    </div>
    <div class="p-2">
        <h2>Terms Governing Use</h2>
        <p>
            The following terms and conditions, as they may be amended from time to time, govern your use of this website and its contents. These terms and conditions may be amended at any time without further notice. Please review these terms and conditions before using this website and its contents. If you do not accept and agree with any of these terms and conditions, then do not use this website and its contents.
        </p>
    </div>
    <div class="p-2">
        <h2>Website Access</h2>
        <p>
            This website and its contents are made available by the CARF International group of companies, including CARF, CARF Canada, and CARF Europe (collectively, “CARF”). A valid logon ID and password are required each time you use this website and its contents, and you are responsible for maintaining the confidentiality of your password. CARF is not responsible and hereby disclaims any liability for lost or modified data, disclosure of information, or otherwise, from use of this website and its contents by anyone other than you using your password. CARF may restrict or terminate your access at any time for any reason, with or without notice, at its sole discretion.
        </p>
    </div>
    <div class="p-2">
        <h2>User Assumes Risk</h2>
        <p>
            Warranty Disclaimer. Your use of this website and its contents is at your sole risk, and you assume all responsibility for the use of this website and its contents. This web site and its contents are provided “as is.” No warranty, expressed or implied, is made from this website and its contents, including without limitation with respect to title, fitness for a particular purpose, merchantability, non-infringement, accuracy, adequacy, completeness, currency, usefulness, confidentiality (e.g., unauthorized breach of network security), or otherwise.
        </p>
    </div>
    <div class="p-2">
        <h2>Limitation of Liabilities</h2>
        <p>
            CARF will not be responsible in contract, tort (including negligence), or otherwise for any damages whatsoever with respect to the use or misuse of this website or its contents, including without limitation any direct, indirect, incidental, special, or consequential damage or loss whatsoever, even if specifically advised of the possibility of such damages or loss.
        </p>
    </div>
    <div class="p-2">
        <h2>Proprietary Rights</h2>
        <p>
            The information, content, selections, arrangements, files, and other material contained on this website are owned by CARF and are protected by intellectual property law. CARF reserves all rights, and you agree not to infringe upon such rights.
        </p>
    </div>
    <div class="p-2">
        <h2>Changes to Website</h2>
        <p>
            CARF may modify this website and its contents at any time, with or without notice, at its sole discretion.
        </p>
    </div>
    <div class="p-2">
        <h2>Applicable Law</h2>
        <p>
            These terms shall be governed by and construed in accordance with the laws of the state of Arizona and the applicable federal laws of the United States of America. For any disputes related to this website or these terms, you agree to submit to the personal jurisdiction of the state or federal courts located within the state of Arizona.
        </p>
    </div>

</div>
